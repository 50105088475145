<div class="dashboard">
  <div class='row'>
    <div class='column--12'>
      <!-- Standard Header Bar -->
      <mat-card class="summary-card">
        <mat-card-content class='card p--md'>
          <div class='row'>
            <div class='column--12 align-right'>
              <div class="header-actions">
                <button mat-raised-button color="primary">Trigger Metrics</button>
                <button mat-raised-button color="basic">Export</button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Top Performance Cards -->
    <div class='column--12'>
      <div class='row'>
        <div class='column--4'>
          <!-- Route Performance Card -->
          <mat-card class="summary-card">
            <mat-card-content class='card p--md metric-card'>
              <h3>Top Performing Route</h3>
              <p class="route">Toronto, ON → Montreal, QC</p>
              <p class="value positive">95.2%</p>
              <p class="subtext">on-time delivery</p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class='column--4'>
          <!-- Best Courier Card -->
          <mat-card class="summary-card">
            <mat-card-content class='card p--md metric-card'>
              <h3>Best Regional Courier</h3>
              <p class="route">DHL</p>
              <p class="value positive">1.2h</p>
              <p class="subtext">avg delay</p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class='column--4'>
          <!-- Volume Card -->
          <mat-card class="summary-card">
            <mat-card-content class='card p--md metric-card'>
              <h3>Regional Volume</h3>
              <p class="route">Ontario Region</p>
              <p class="value">12,453</p>
              <p class="subtext">shipments</p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    <div class='column--12'>
      <!-- Route Analysis Table -->
      <mat-card class="summary-card">
        <mat-card-content class='card p--md'>
          <h2>Route Performance Analysis</h2>
          <table mat-table [dataSource]="routeAnalysisData" class="mat-elevation-z0">
            <!-- Origin → Destination Column -->
            <ng-container matColumnDef="route">
              <th mat-header-cell *matHeaderCellDef>Origin → Destination</th>
              <td mat-cell *matCellDef="let element">{{ element.route }}</td>
            </ng-container>

            <!-- Volume Column -->
            <ng-container matColumnDef="volume">
              <th mat-header-cell *matHeaderCellDef>Volume</th>
              <td mat-cell *matCellDef="let element">{{ element.volume }}</td>
            </ng-container>

            <!-- On-time % Column -->
            <ng-container matColumnDef="onTime">
              <th mat-header-cell *matHeaderCellDef>On-time %</th>
              <td mat-cell *matCellDef="let element">{{ element.onTime }}</td>
            </ng-container>

            <!-- Avg Delay Column -->
            <ng-container matColumnDef="avgDelay">
              <th mat-header-cell *matHeaderCellDef>Avg Delay</th>
              <td mat-cell *matCellDef="let element">{{ element.avgDelay }}</td>
            </ng-container>

            <!-- Best Courier Column -->
            <ng-container matColumnDef="bestCourier">
              <th mat-header-cell *matHeaderCellDef>Best Courier</th>
              <td mat-cell *matCellDef="let element">{{ element.bestCourier }}</td>
            </ng-container>

            <!-- Active Couriers Column -->
            <ng-container matColumnDef="activeCouriers">
              <th mat-header-cell *matHeaderCellDef>Active Couriers</th>
              <td mat-cell *matCellDef="let element">{{ element.activeCouriers }}</td>
            </ng-container>

            <!-- Performance Trend Column -->
            <ng-container matColumnDef="performanceTrend">
              <th mat-header-cell *matHeaderCellDef>Performance Trend</th>
              <td mat-cell *matCellDef="let element">
                <svg width="100" height="30">
                  <path [attr.d]="element.performanceTrend" fill="none" stroke="#198754" stroke-width="2"/>
                </svg>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
    <div class='column--12'>
      <!-- Regional Insights -->
      <mat-card class="summary-card">
        <mat-card-content class='card p--md'>
          <h2>Regional Performance Insights</h2>
          <div class='row'>
            <div class='column--6'>
              <!-- Regional Map Placeholder -->
              <div class="map-placeholder">
                <p>Performance Heat Map</p>
              </div>
            </div>
            <div class='column--6'>
              <!-- Regional Stats -->
              <div class='row'>
                <div class='column--12'>
                  <!-- Service Coverage -->
                  <div class="stats-card">
                    <h3>Service Coverage</h3>
                    <p>Total Available Couriers: 5</p>
                    <p>Active Couriers: 4</p>
                  </div>
                </div>
                <div class='column--12'>
                  <!-- Delay Distribution -->
                  <div class="stats-card">
                    <h3>Delay Distribution</h3>
                    <div class="delay-bars">
                      <div class="delay-bar" style="width: 45%; background-color: #198754;"></div>
                      <div class="delay-bar" style="width: 35%; background-color: #28a745;"></div>
                      <div class="delay-bar" style="width: 20%; background-color: #ffc107;"></div>
                    </div>
                    <div class="delay-labels">
                      <p class='display-inline-block' style="width: 45%;">Within 1h (45%)</p>
                      <p class='display-inline-block' style="width: 35%;">Within 3h (35%)</p>
                      <p class='display-inline-block' style="width: 20%;">Over 3h (20%)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
