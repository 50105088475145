<ng-container *ngIf='!loading, else isLoading'>
  <div class='container mx--none'>
    <ng-container *ngIf='canViewCards || canViewTopRevenue'>
      <form [formGroup]="filterForm">
        <div class='row'>
          <div class='column--3'>
            <mat-form-field class='full-width'>
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
          </div>
          <div class='column--4'>
            <mat-form-field class='full-width'>
              <mat-label>Provinces</mat-label>
              <mat-select formControlName="provinces" name='provinces' placeholder='All provinces' multiple>
                <mat-option *ngFor='let option of provincesDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='column--3'>
            <mat-form-field class='full-width'>
              <mat-label>Couriers</mat-label>
              <mat-select formControlName="couriers" name='couriers' placeholder='All couriers' multiple>
                <mat-option *ngFor='let option of couriersDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='column--2 with-gutter'>
            <mat-form-field class='full-width'>
              <mat-label>Account types</mat-label>
              <mat-select formControlName="accountTypes" name='accountTypes' placeholder='All types' multiple>
                <mat-option *ngFor='let option of accountTypeDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class='row'>
          <div class='column--12 pt--xs pr--md align-right'>
            <button mat-flat-button color='primary' type='submit' (click)='getAllInformation()'>Search</button>
          </div>
        </div>
      </form>
    </ng-container>
    <mat-tab-group
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      dynamicHeight
      animationDuration='0ms'
      [selectedIndex]="selectedTab"
      (selectedIndexChange)='selectedTab = $event'
    >
      <mat-tab *ngFor='let tab of tabs' [label]='tab.label'>
        <ng-template matTabContent>
          <div class='mt--lg'>
            <ng-container *ngIf="canViewCards && tab.id === 'app-report-cards'">
              <app-machool-report-cards
                #machoolReportCardsComponent
                [filterForm]='filterForm'
                [range]='range'
                [canDownloadCardData]='canDownloadCardData'
              ></app-machool-report-cards>
            </ng-container>

            <ng-container *ngIf="canViewTopRevenue && tab.id === 'app-report-top-customers'">
              <app-machool-report-top-revenue
                #machoolReportTopRevenueComponent
                [filterForm]='filterForm'
                [range]='range'
              ></app-machool-report-top-revenue>
            </ng-container>

            <ng-container *ngIf="canViewTopCouriers && tab.id === 'app-report-top-couriers'">
              <app-machool-report-top-courier
                #machoolReportTopCourierComponent
                [filterForm]='filterForm'
                [range]='range'
              ></app-machool-report-top-courier>
            </ng-container>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
