<ng-container *ngIf='!loading && company, else isLoading'>
  <form [formGroup]='companySettingsForm'>
    <div class='row'>
      <div class='column--8'>
        <h3>Settings Flags</h3>
      </div>
    </div>

    <div class='row mt--sm'>
      <div class='column--8'>
        <mat-checkbox class="text--sm" color='primary' formControlName='enforceSSO'><span class='no-bold text--sm'>Force the company to use SSO?</span></mat-checkbox>
      </div>
    </div>

    <div class='row mt--sm'>
      <div class='column--8'>
        <mat-checkbox class="text--sm" color='primary' formControlName='isLD'><span class='no-bold text--sm'>Is this a London Drugs company? (different EEKO rates for LD)</span></mat-checkbox>
      </div>
    </div>

    <div class='row mt--sm'>
      <div class='column--8'>
        <mat-checkbox class="text--sm" color='primary' formControlName='taxExempt'><span class='no-bold text--sm'>Is the company tax exempt?</span></mat-checkbox>
      </div>
    </div>

    <div class='row mt--sm'>
      <div class='column--8'>
        <mat-checkbox class="text--sm" color='primary' formControlName='addBlindMarginAllowed'><span class='no-bold text--sm'>Can the company add blind markups?</span></mat-checkbox>
      </div>
    </div>

    <div class='row mt--sm'>
      <div class='column--8'>
        <mat-checkbox class="text--sm" color='primary' formControlName='showCustomerReceipt'><span class='no-bold text--sm'>Should we show customer receipts for this company?</span></mat-checkbox>
      </div>
    </div>

    <div class='row mt--sm'>
      <div class='column--8'>
        <mat-checkbox class="text--sm" color='primary' formControlName='isFraudulent'><span class='no-bold text--sm'>Is the company suspected for fraudulent activity?</span></mat-checkbox>
      </div>
    </div>

    <div class='row mt--sm'>
      <div class='column--8'>
        <mat-checkbox class="text--sm" color='primary' formControlName='showReturnCentre'><span class='no-bold text--sm'>Does the company have access to setup a Return Centre?</span></mat-checkbox>
      </div>
    </div>

    <div class='row mt--md'>
      <div class='column--12'>
        <button mat-flat-button color='primary' type='submit' [disabled]="!canEdit || companySettingsForm.invalid || loading" (click)='submit()'>Save</button>
      </div>
    </div>

  </form>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
