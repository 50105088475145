<div class='row mt--md'>
  <div class='column--12'>
    <h3>Companies by revenue</h3>
  </div>
</div>
<div class='row'>
  <div class='column--12 pr--md pb--sm'>
    <mat-card>
      <mat-card-content>
        <ng-container *ngIf="!loadingTopRevenue, else loadingTop">
          <div class='responsive-table' *ngIf='!topRevenueAccountsEmpty, else emptyRevenueAccounts'>
            <table mat-table [dataSource]="topRevenueAccounts">
              <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef style='width: 50px'></th>
                <td mat-cell *matCellDef="let element; let i = index;">
                  {{ i+1 }}
                </td>
              </ng-container>
              <ng-container matColumnDef="companyName">
                <th mat-header-cell *matHeaderCellDef>Company / Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.companyName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef>Total # of Shipments</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.total }}
                </td>
              </ng-container>
              <ng-container matColumnDef="revenue">
                <th mat-header-cell *matHeaderCellDef>Revenue</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.revenue | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="sales_rep">
                <th mat-header-cell *matHeaderCellDef>Sales Rep</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.salesRep ? element.salesRep : 'Other' }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="topRevenueColumns"></tr>
              <tr mat-row *matRowDef="let element; columns: topRevenueColumns; let i = index" class="top-company-row">
              </tr>
            </table>
          </div>
          <ng-template #emptyRevenueAccounts>
            <div class='h1 mt--sm mb--none align-center'>
              <i class='icon-warn text--warn'></i>
              <div class='text-center'>Ship! Nothing matches the search criteria.</div>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #loadingTop>
          <div class='align-center mt--sm'>
            <app-loading></app-loading>
          </div>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
</div>
