import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DEFAULT_LOCALE, ReportCardGroup } from '@app/shared/constants';
import { LoggerService } from '@app/shared/services/logger.service';
import { ReportsService } from '@app/shared/services/reports.service';
import { HelpersService } from '@app/shared/services/helpers.service';
import { formatNumber } from '@angular/common';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-machool-report-top-courier',
  templateUrl: './machool-report-top-courier.component.html',
})
export class MachoolReportTopCourierComponent implements OnInit {
  @Input() filterForm: FormGroup | undefined;
  @Input() range: FormGroup | undefined;

  public loadingTopCouriers = false;
  public loadingTopCouriersFailed = false;
  public topCouriersAccounts: any[];
  public sortedTopCouriersAccounts: any[];
  public topCouriersAccountsEmpty: boolean = false;
  public topCouriersColumns = ['index', 'providerName', 'totalNumShipments', 'totalNumMAShipments', 'totalMARevenue', 'totalNumBYOAShipments', 'totalBYOARevenue'];

  constructor(
    private logger: LoggerService,
    private helpersService: HelpersService,
    private reportsService: ReportsService,
  ) {}

  ngOnInit() {
    this.getTopCouriersList();
  }

  public sortData(sort: Sort) {
    const data = this.topCouriersAccounts.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedTopCouriersAccounts = data;
      return;
    }
    const isAsc = sort.direction === 'asc';
    this.sortedTopCouriersAccounts = data.sort((a, b) => {
      return this.helpersService.compare(a[sort.active], b[sort.active], isAsc);
    });
  }

  public getTopCouriersList(): void {
    this.loadingTopCouriers = true;
    this.loadingTopCouriersFailed = false;
    let query: any = this.reportsService.getFilterQuery(this.range, this.filterForm);
    query.report_card_group = ReportCardGroup.TOP_COURIERS;
    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.topCouriersAccounts = [];
        res.accounts.forEach((courier) => {
          const courierStats = {
            providerName: this.helpersService.convertShipmentProviderToString(courier.provider_type),
            totalNumShipmentsFormatted: formatNumber(courier.totalNumShipments, DEFAULT_LOCALE),
            totalNumShipments: courier.totalNumShipments,
            totalNumMAShipmentsFormatted: formatNumber(courier.totalNumMAShipments, DEFAULT_LOCALE),
            totalNumMAShipments: courier.totalNumMAShipments,
            totalMARevenue: parseFloat(courier.totalMARevenue),
            totalNumBYOAShipmentsFormatted: formatNumber(courier.totalNumBYOAShipments, DEFAULT_LOCALE),
            totalNumBYOAShipments: courier.totalNumBYOAShipments,
            totalBYOARevenue: parseFloat(courier.totalBYOARevenue),
          };
          this.topCouriersAccounts.push(courierStats);
        })
        this.topCouriersAccountsEmpty = this.topCouriersAccounts.length === 0;
        this.sortData({active: 'totalMARevenue', direction: 'desc'});
        this.loadingTopCouriers = false;
      },
      (err: Error) => {
        this.loadingTopCouriers = false;
        this.loadingTopCouriersFailed = true;
        this.logger.error('GET top accounts report error', err);
      }
    );
  }
}
