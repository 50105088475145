import { animate, state, style, transition, trigger } from '@angular/animations';
import { AdminScopes } from '@app/shared/services/user.service';

export enum Roles {
  USER = 'U',
  ADMINISTRATOR = 'A',
  OWNER = 'O', // formerly 'root'
  MACHOOL = 'M',
}

export enum RolesVerbose {
  USER = 'User',
  ADMINISTRATOR = 'Admin',
  OWNER = 'Owner',
  MACHOOL = 'Customer support',
}

export enum WalletTransactionTypes {
  SHIPMENT_CHARGE = 'Shipment Charge',
  RECON_CHARGE = 'Recon Charge',
  FUNDS_ADDED = 'Funds Added',
  ADMIN_ADJUSTMENT = 'Administrative Adjustment',
}

export enum BillingTypes {
  CREDIT_CARD = 'C',
  MACHOOL_ACCOUNT = 'M',
  MACHOOL_WALLET = 'W',
  THIRDPARTY_ACCOUNT = 'THIRDPARTY',
}

export enum BillingTypesVerbose {
  CREDIT_CARD = 'Credit card',
  MACHOOL_ACCOUNT = 'Machool account',
  MACHOOL_WALLET = 'Machool Wallet',
  THIRDPARTY_ACCOUNT = 'Bill to 3rd party account',
}

export enum CompanyNoteType {
  HAPPY = 'H',
  NEUTRAL = 'N',
  UNHAPPY = 'U',
  CONFUSED = 'C',
}

export enum CompanyNoteVerbose {
  HAPPY = 'Happy',
  NEUTRAL = 'Neutral',
  UNHAPPY = 'Unhappy',
  CONFUSED = 'Confused',
}

// Company Enums
export enum PricingTiers {
  BRONZE = 'B',
  SILVER = 'S',
  GOLD = 'G',
  EMPLOYEE = 'E',
  CORPORATE = 'C',
  AFFINITY_CQCD = 'Q',
  AFFINITY_PANIER = 'A',
}

export enum PricingTierVerbose {
  BRONZE = 'Bronze',
  SILVER = 'Silver',
  GOLD = 'Gold',
  CORPORATE = 'Custom',
  AFFINITY_CQCD = 'CQCD',
  EMPLOYEE = 'Employee',
}

export enum SupportedReconProvider {
  PUROLATOR = 'PUROLATOR',
  DHL = 'DHL',
  GLS = 'GLS',
  NATIONEX = 'NATIONEX',
  LIVRAPIDE = 'LIVRAPIDE',
  CANPAR = 'CANPAR',
  FEDEX = 'FEDEX',
  RIVO = 'RIVO',
  CANADA_POST = 'CANADA_POST',
  CANADA_POST_3PL = 'CANADA_POST_3PL',
  CHASSEURS_COURRIER = 'CHASSEURS_COURRIER',
  UNIUNI = 'UNIUNI',
  FLASHBOX = 'FLASHBOX',
  RIVO_MULTI_LEG = 'RIVO_MULTI_LEG',
  INTELCOM_MULTI_LEG = 'INTELCOM_MULTI_LEG',
  APPLE_EXPRESS_MULTI_LEG = 'APPLE_EXPRESS_MULTI_LEG',
  UPS = 'UPS',
  UNIUNI_MULTI_LEG = 'UNIUNI_MULTI_LEG',
  T_FORCE_MULTI_LEG = 'T_FORCE_MULTI_LEG',
  ICS_COURIER = 'ICS_COURIER',
  // TODO: Legacy providers. Remove once old shipment data archived (4266)
  COURANT_PLUS = 'COURANT_PLUS',
  GOBOLT = 'GOBOLT',
  DELIVRO = 'DELIVRO',
  DAYROSS = 'DAYROSS',
  UPIC = 'UPIC',
  MARSH = 'MARSH',
  LOOMIS_EXPRESS = 'LOOMIS_EXPRESS'
}

export enum ShippingProviders {
  CANPAR = 'canpar',
  CHASSEURS_COURRIER = 'chasseurscourrier',
  CANADA_POST = 'canadapost',
  CANADA_POST_3PL = 'canadapost3pl',
  DHL = 'dhl',
  GLS = 'gls',
  INTELCOM = 'intelcom',
  FEDEX = 'fedex',
  FLASHBOX = 'flashbox',
  FEDEX_GROUND = 'fedexGround',
  FEDEX_EXPRESS = 'fedexExpress',
  PUROLATOR = 'purolator',
  UPS = 'ups',
  MACHOOL = 'machool',
  NATIONEX = 'nationex',
  SHIFT = 'shift',
  EEKO = 'eeko',
  RIVO = 'rivo', // Air Canada
  AIR_CANADA_DOMESTIC = 'aircanadadomestic', // Air Canada domestic
  AIR_CANADA_INTERNATIONAL = 'aircanadainternational', // Air Canada international
  UNIUNI = 'uniuni',
  SPRING = 'spring',
  TREXITY = 'trexity',
  TFORCE_LOGISTICS = 'tforcelogistics',
  MACHOOL_LOGISTICS = 'machoollogistics',
  APPLE_EXPRESS = 'appleexpress',
  LIVRAPIDE = 'livrapide',
  ICS_COURIER = 'icscourier',
  EVA = 'eva',
  DELIVRO = 'delivro',
  ULALA = 'ulala',
  MDX = 'mdx',
  LOOMIS_EXPRESS = 'loomisexpress',
  // TODO: Legacy providers. Remove once old shipment data archived (4266)
  GOBOLT = 'gobolt',
  COURANT_PLUS = 'courantplus',
  UBER = 'uber',

  // Freight couriers
  FREIGHT_AIR_CANADA = 'freightaircanada',
  DAYROSS = 'dayross',
}

export enum FREIGHT_PROVIDERS {
  DAYROSS = 'dayross',
}

export enum ShippingProviderNames {
  AIR_CANADA = 'Air Canada Cargo (Multi-Courier)',
  CANADA_POST = 'Canada Post',
  CANADA_POST_3PL = 'Canada Post 3PL',
  CANPAR = 'Canpar Express',
  CHASSEURS_COURRIER = 'Chasseurs Courrier',
  GLS = 'GLS',
  DHL = 'DHL',
  FEDEX = 'FedEx®',
  FEDEX_EXPRESS = 'FedEx Express',
  FEDEX_GROUND = 'FedEx Ground',
  FLASHBOX = 'FlashBox',
  INTELCOM = 'Dragonfly',
  NATIONEX = 'Nationex',
  PUROLATOR = 'Purolator',
  SHIFT = 'Shift Delivery',
  UPS = 'UPS®',
  EEKO = 'EEKO',
  RIVO = 'Rivo',
  AIR_CANADA_DOMESTIC = 'Air Canada Cargo (Multi-Courier Domestic)',
  AIR_CANADA_INTERNATIONAL = 'Air Canada Cargo (Multi-Courier International)',
  UNIUNI = 'UniUni',
  SPRING = 'Spring',
  MACHOOL = 'Machool',
  TREXITY = 'Trexity',
  APPLE_EXPRESS = 'Apple Express',
  TFORCE_LOGISTICS = 'TForce',
  MACHOOL_LOGISTICS = 'Machool Logistics',
  LIVRAPIDE = 'Livrapide',
  ICS_COURIER = 'ICS Courier',
  EVA = 'Eva',
  DELIVRO = 'Delivro',
  ULALA = 'Ulala',
  MDX = 'MDX Logistique',
  LOOMIS_EXPRESS = 'Loomis Express',
  UNKNOWN = 'N/A',
  // TODO: Legacy providers. Remove once old shipment data archived (4266)
  GOBOLT = 'GoBolt',
  COURANT_PLUS = 'Courant Plus by Nationex',
  UBER = 'Uber',
  DAYROSS = 'Day & Ross',
  // Freight Air Canada Cargo
  FREIGHT_AIR_CANADA = 'Air Canada Cargo (Freight)',
  // Recon Providers
  UNIUNI_MULTI_LEG = 'UniUni (Multi-Courier)',
  INTELCOM_MULTI_LEG = 'Dragonfly (Multi-Courier)',
  APPLE_EXPRESS_MULTI_LEG = 'Apple Express (Multi-Courier)',
  T_FORCE_MULTI_LEG = 'TForce (Multi-Courier)',
}

export enum DeliveryToPickupLocationProviders {
  PENGUIN_PICKUP = 'penguinpickup',
  PUDO = 'pudo',
}

export enum DeliveryToPickupLocationProviderNames {
  PENGUIN_PICKUP = 'PenguinPickUp',
  PUDO = 'PUDO',
}

export const CacheKeys = {
  INVOICES_LAST_INVOICE_NUMBER: 'invoices:last-invoice-number',
};

export const Mailers = {
  SEND_CUSTOMER_INVOICE: 'sendCustomerInvoice',
};

export enum InvoiceDownloadTypes {
  PDF = 'pdf',
  CSV = 'csv',
}

export const PUBLIC_INVOICES_URL_PATH = 'statements/invoices/download';

export enum PaymentTypes {
  E_TRANSFER = 'E',
  EFT = 'F',
  CHEQUE = 'C',
}

export enum PaymentTypesVerbose {
  E_TRANSFER = 'E-transfer',
  EFT = 'EFT',
  CHEQUE = 'Cheque',
}

export enum ProviderNotificationCapabilities {
  IS_EDITABLE_RECIPIENT = 1, // 0001
  DEFAULT_ON_RECIPIENT = 2, // 0010
  IS_EDITABLE_SENDER = 4, // 0100
  DEFAULT_ON_SENDER = 8, // 1000
}

export enum ShipmentStatuses {
  CREATED = 'CR',
  CONFIRMED = 'CF',
  CANCELLED = 'CL',
  UNCONFIRMED = 'UC',
  PENDING_CANCEL = 'PC',
  CANCEL_REJECTED = 'CJ',
  PARTIALLY_DELIVERED = 'PD',
}

export enum ShipmentStatusesVerbose {
  CREATED = 'Confirmed',
  CONFIRMED = 'Confirmed',
  CANCELLED = 'Cancelled',
  UNCONFIRMED = 'Unconfirmed',
  PENDING_CANCEL = 'Cancel pending',
  CANCEL_REJECTED = 'Cancel rejected',
  PARTIALLY_DELIVERED = 'Partially Delivered',
  DELIVERED = 'Delivered',
  ATTENTION = 'Attention',
  IN_TRANSIT = 'In-transit',
  UNKNOWN = 'Unknown',
}

export enum ThirdPartyInsuranceProviderStatus {
  BOOKED = 'BOK', // When a shipment is insurable
  CANCEL_PENDING = 'CCP',
  REFERRED = 'REF', // When a shipment must be reviewed by an underwriter
  CANCELLED = 'CAN',
  REJECTED = 'REJ',
}

export enum LegacyShipmentStatuses {
  // internal statuses
  CREATED = 'created',
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
  UNCONFIRMED = 'unconfirmed',
  PENDING_CANCEL = 'pendingCancel',
  CANCEL_REJECTED = 'cancelRejected',
  // tracking statuses
  TRACKING_DELIVERED = 'delivered',
  TRACKING_IN_TRANSIT = 'in-transit',
  TRACKING_LOCAL_BRANCH = 'local-branch',
  TRACKING_ATTENTION = 'attention',
  PARTIALLY_DELIVERED = 'partially-delivered',
}

export enum ShipmentChargeStatus {
  CHARGE_PENDING = 'CP',
  CHARGE_DECLINED = 'CD',
  CHARGE_APPROVED = 'CA',
  REFUND_PENDING = 'RP',
  REFUND_DECLINED = 'RD',
  REFUND_APPROVED = 'RA',
  ADJUSTMENT_PENDING = 'AP',
  ADJUSTMENT_DECLINED = 'AD',
  ADJUSTMENT_APPROVED = 'AA',
  ADJUSTMENT_IGNORED = 'AI',
  UNKNOWN_REASON = 'UR',
  DO_NOT_BILL_ADJUSTMENTS = 'DB',
}

// TODO: use machool-js
export enum InsuranceProviders {
  MARSH = 'marsh',
  UPIC = 'upic',
}

export enum InsuranceProviderNames {
  MARSH = 'MARSH',
  UPIC = 'UPIC',
  UNKNOWN = 'N/A',
}

export enum EcommerceProviders {
  SHOPIFY = 'shopify',
  LIGHTSPEED = 'lightspeed',
  WOOCOMMERCE = 'woocommerce',
  MAGENTO = 'magento',
  WIX = 'wix',
  ETSY = 'etsy',
  BIGCOMMERCE = 'bigcommerce',
  DVORE = 'dvore',
  SQUARESPACE = 'squarespace',
  PANIERDACHAT = 'panierdachat',
  TECHPOS = 'techpos',
  XCART = 'xcart',
  LEPANIERBLEU = 'lepanierbleu',
  LIGHTSPEED_E_SERIES = 'lightspeedeseries',
}

export enum EcommerceProviderNames {
  SHOPIFY = 'Shopify',
  LIGHTSPEED = 'Lightspeed',
  WOOCOMMERCE = 'WooCommerce',
  MAGENTO = 'Magento',
  WIX = 'Wix',
  ETSY = 'Etsy',
  BIGCOMMERCE = 'BigCommerce',
  DVORE = 'Dvore',
  SQUARESPACE = 'Squarespace',
  PANIERDACHAT = 'Panierdachat',
  TECHPOS = 'TechPOS',
  XCART = 'X-Cart',
  LEPANIERBLEU = 'LePanierBleu',
  LIGHTSPEED_E_SERIES = 'Lightspeed E-Series',
}

export enum TrackingTypes {
  FORWARD = 'forward',
  RETURN = 'return',
}

export enum DateFormats {
  DATE = 'EEEE, d LLLL',
  DATE_SHORT = 'EEE, d LLL',
  DATE_MONTH = 'd LLLL',
  DATE_MONTH_SML = 'd LLL',
  DATE_DAY = 'd',
  DATE_ISO = 'yyyy-MM-dd',
  DATE_ISO_FULL = 'yyyy-MM-ddThh:mm:ssZ',
  DATE_YEAR_SML = 'd LLL, yyyy',
  DATE_YEAR = 'd LLLL, yyyy',
  DATE_TIME = 'EEEE, LLLL d h:mma',
  DELIVERY_DATE_TIME = "EEEE, LLLL d, yyyy 'at' h:mm a",
  DATE_TIME_YEAR = 'd LLLL h:mma yyyy',
  DATE_TIME_SHIPMENT = 'LLL d, h:mm a',
  TIME = 'h:mm a',
  WEEKDAY = 'EEEE',
  ORDER_LIST = 'MM/dd h:mma',
}

export enum AccountTypes {
  MACHOOL = 'M',
  BYOA = 'B',
}

export enum AccountTypesVerbose {
  MACHOOL = 'Machool',
  BYOA = 'BYOA',
}

export const TRACKING_TIMESTAMP_FORMAT = 'yyyy-MM-dd HH:mm';
export const TRACKING_TIMESTAMP_EXT_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export enum ShipmentStatus {
  AWAITINGPU = 'Awaiting pickup',
  DELIVERED = 'Delivered',
  ESTIMATED = 'estimated delivery',
  INTRANSIT = 'Item in transit',
  LOCALBRANCH = 'At local branch',
  LOCATION = 'last location',
  NOTRACKING = 'No tracking information available at the moment',
  NUMBER = 'tracking number',
  SHIPMENT_INFO_RECEIVED = 'Shipment information received',
  STATUS = 'Status',
  TITLE = 'tracking information',
  TRACK_ERROR = 'Not Available',
  PARTIALLY_DELIVERED = 'Partially Delivered',
}

export enum ErrorMessages {
  UNAUTHORIZED = 'You are unauthorized to access this resource',
  UNEXPECTED = 'There was an unexpected error, please try again or contact support',
  TIMEOUT = 'Request timed out',
}

export const Animations = {
  ReconExpandAnimation: trigger('detailExpand', [
    state('collapsed', style({ maxHeight: '0px', height: '0px', minHeight: '0' })),
    state('expanded', style({ height: '*' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
  ExpandAnimation: trigger('detailExpand', [
    state('collapsed', style({ maxHeight: '0px', height: '0px', minHeight: '0' })),
    state('expanded', style({ height: '*', maxHeight: '1000px' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
  OpenClose: trigger('openClose', [
    // ...
    state(
      'open',
      style({
        'margin-left': '290px',
      })
    ),
    state(
      'closed',
      style({
        'margin-left': '100px',
      })
    ),
    transition('open => closed', [animate('0.25s ease-in-out')]),
    transition('closed => open', [animate('0.25s ease-in-out')]),
  ]),
  RotateIcon: trigger('rotateIcon', [
    // ...
    state(
      'open',
      style({
        rotate: '0deg',
      })
    ),
    state(
      'closed',
      style({
        rotate: '180deg',
      })
    ),
    transition('open => closed', [animate('0.25s ease-in-out')]),
    transition('closed => open', [animate('0.25s ease-in-out')]),
  ]),
};

export interface sidenavItems {
  routerLink: string;
  iconType?: string;
  icon: string;
  title: string;
  active: boolean;
  scope: AdminScopes[];
  iconClass?: string;
  withSpacer?: boolean;
}

export enum EDI_INVOICE_MODAL_TYPE {
  UNKNOWN_TRACKING = 'unknown-tracking-info',
  EDI_INVOICE_ONLY = 'edi-invoice-only',
}

export const DEFAULT_LOCALE = 'en-CA';

export enum ReportCardGroup {
  ACCOUNTS = 'accounts',
  NEW_ACCOUNTS = 'new_accounts',
  STARTED_SHIPPING = 'started_shipping',
  GREEN_SHIPMENTS_REVENUE = 'green_shipments_revenue',
  STRIPE = 'stripe',
  RECON_ADJUSTMENTS = 'recon_adjustments',
  CQCD = 'cqcd',
  PANIER_DA_CHAT = 'panier_da_chat',
  NATIONEX_PICKUP = 'nationex_pickup',
  WALLET_BALANCE = 'wallet_balance',

  SHIPMENTS = 'shipments',
  SHIPMENTS_MANUAL = 'shipments_manual',
  SHIPMENTS_ECOMMERCE = 'shipments_ecommerce',
  SHIPMENTS_API = 'shipments_api',
  SHIPMENTS_CONNECT = 'shipments_connect',
  SHIPMENTS_FREIGHT = 'shipments_freight',
  REVENUE = 'revenue',
  REVENUE_BY_CHARGE = 'revenue_by_charge',

  TOP_REVENUE = 'top_revenue',

  ACC_SHIPMENTS = 'acc_shipments',
}

export enum InvoicePaymentTypes {
  PAD = 'PAD',
  CREDIT_CARD = 'CREDIT_CARD',
  OTHER = 'OTHER',
}

export const PanelWidths = {
  desktopFull: '600px',
  mobileFull: '100vw',
};

export enum CompanyChargeName {
  MACHOOL = 'machool',
  CUSTOMER = 'customer',
}

export enum BillingName {
  CREDIT_CARD = 'credit-card',
  MACHOOL_ACCOUNT = 'machool-account',
  MACHOOL_WALLET = 'machool-wallet',
}

export enum CompanyChargeCode {
  PICKUP_FEE = 'pickup-fee',
  STORE_PURCHASE = 'supply-store',
  API_SUBSCRIPTION = 'api-subscription',
  ADDRESS_CORRECT = 'address-correction',
  ECOMMERCE_PLUGIN = 'ecommerce-plugin',
}

export const ResponsiveRanges = {
  mobile: 768,
  desktop: 992,
  largeDesktop: 1200,
};

export const SupportedAirports = [
  {
    code: 'YEG',
    name: 'Edmonton International Airport',
    address: {
      line1: '3996 21 Street East',
      line2: '',
      city: 'Edmonton',
      province: 'AB',
      country: 'CA',
      postalCode: 'T9E 0V6',
      timezone: 'America/Edmonton',
    },
    formattedAddress: {
      firstLine: '3996 21 Street East',
      secondLine: 'Edmonton, AB, T9E 0V6, CA',
    },
  },
  {
    code: 'YHZ',
    name: 'Halifax Stanfield International Airport',
    address: {
      line1: '619 Barnes Drive',
      line2: '',
      city: 'Goffs',
      province: 'NS',
      country: 'CA',
      postalCode: 'B2T 1K3',
      timezone: 'America/Halifax',
    },
    formattedAddress: {
      firstLine: '619 Barnes Drive',
      secondLine: 'Goffs, NS, B2T 1K3, CA',
    },
  },
  {
    code: 'YOW',
    name: 'Ottawa International Airport',
    address: {
      line1: '900 Airport Parkway Private',
      line2: '',
      city: 'Ottawa',
      province: 'ON',
      country: 'CA',
      postalCode: 'K1V 9B4',
      timezone: 'America/Toronto',
    },
    formattedAddress: {
      firstLine: '900 Airport Parkway Private',
      secondLine: 'Ottawa, ON, K1V 9B4, CA',
    },
  },
  {
    code: 'YQR',
    name: 'Regina International Airport',
    address: {
      line1: '3010 Tutor Drive',
      line2: '#400',
      city: 'Regina',
      province: 'SK',
      country: 'CA',
      postalCode: 'S4W 0R7',
      timezone: 'America/Regina',
    },
    formattedAddress: {
      firstLine: '#400 3010 Tutor Drive',
      secondLine: 'Regina, SK, S4W 0R7, CA',
    },
  },
  {
    code: 'YUL',
    name: 'Montréal-Pierre Elliott Trudeau International Airport',
    address: {
      line1: '2200 Reverchon',
      line2: 'Doc door #43',
      city: 'Dorval',
      province: 'QC',
      country: 'CA',
      postalCode: 'H9P 2S7',
      timezone: 'America/Toronto',
    },
    formattedAddress: {
      firstLine: '2200 Reverchon',
      secondLine: 'Dorval, QC, H9P 2S7, CA',
    },
  },
  {
    code: 'YVR',
    name: 'Vancouver International Airport',
    address: {
      line1: '4900 Miller Road',
      line2: '',
      city: 'Richmond',
      province: 'BC',
      country: 'CA',
      postalCode: 'V7B 1K7',
      timezone: 'America/Vancouver',
    },
    formattedAddress: {
      firstLine: '4900 Miller Road',
      secondLine: 'Richmond, BC, V7B 1K7, CA',
    },
  },
  {
    code: 'YWG',
    name: 'Winnipeg International Airport',
    address: {
      line1: '2019 Sargent Ave',
      line2: 'Unit 10',
      city: 'Winnipeg',
      province: 'MB',
      country: 'CA',
      postalCode: 'R3H 0E1',
      timezone: 'America/Winnipeg',
    },
    formattedAddress: {
      firstLine: 'Unit 10 2019 Sargent Ave',
      secondLine: 'Winnipeg, MB, R3H 0E1, CA',
    },
  },
  {
    code: 'YYC',
    name: 'Calgary International Airport',
    address: {
      line1: '175 Aero Way NE',
      line2: '#110',
      city: 'Calgary',
      province: 'AB',
      country: 'CA',
      postalCode: 'T2E 6K2',
      timezone: 'America/Edmonton',
    },
    formattedAddress: {
      firstLine: '#110-175 Aero Way NE',
      secondLine: 'Calgary, AB, T2E 6K2, CA',
    },
  },
  {
    code: 'YYT',
    name: "St. John's International Airport",
    address: {
      line1: '114 Airport Service Road',
      line2: '',
      city: "St. John's",
      province: 'NL',
      country: 'CA',
      postalCode: 'A1A 0H6',
      timezone: 'America/St_Johns',
    },
    formattedAddress: {
      firstLine: '114 Airport Service Road',
      secondLine: "St. John's, NL, A1A 0H6, CA",
    },
  },
  {
    code: 'YYZ',
    name: 'Toronto Pearson International Airport',
    address: {
      line1: '2580 Britannia Road East',
      line2: '',
      city: 'Mississauga',
      province: 'ON',
      country: 'CA',
      postalCode: 'L4W 2P7',
      timezone: 'America/Toronto',
    },
    formattedAddress: {
      firstLine: '2580 Britannia Road East',
      secondLine: 'Mississauga, ON, L4W 2P7, CA',
    },
  },
];

export const DefaultTimezone = 'America/Toronto';

export const ProvinceList = [
  { id: 'AB', text: 'Alberta' },
  { id: 'BC', text: 'British Columbia' },
  { id: 'MB', text: 'Manitoba' },
  { id: 'NB', text: 'New Brunswick' },
  { id: 'NL', text: 'Newfoundland and Labrador' },
  { id: 'NT', text: 'Northwest Territories' },
  { id: 'NS', text: 'Nova Scotia' },
  { id: 'NU', text: 'Nunavut' },
  { id: 'ON', text: 'Ontario' },
  { id: 'PE', text: 'Prince Edward Island' },
  { id: 'QC', text: 'Quebec' },
  { id: 'SK', text: 'Saskatchewan' },
  { id: 'YT', text: 'Yukon' },
];

export const SupportedReconProvideItems = [
  {
    id: SupportedReconProvider.PUROLATOR,
    value: SupportedReconProvider.PUROLATOR,
    text: ShippingProviderNames.PUROLATOR,
  },
  {
    id: SupportedReconProvider.DHL,
    value: SupportedReconProvider.DHL,
    text: ShippingProviderNames.DHL,
  },
  {
    id: SupportedReconProvider.GLS,
    value: SupportedReconProvider.GLS,
    text: ShippingProviderNames.GLS,
  },
  {
    id: SupportedReconProvider.NATIONEX,
    value: SupportedReconProvider.NATIONEX,
    text: ShippingProviderNames.NATIONEX,
  },
  {
    id: SupportedReconProvider.LIVRAPIDE,
    value: SupportedReconProvider.LIVRAPIDE,
    text: ShippingProviderNames.LIVRAPIDE,
  },
  {
    id: SupportedReconProvider.CANPAR,
    value: SupportedReconProvider.CANPAR,
    text: ShippingProviderNames.CANPAR,
  },
  {
    id: SupportedReconProvider.FEDEX,
    value: SupportedReconProvider.FEDEX,
    text: ShippingProviderNames.FEDEX,
  },
  {
    id: SupportedReconProvider.CANADA_POST,
    value: SupportedReconProvider.CANADA_POST,
    text: ShippingProviderNames.CANADA_POST,
  },
  {
    id: SupportedReconProvider.CANADA_POST_3PL,
    value: SupportedReconProvider.CANADA_POST_3PL,
    text: ShippingProviderNames.CANADA_POST_3PL,
  },
  {
    id: SupportedReconProvider.CHASSEURS_COURRIER,
    value: SupportedReconProvider.CHASSEURS_COURRIER,
    text: ShippingProviderNames.CHASSEURS_COURRIER,
  },
  {
    id: SupportedReconProvider.UNIUNI,
    value: SupportedReconProvider.UNIUNI,
    text: ShippingProviderNames.UNIUNI,
  },
  {
    id: SupportedReconProvider.FLASHBOX,
    value: SupportedReconProvider.FLASHBOX,
    text: ShippingProviderNames.FLASHBOX,
  },
  {
    id: SupportedReconProvider.RIVO_MULTI_LEG,
    value: SupportedReconProvider.RIVO_MULTI_LEG,
    text: ShippingProviderNames.AIR_CANADA,
  },
  {
    id: SupportedReconProvider.INTELCOM_MULTI_LEG,
    value: SupportedReconProvider.INTELCOM_MULTI_LEG,
    text: ShippingProviderNames.INTELCOM_MULTI_LEG,
  },
  {
    id: SupportedReconProvider.UNIUNI_MULTI_LEG,
    value: SupportedReconProvider.UNIUNI_MULTI_LEG,
    text: ShippingProviderNames.UNIUNI_MULTI_LEG,
  },
  {
    id: SupportedReconProvider.UPS,
    value: SupportedReconProvider.UPS,
    text: ShippingProviderNames.UPS,
  },
  {
    id: SupportedReconProvider.ICS_COURIER,
    value: SupportedReconProvider.ICS_COURIER,
    text: ShippingProviderNames.ICS_COURIER,
  },
  {
    id: SupportedReconProvider.DELIVRO,
    value: SupportedReconProvider.DELIVRO,
    text: ShippingProviderNames.DELIVRO,
  },
  {
    id: SupportedReconProvider.DAYROSS,
    value: SupportedReconProvider.DAYROSS,
    text: ShippingProviderNames.DAYROSS,
  },
  {
    id: SupportedReconProvider.LOOMIS_EXPRESS,
    value: SupportedReconProvider.LOOMIS_EXPRESS,
    text: ShippingProviderNames.LOOMIS_EXPRESS,
  },
  {
    id: SupportedReconProvider.UPIC,
    value: SupportedReconProvider.UPIC,
    text: InsuranceProviderNames.UPIC,
  },
  {
    id: SupportedReconProvider.MARSH,
    value: SupportedReconProvider.MARSH,
    text: InsuranceProviderNames.MARSH,
  },
];

export const ReconProviderItems = [
  {
    id: ShippingProviders.PUROLATOR,
    value: ShippingProviders.PUROLATOR,
    text: ShippingProviderNames.PUROLATOR,
  },
  { id: ShippingProviders.DHL, value: ShippingProviders.DHL, text: ShippingProviderNames.DHL },
  { id: ShippingProviders.GLS, value: ShippingProviders.GLS, text: ShippingProviderNames.GLS },
  {
    id: ShippingProviders.NATIONEX,
    value: ShippingProviders.NATIONEX,
    text: ShippingProviderNames.NATIONEX,
  },
  {
    id: ShippingProviders.CANPAR,
    value: ShippingProviders.CANPAR,
    text: ShippingProviderNames.CANPAR,
  },
  {
    id: ShippingProviders.FEDEX,
    value: ShippingProviders.FEDEX,
    text: ShippingProviderNames.FEDEX,
  },
  { id: ShippingProviders.RIVO, value: ShippingProviders.RIVO, text: ShippingProviderNames.AIR_CANADA },
  {
    id: ShippingProviders.CANADA_POST,
    value: ShippingProviders.CANADA_POST,
    text: ShippingProviderNames.CANADA_POST,
  },
  {
    id: ShippingProviders.CHASSEURS_COURRIER,
    value: ShippingProviders.CHASSEURS_COURRIER,
    text: ShippingProviderNames.CHASSEURS_COURRIER,
  },
  {
    id: ShippingProviders.UNIUNI,
    value: ShippingProviders.UNIUNI,
    text: ShippingProviderNames.UNIUNI,
  },
  {
    id: ShippingProviders.FLASHBOX,
    value: ShippingProviders.FLASHBOX,
    text: ShippingProviderNames.FLASHBOX,
  },
  { id: ShippingProviders.UPS, value: ShippingProviders.UPS, text: ShippingProviderNames.UPS },
  {
    id: ShippingProviders.ICS_COURIER,
    value: ShippingProviders.ICS_COURIER,
    text: ShippingProviderNames.ICS_COURIER,
  },
  { id: ShippingProviders.LIVRAPIDE, value: ShippingProviders.LIVRAPIDE, text: ShippingProviderNames.LIVRAPIDE },
  {
    id: ShippingProviders.CANADA_POST_3PL,
    value: ShippingProviders.CANADA_POST_3PL,
    text: ShippingProviderNames.CANADA_POST_3PL,
  },
  { id: ShippingProviders.DELIVRO, value: ShippingProviders.DELIVRO, text: ShippingProviderNames.DELIVRO },
  { id: ShippingProviders.DAYROSS, value: ShippingProviders.DAYROSS, text: ShippingProviderNames.DAYROSS },
  { id: ShippingProviders.LOOMIS_EXPRESS, value: ShippingProviders.LOOMIS_EXPRESS, text: ShippingProviderNames.LOOMIS_EXPRESS },
];

export const NavItems: sidenavItems[] = [
  {
    routerLink: 'users',
    iconType: 'icons',
    icon: 'person',
    iconClass: 'icon-user',
    title: 'Users',
    active: false,
    scope: [AdminScopes.VIEW_USERS],
  },
  {
    routerLink: 'shipments',
    iconType: 'icons',
    icon: 'send',
    iconClass: 'icon-ship',
    title: 'Shipments',
    active: false,
    scope: [AdminScopes.VIEW_SHIPMENTS],
  },
  {
    routerLink: 'manifests',
    iconType: 'symbols',
    icon: 'export_notes',
    title: 'Manifests',
    active: false,
    scope: [AdminScopes.VIEW_MANIFESTS],
  },
  {
    routerLink: 'reports',
    iconType: 'icons',
    icon: 'query_stats',
    title: 'Report',
    active: false,
    scope: [AdminScopes.VIEW_REPORTS],
  },
  {
    routerLink: 'dashboard',
    iconType: 'icons',
    icon: 'space_dashboard',
    title: 'Dashboard',
    active: false,
    scope: [AdminScopes.VIEW_DASHBOARD],
  },
  {
    routerLink: 'invoices',
    icon: 'receipt_long',
    title: 'Invoices',
    active: false,
    scope: [AdminScopes.VIEW_INVOICES],
  },
  {
    routerLink: 'couriers',
    iconType: 'icons',
    icon: 'truck',
    iconClass: 'icon-truck',
    title: 'Couriers',
    active: false,
    scope: [AdminScopes.VIEW_COURIERS],
  },
  {
    routerLink: 'platforms',
    icon: 'storefront',
    title: 'Platforms',
    active: false,
    scope: [AdminScopes.VIEW_PLATFORMS],
  },
  {
    routerLink: 'reconciliation',
    iconType: 'icons',
    icon: 'icon-bill-dollar',
    iconClass: 'icon-bill-dollar',
    title: 'Reconciliation',
    active: false,
    scope: [AdminScopes.VIEW_RECONCILIATION],
  },
  {
    routerLink: 'unknown-tracking',
    iconType: 'icons',
    icon: 'icon-no-docs',
    iconClass: 'icon-no-docs',
    title: 'Unknown Tracking',
    active: false,
    scope: [AdminScopes.VIEW_UNKNOWN_TRACKING],
  },
  {
    routerLink: 'unbillable-shipment',
    iconType: 'icons',
    icon: 'icon-no',
    iconClass: 'icon-no',
    title: 'Do not bill',
    active: false,
    scope: [AdminScopes.VIEW_UNBILLABLE_SHIPMENTS],
  },
  {
    routerLink: 'finance',
    icon: 'credit_score',
    title: 'Finance',
    active: false,
    scope: [AdminScopes.VIEW_FINANCE],
  },
  {
    routerLink: 'permissions',
    icon: 'manage_accounts',
    title: 'Permissions',
    active: false,
    scope: [AdminScopes.VIEW_PERMISSIONS],
  },
  // {
  //   routerLink: 'service-builder',
  //   icon: 'widgets',
  //   title: 'Service builder',
  //   active: false,
  //   scope: [AdminScopes.VIEW_SERVICE_BUILDER],
  // },
  // {
  //   routerLink: 'notifications',
  //   icon: 'notifications',
  //   title: 'Notifications',
  //   active: false,
  //   scope: [AdminScopes.VIEW_NOTIFICATIONS],
  // },
  // {
  //   routerLink: 'cancellations',
  //   icon: 'error_outlined',
  //   title: 'Cancellations',
  //   active: false,
  //   scope: [AdminScopes.VIEW_CANCELLATIONS],
  // },
  // {
  //   routerLink: 'insurance-claims',
  //   icon: 'verified_user',
  //   title: 'Insurance claims',
  //   active: false,
  //   scope: [AdminScopes.VIEW_INSURANCE_CLAIMS],
  // },
  // {
  //   routerLink: 'api-keys',
  //   icon: 'api',
  //   title: 'API Keys',
  //   active: false,
  //   scope: [AdminScopes.VIEW_API_KEYS],
  // },
];
