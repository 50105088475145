import { forwardRef, NgModule } from '@angular/core';
import { HomeComponent } from '@app/pages/authenticated/pages/home/home.component';
import { AuthenticatedComponent } from './authenticated.component';
import { AuthenticatedRoutingModule } from './authenticated-routing.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FirebaseUIModule } from 'firebaseui-angular';
import { CommonModule } from '@angular/common';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { SharedModule } from '@app/shared/shared.module';
import { UsersModule } from './pages/users/users.module';
import { ComponentsModule } from '@app/shared/components/components.module';
import { ReconciliationModule } from '@app/pages/authenticated/pages/reconciliation/reconciliation.module';
import { CancellationsModule } from '@app/pages/authenticated/pages/cancellations/cancellations.module';
import { NotificationsModule } from '@app/pages/authenticated/pages/notifications/notifications.module';
import { ServiceBuilderModule } from '@app/pages/authenticated/pages/service-builder/service-builder.module';
import { InsuranceClaimsModule } from '@app/pages/authenticated/pages/insurance-claims/insurance-claims.module';
import { ReportsModule } from '@app/pages/authenticated/pages/reports/reports.module';
import { InvoicesModule } from '@app/pages/authenticated/pages/invoices/invoices.module';
import { ApiKeysModule } from '@app/pages/authenticated/pages/api-keys/api-keys.module';
import { TrackingComponent } from '@app/pages/authenticated/components/tracking/tracking.component';
import { AdminChargeDetailsComponent } from '@app/pages/authenticated/components/admin-charge-details/admin-charge-details.component';
import { AdminPresetDetailsComponent } from '@app/pages/authenticated/components/admin-preset-details/admin-preset-details.component';
import { CanadaPostReceiptComponent } from '@app/pages/authenticated/components/canada-post-receipt/canada-post-receipt.component';
import { EdiInvoiceComponent } from '@app/pages/authenticated/components/edi-invoice/edi-invoice.component';
import { ManifestsModule } from '@app/pages/authenticated/pages/manifests/manifests.module';
import { ViewDocumentComponent } from '@app/pages/authenticated/components/view-document/view-document.component';
import { DashboardModule } from '@app/pages/authenticated/pages/dashboard/dashboard.module';
import { CouriersModule } from '@app/pages/authenticated/pages/couriers/couriers.module';
import { UnknownTrackingModule } from '@app/pages/authenticated/pages/unknown-tracking/unknown-tracking.module';
import { UnbillableShipmentsModule } from './pages/unbillable-shipments/unbillable-shipments.module';
import { FinanceModule } from '@app/pages/authenticated/pages/finance/finance.module';
import { ClaimsModule } from '@app/pages/authenticated/pages/claims/claims.module';
import { ConfigurationModule } from '@app/pages/authenticated/pages/configuration/configuration.module';
import { OtpModule } from '@app/pages/authenticated/pages/otp/otp.module';

@NgModule({
  imports: [
    AngularFireModule,
    AngularFireAuthModule,
    ApiKeysModule,
    FinanceModule,
    ClaimsModule,
    AuthenticatedRoutingModule,
    CancellationsModule,
    CommonModule,
    ComponentsModule,
    CouriersModule,
    DashboardModule,
    FirebaseUIModule,
    InsuranceClaimsModule,
    NotificationsModule,
    ManifestsModule,
    ReconciliationModule,
    ReportsModule,
    InvoicesModule,
    ServiceBuilderModule,
    SharedModule,
    UsersModule,
    UnknownTrackingModule,
    UnbillableShipmentsModule,
    ConfigurationModule,
    OtpModule,
  ],
  declarations: [
    AdminChargeDetailsComponent,
    AuthenticatedComponent,
    CanadaPostReceiptComponent,
    HomeComponent,
    EdiInvoiceComponent,
    SideNavComponent,
    TopNavComponent,
    TrackingComponent,
    ViewDocumentComponent,
    AdminPresetDetailsComponent,
  ],
})
export class AuthenticatedModule {}
