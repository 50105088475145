import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { FormControl, FormGroup } from '@angular/forms';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { DateTime } from 'luxon';
import { ProvidersService } from '@app/shared/services/providers.service';
import { PageEvent } from '@angular/material/paginator';
import { OtpService } from '@app/shared/services/otp.service';
import { StateService, TimeBucketInfoInterface } from '@app/shared/services/state.service';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { AggregationType, ShipmentAggregateResponse } from '../../otp.interfaces';
import { ShippingProviders } from '@app/shared/constants';

@Component({
  selector: 'app-otp-couriers',
  templateUrl: './otp-couriers.component.html',
  styleUrls: ['./otp-couriers.component.scss'],
})
export class OtpCouriersComponent implements OnInit, OnDestroy {
  public timeBucket$ = this.stateService.timeBucketInfo$;
  private timeBucketSubscription: Subscription;
  public courierFilter$ = this.stateService.courierFilter$;
  private courierFilterSubscription: Subscription;
  public adminUserInfo$: Observable<any> = this.stateService.adminUserInfo$;
  private adminUserInfoSubscription: Subscription;
  public canViewOtp: boolean = false;
  public currentBucket: string;
  public courierFilter: string[];

  public loading: boolean = true;
  public currentPage: number = 0;
  public itemsPerPage: number = 5;
  public itemsPerPageOptions: number[] = [5, 10, 25];

  public daterange = {
    start: DateTime.now().minus({ day: 7 }).toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  };
  public range = new FormGroup({
    start: new FormControl<Date | null>(this.daterange.start),
    end: new FormControl<Date | null>(this.daterange.end),
  });
  public couriersDropdown = [];
  public couriersSetup: boolean = false;
  public allServiceMetrics = [];
  public bucketData: any;

  // Performance Summary
  public totalShipments: number = 0;
  public onTimeRate: number = 0;
  public averageDelay: number = 0;
  // Guaranteed Service Metrics
  public gTotalShipments: number = 1240;
  public gOnTimeRate: number = 86;
  public gOnTimeCount: number = 1066;
  public gAverageDelay: number = 2.3;

  // Donut chart data
  public gradeDistribution = [
    { name: 'Grade A', value: 0 },
    { name: 'Grade B', value: 0 },
    { name: 'Grade C', value: 0 },
    { name: 'Grade D', value: 0 },
    { name: 'Grade F', value: 0 },
  ];

  // Bar chart data
  public serviceBreakdown = [];
  public courierComparison = [];

  // Line chart data
  public performanceTrends = [
    {
      name: 'DHL',
      series: [
        { name: 'W18', value: 86 },
        { name: 'W19', value: 85 },
        { name: 'W20', value: 84 },
      ],
    },
    {
      name: 'FedEx',
      series: [
        { name: 'W18', value: 79 },
        { name: 'W19', value: 78 },
        { name: 'W20', value: 77 },
      ],
    },
    {
      name: 'UPS',
      series: [
        { name: 'W18', value: 73 },
        { name: 'W19', value: 72 },
        { name: 'W20', value: 71 },
      ],
    },
    {
      name: 'USPS',
      series: [
        { name: 'W18', value: 70 },
        { name: 'W19', value: 72 },
        { name: 'W20', value: 71 },
      ],
    },
    {
      name: 'Canada Post',
      series: [
        { name: 'W18', value: 70 },
        { name: 'W19', value: 72 },
        { name: 'W20', value: 71 },
      ],
    },
  ];

  // Mock data for the detailed metrics table
  public detailedMetrics: any = [];

  // Columns to display in the table
  public displayedColumns: string[] = [
    'courier',
    'total',
    'onTime',
    'onTimePercent',
    'avgDelay',
    'gradeA',
    'gradeB',
    'gradeC',
    'gradeD',
    'gradeF',
    'gService',
  ];

  // Courier-specific shipments table
  public isSingleCourierSelected: boolean = false;
  public selectedCourierName: string = '';
  public selectedCourierId: ShippingProviders;
  public courierShipments: any[] = [];
  public shipmentColumns: string[] = [
    'accountType',
    'courier',
    'trackingNumber',
    'shipmentCreatedAt',
    'estimatedDelivery',
    'pickupTime',
    'expectedDelivery',
    'actualDelivery',
    'delay',
    'performanceGrade',
    'isGuaranteed',
    'serviceName',
    'originCity',
    'originProvince',
    'destinationCity',
    'destinationProvince',
  ];
  public currentShipmentsPage: number = 0;
  public shipmentsPerPage: number = 10;
  public totalShipmentsCount: number = 0;

  constructor(
    private helpersService: HelpersService,
    private otpService: OtpService,
    private logger: LoggerService,
    private providersService: ProvidersService,
    private userService: UserService,
    private stateService: StateService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.adminUserInfo$.subscribe((adminUserInfo: AdminUser) => {
      this.canViewOtp = this.userService.hasAuthScope(adminUserInfo, [AdminScopes.VIEW_OTP]);
      if (this.canViewOtp) {
        this.timeBucket$.subscribe((timeBucketInfo: TimeBucketInfoInterface | null): void => {
          this.currentBucket = timeBucketInfo.timeBucket;
          this.getCourierData();
        });
        this.courierFilter$.subscribe((courierFilter: string[] | null): void => {
          this.courierFilter = courierFilter;
          this.checkSingleCourierSelected();
          this.getCourierData();
        });
      }
    });
  }

  ngOnDestroy() {
    this.adminUserInfoSubscription?.unsubscribe();
    this.timeBucketSubscription?.unsubscribe();
    this.courierFilterSubscription?.unsubscribe();
  }

  public pageChanged(pageEvent: PageEvent): void {
    this.itemsPerPage = pageEvent.pageSize;
    this.currentPage = pageEvent.pageIndex;
    const end = this.itemsPerPage * (this.currentPage + 1);
    const start = end - this.itemsPerPage;
  }

  public showAllServices(): void {
    this.serviceBreakdown = [];
    if (this.allServiceMetrics.length > 0) {
      this.allServiceMetrics.forEach((service) => {
        this.serviceBreakdown.push({
          name: service.name,
          value: service.totalShipments,
        });
      });
    }
  }

  private getCourierData() {
    this.loading = true;
    const params = { timeBucket: this.currentBucket, history: 1 };
    if (this.courierFilter?.length > 0) {
      _.extend(params, { couriers: this.courierFilter });
    }
    this.otpService.getCouriers(params).subscribe(
      (response) => {
        this.logger.log('GET Otp Couriers', response);
        this.bucketData = response;
        this.setupMetrics();
      },
      (error) => {
        this.logger.error('GET Otp Couriers error', error);
      }
    );
  }

  private setupMetrics() {
    const gradeDistributionData = this.bucketData?.gradeDistribution;
    if (gradeDistributionData?.data?.length > 0) {
      this.gradeDistribution[0].value = this.helpersService.sumObjValues(gradeDistributionData?.data, 'A');
      this.gradeDistribution[1].value = this.helpersService.sumObjValues(gradeDistributionData?.data, 'B');
      this.gradeDistribution[2].value = this.helpersService.sumObjValues(gradeDistributionData?.data, 'C');
      this.gradeDistribution[3].value = this.helpersService.sumObjValues(gradeDistributionData?.data, 'D');
      this.gradeDistribution[4].value = this.helpersService.sumObjValues(gradeDistributionData?.data, 'F');
    }
    const metricsData = this.bucketData?.metrics;
    if (metricsData?.length > 0) {
      this.detailedMetrics = [];
      this.courierComparison = [];
      this.gTotalShipments = 0;
      this.gOnTimeCount = 0;
      this.gOnTimeRate = 0;
      this.gAverageDelay = 0;
      let services = [];
      metricsData.forEach((courier: any) => {
        let gradeA = 0;
        let gradeB = 0;
        let gradeC = 0;
        let gradeD = 0;
        let gradeF = 0;
        let courierTotal = 0;
        let gServiceTotal = 0;
        let gServiceOnTime = 0;
        courier.data.forEach((data: any) => {
          gradeA += data?.gradeDistribution.A;
          gradeB += data?.gradeDistribution.B;
          gradeC += data?.gradeDistribution.C;
          gradeD += data?.gradeDistribution.D;
          gradeF += data?.gradeDistribution.A;
          courierTotal += data?.totalShipments;
          gServiceTotal += data?.guaranteedService.total;
          gServiceOnTime += data?.guaranteedService.onTime;
          this.gTotalShipments += data?.guaranteedService.total;
          this.gOnTimeCount += data?.guaranteedService.onTime;
          this.gAverageDelay += data?.guaranteedService.averageDelay;

          const serviceTypeBreakdown = data?.services;
          if (serviceTypeBreakdown && Object.keys(serviceTypeBreakdown).length > 0) {
            Object.keys(serviceTypeBreakdown).forEach((serviceCode) => {
              const serviceType = serviceTypeBreakdown[serviceCode];
              services.push({
                code: serviceCode,
                name: serviceType.serviceName,
                totalShipments: serviceType.totalShipments,
                onTimeShipments: serviceType.onTimeShipments,
                onTimePercentage: serviceType.onTimePercentage,
                averageDelay: serviceType.averageDelay,
              });
            });
          }
        });
        const gService = (gServiceOnTime / gServiceTotal) * 100 || 0;
        const courierName = this.helpersService.convertShipmentProviderToString(courier.courier);
        const totalShipments = this.helpersService.sumObjValues(courier?.data, 'totalShipments');
        const metrics = {
          courier: courierName,
          total: totalShipments,
          onTime: this.helpersService.sumObjValues(courier?.data, 'onTimeShipments'),
          onTimePercent: this.helpersService.sumObjValues(courier?.data, 'onTimePercentage'),
          avgDelay: this.helpersService.sumObjValues(courier?.data, 'averageDelay'),
          gradeA: gradeA / courierTotal,
          gradeB: gradeB / courierTotal,
          gradeC: gradeC / courierTotal,
          gradeD: gradeD / courierTotal,
          gradeF: gradeF / courierTotal,
          gService,
        };
        // TODO - just use an enum for this so we always have values
        if (!this.couriersSetup) {
          this.couriersDropdown.push({ id: courier.courier, text: courierName });
        }
        this.detailedMetrics.push(metrics);
        this.courierComparison.push({
          name: courierName,
          value: totalShipments,
        });
      });
      this.serviceBreakdown = [];
      if (services.length > 0) {
        this.allServiceMetrics = this.helpersService.sortArray(services, 'totalShipments');
        this.allServiceMetrics.slice(0, 5).forEach((service) => {
          this.serviceBreakdown.push({
            name: service.name,
            value: service.totalShipments,
          });
        });
      }
      this.gOnTimeRate = (this.gOnTimeCount / this.gTotalShipments) * 100 || 0;
    }
    const totalsData = this.bucketData?.totals;
    if (totalsData.data?.length > 0) {
      this.totalShipments = this.helpersService.sumObjValues(totalsData?.data, 'totalShipments');
      this.onTimeRate = this.helpersService.sumObjValues(totalsData?.data, 'onTimePercentage');
      this.averageDelay = this.helpersService.sumObjValues(totalsData?.data, 'averageDelay');
    }
    this.couriersSetup = true;
    this.loading = false;
  }

  private checkSingleCourierSelected(): void {
    if (this.courierFilter && this.courierFilter.length === 1) {
      this.isSingleCourierSelected = true;
      this.selectedCourierId = this.courierFilter[0] as unknown as ShippingProviders;
      this.selectedCourierName = this.helpersService.convertShipmentProviderToString(this.selectedCourierId);
      this.loadCourierShipments();
    } else {
      this.isSingleCourierSelected = false;
      this.selectedCourierName = '';
      this.courierShipments = [];
    }
  }

  private loadCourierShipments(): void {
    if (!this.isSingleCourierSelected || !this.currentBucket) return;

    this.otpService.getAggregateShipmentsCount(this.currentBucket, AggregationType.COURIER).subscribe(
      (response) => {
        this.totalShipmentsCount = response.count;
        this.fetchCourierShipments();
      },
      (error) => {
        this.logger.error('GET Shipments Count error', error);
      }
    );
  }

  private fetchCourierShipments(): void {
    const params = {
      page: this.currentShipmentsPage + 1,
      pageSize: this.shipmentsPerPage,
    };

    this.otpService.getAggregateShipments(this.currentBucket, AggregationType.COURIER, params).subscribe(
      (response: ShipmentAggregateResponse) => {
        this.logger.log('GET Courier Shipments', response);
        this.courierShipments = response.data.map((shipment) => {
          return {
            account_type: shipment.account_type,
            courier_name: this.helpersService.convertShipmentProviderToString(shipment.courier),
            tracking_number: shipment.tracking_number,
            shipment_created_at: shipment.shipment_created_at,
            pickup_time: shipment.pickup_time,
            expected_delivery: shipment.expected_delivery,
            actual_delivery: shipment.actual_delivery,
            estimated_delivery: shipment.estimated_delivery,
            origin_city: shipment.origin_city,
            origin_province: shipment.origin_province,
            destination_city: shipment.destination_city,
            destination_province: shipment.destination_province,
            performance_grade: shipment.performance_grade,
            service_name: shipment.service_name,
            is_guaranteed: shipment.is_guaranteed,
            delay: shipment.delay,
          };
        });
      },
      (error) => {
        this.logger.error('GET Courier Shipments error', error);
      }
    );
  }

  public shipmentPageChanged(pageEvent: PageEvent): void {
    this.shipmentsPerPage = pageEvent.pageSize;
    this.currentShipmentsPage = pageEvent.pageIndex;
    this.fetchCourierShipments();
  }
}
