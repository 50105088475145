import { Injectable } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ErrorMessages, ShippingProviderNames, ShippingProviders } from '@app/shared/constants';
import {
  AggregationType,
  BulkReprocessResponse,
  CourierPerformanceResponse,
  DashboardResponse,
  FlagShipmentOutlierResponse,
  RegionPerformanceResponse,
  ReprocessShipmentResponse,
  ServiceTypePerformanceResponse,
  ShipmentAggregateCountResponse,
  ShipmentAggregateResponse,
  TimeBucketResponse,
  TimePeriod,
  TriggerMetricsCalculationResponse,
} from '../../pages/authenticated/pages/otp/otp.interfaces';

@Injectable({ providedIn: 'root' })
export class OtpService {
  constructor(
    private helperService: HelpersService,
    private http: HttpClient
  ) {}

  public getSupportedCouriers(): any {
    return [
      {
        id: ShippingProviders.CANADA_POST,
        name: ShippingProviderNames.CANADA_POST,
      },
      {
        id: ShippingProviders.NATIONEX,
        name: ShippingProviderNames.NATIONEX,
      },
      {
        id: ShippingProviders.PUROLATOR,
        name: ShippingProviderNames.PUROLATOR,
      },
      {
        id: ShippingProviders.GLS,
        name: ShippingProviderNames.GLS,
      },
      {
        id: ShippingProviders.UPS,
        name: ShippingProviderNames.UPS,
      },
      {
        id: ShippingProviders.ICS_COURIER,
        name: ShippingProviderNames.ICS_COURIER,
      },
    ]
  }

  public getDashboard(params?: { timeBucket?: string; history?: number }): Observable<DashboardResponse> {
    const headers = this.helperService.getAuthHeaders();

    return this.http
      .get<DashboardResponse>(`${environment.TIMESERIES_SERVICES_URL}/metrics/dashboard`,
        { headers, params }
      )
      .pipe(
        map((res: DashboardResponse) => res),
        catchError((error: any) =>
          throwError(() => error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public getCouriers(params?: {
    timeBucket?: string;
    history?: number;
    couriers?: ShippingProviders[];
    guaranteedOnly?: boolean;
  }): Observable<CourierPerformanceResponse> {
    const headers = this.helperService.getAuthHeaders();
    return this.http
      .get<CourierPerformanceResponse>(`${environment.TIMESERIES_SERVICES_URL}/metrics/couriers`,
        { headers, params }
      )
      .pipe(
        map((res: CourierPerformanceResponse) => res),
        catchError((error: any) =>
          throwError(() => error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public getServiceTypes(params?: {
    timeBucket?: string;
    history?: number;
    courier?: ShippingProviders;
  }): Observable<ServiceTypePerformanceResponse> {
    const headers = this.helperService.getAuthHeaders();
    return this.http
      .get<ServiceTypePerformanceResponse>(`${environment.TIMESERIES_SERVICES_URL}/metrics/service-types`,
        { headers, params }
      )
      .pipe(
        map((res: ServiceTypePerformanceResponse) => res),
        catchError((error: any) =>
          throwError(() => error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public getRegions(params?: {
    timeBucket?: string;
    history?: number;
    originCity?: string;
    originProvince?: string;
    destinationCity?: string;
    destinationProvince?: string;
  }): Observable<RegionPerformanceResponse> {
    const headers = this.helperService.getAuthHeaders();
    return this.http
      .get<RegionPerformanceResponse>(`${environment.TIMESERIES_SERVICES_URL}/metrics/regions`,
        { headers, params }
      )
      .pipe(
        map((res: RegionPerformanceResponse) => res),
        catchError((error: any) =>
          throwError(() => error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public getAggregateShipments(
    timeBucket: string,
    aggregateType: AggregationType,
    params: { page: number; pageSize: number } = { page: 1, pageSize: 20 }
  ): Observable<ShipmentAggregateResponse> {
    const headers = this.helperService.getAuthHeaders();
    return this.http
      .get<ShipmentAggregateResponse>(
        `${environment.TIMESERIES_SERVICES_URL}/shipments/performance/aggregate/${timeBucket}/${aggregateType}/shipments`,
        { headers, params }
      )
      .pipe(
        map((res: ShipmentAggregateResponse) => res),
        catchError((error: any) =>
          throwError(() => error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public getAggregateShipmentsCount(
    timeBucket: string,
    aggregateType: AggregationType
  ): Observable<ShipmentAggregateCountResponse> {
    const headers = this.helperService.getAuthHeaders();
    return this.http
      .get<ShipmentAggregateCountResponse>(
        `${environment.TIMESERIES_SERVICES_URL}/shipments/performance/aggregate/${timeBucket}/${aggregateType}/shipments/count`,
        { headers }
      )
      .pipe(
        map((res: ShipmentAggregateCountResponse) => res),
        catchError((error: any) =>
          throwError(() => error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public getTimeBuckets(params?: {
    refresh?: boolean;
    aggregateType?: AggregationType;
  }): Observable<TimeBucketResponse> {
    const headers = this.helperService.getAuthHeaders();

    return this.http
      .get<TimeBucketResponse>(`${environment.TIMESERIES_SERVICES_URL}/metrics/time-buckets`,
        { headers, params }
      )
      .pipe(
        map((res: TimeBucketResponse) => res),
        catchError((error: any) =>
          throwError(() => error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public postRecalculate(params?: {
    date?: string;
    periods?: TimePeriod[];
  }): Observable<TriggerMetricsCalculationResponse> {
    const headers = this.helperService.getAuthHeaders();

    return this.http
      .post<TriggerMetricsCalculationResponse>(
        `${environment.TIMESERIES_SERVICES_URL}/metrics/calculate`,
        params,
        { headers }
      )
      .pipe(
        map((res: TriggerMetricsCalculationResponse) => res),
        catchError((error: any) =>
          throwError(() => error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public flagShipmentAsOutlier(
    shipmentId: number,
    data: { isOutlier: boolean; notes?: string }
  ): Observable<FlagShipmentOutlierResponse> {
    const headers = this.helperService.getAuthHeaders();

    return this.http
      .patch<FlagShipmentOutlierResponse>(
        `${environment.TIMESERIES_SERVICES_URL}/shipments/${shipmentId}/performance/outlier`,
        data,
        { headers }
      )
      .pipe(
        map((res: FlagShipmentOutlierResponse) => res),
        catchError((error: any) =>
          throwError(() => error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public reprocessShipment(
    shipmentId: number,
    data: {
      trackingEventsSource: string;
      companyId: number
    }
  ): Observable<ReprocessShipmentResponse> {
    const headers = this.helperService.getAuthHeaders();

    return this.http
      .post<ReprocessShipmentResponse>(
        `${environment.TIMESERIES_SERVICES_URL}/shipments/${shipmentId}/performance/reprocess`,
        data,
        { headers }
      )
      .pipe(
        map((res: ReprocessShipmentResponse) => res),
        catchError((error: any) =>
          throwError(() => error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public bulkReprocessShipments(
    data: { redisKey: string }
  ): Observable<BulkReprocessResponse> {
    const headers = this.helperService.getAuthHeaders();

    return this.http
      .post<BulkReprocessResponse>(
        `${environment.TIMESERIES_SERVICES_URL}/shipments/performance/bulk-reprocess`,
        data,
        { headers }
      )
      .pipe(
        map((res: BulkReprocessResponse) => res),
        catchError((error: any) =>
          throwError(() => error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }
}
