import { FormBuilder, FormGroup } from '@angular/forms';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { ProvidersService } from '@app/shared/services/providers.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import {
  CanadaHeatmapComponent,
  LocationData,
  ProvinceSelectionEvent,
} from '@app/pages/authenticated/pages/otp/components/canada-heat-map/canada-heat-map.component';
import { StateService } from '@app/shared/services/state.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-otp-regions',
  templateUrl: './otp-regions.component.html',
  styleUrls: ['./otp-regions.component.scss'],
})
export class OtpRegionsComponent implements OnInit, OnDestroy {
  @ViewChild('heatmap') heatmapComponent!: CanadaHeatmapComponent;

  public timeBucket$ = this.stateService.timeBucketInfo$;
  private timeBucketSubscription: Subscription;
  public courierFilter$ = this.stateService.courierFilter$;
  private courierFilterSubscription: Subscription;
  public adminUserInfo$: Observable<any> = this.stateService.adminUserInfo$;
  private adminUserInfoSubscription: Subscription;
  public canViewOtp: boolean = false;
  public currentBucket: string;

  public loading: boolean = true;
  public form: FormGroup | undefined;

  selectedProvince: string | null = null;
  locationCount = 0;

  locationData: LocationData[] = [
    {
      latitude: 43.6532,
      longitude: -79.3832,
      volume: 100,
      name: 'Toronto',
      province: 'Ontario',
      // Example of hierarchical data
      subLocations: [
        { latitude: 43.6426, longitude: -79.3871, volume: 35, name: 'Downtown Toronto' },
        { latitude: 43.6547, longitude: -79.4120, volume: 25, name: 'West Toronto' },
        { latitude: 43.6689, longitude: -79.3452, volume: 30, name: 'East Toronto' }
      ]
    },
    {
      latitude: 45.5017,
      longitude: -73.5673,
      volume: 80,
      name: 'Montreal',
      province: 'Quebec',
      subLocations: [
        { latitude: 45.5088, longitude: -73.5878, volume: 30, name: 'Downtown Montreal' },
        { latitude: 45.5224, longitude: -73.5905, volume: 20, name: 'Mont-Royal' },
        { latitude: 45.4714, longitude: -73.6142, volume: 25, name: 'Westmount' }
      ]
    },
    { latitude: 49.2827, longitude: -123.1207, volume: 60, name: 'Vancouver', province: 'British Columbia' },
    { latitude: 51.0447, longitude: -114.0719, volume: 40, name: 'Calgary', province: 'Alberta' },
    { latitude: 53.5461, longitude: -113.4938, volume: 30, name: 'Edmonton', province: 'Alberta' },
    { latitude: 49.8951, longitude: -97.1384, volume: 20, name: 'Winnipeg', province: 'Manitoba' }
  ];

  // Detailed data for Quebec (would typically come from an API)
  quebecDetailedData: LocationData[] = [
    { latitude: 45.5017, longitude: -73.5673, volume: 80, name: 'Montreal' },
    { latitude: 46.8139, longitude: -71.2080, volume: 40, name: 'Quebec City' },
    { latitude: 45.4112, longitude: -71.8168, volume: 15, name: 'Sherbrooke' },
    { latitude: 45.3834, longitude: -73.9746, volume: 10, name: 'Vaudreuil-Dorion' },
    { latitude: 45.6809, longitude: -73.4391, volume: 8, name: 'Terrebonne' },
    { latitude: 45.5479, longitude: -73.7408, volume: 12, name: 'Laval' },
    { latitude: 45.5333, longitude: -73.5167, volume: 9, name: 'Longueuil' },
    { latitude: 48.4237, longitude: -71.0687, volume: 7, name: 'Saguenay' },
    { latitude: 45.9471, longitude: -74.1171, volume: 5, name: 'Saint-Jérôme' },
  ];

  // Montreal area detailed data (would typically come from an API)
  montrealDetailedData: LocationData[] = [
    { latitude: 45.5088, longitude: -73.5878, volume: 30, name: 'Downtown Montreal' },
    { latitude: 45.5224, longitude: -73.5905, volume: 20, name: 'Mont-Royal' },
    { latitude: 45.4714, longitude: -73.6142, volume: 25, name: 'Westmount' },
    { latitude: 45.5236, longitude: -73.6099, volume: 18, name: 'Outremont' },
    { latitude: 45.5235, longitude: -73.5514, volume: 12, name: 'Le Plateau' },
    { latitude: 45.4930, longitude: -73.5850, volume: 14, name: 'Griffintown' },
    { latitude: 45.5391, longitude: -73.6070, volume: 9, name: 'Mile End' },
    { latitude: 45.5069, longitude: -73.5339, volume: 7, name: 'Hochelaga' },
    { latitude: 45.4665, longitude: -73.5673, volume: 10, name: 'Verdun' },
    { latitude: 45.5123, longitude: -73.5555, volume: 8, name: 'Old Port' }
  ];


  constructor(
    private formBuilder: FormBuilder,
    private helpersService: HelpersService,
    private logger: LoggerService,
    private providersService: ProvidersService,
    private userService: UserService,
    private stateService: StateService,
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.adminUserInfoSubscription = this.adminUserInfo$.subscribe((adminUserInfo: AdminUser) => {
      this.canViewOtp = this.userService.hasAuthScope(adminUserInfo, [AdminScopes.VIEW_OTP]);
      if (this.canViewOtp) {
        // TODO - Get content
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.adminUserInfoSubscription?.unsubscribe();
    this.timeBucketSubscription?.unsubscribe();
    this.courierFilterSubscription?.unsubscribe();
  }

  onProvinceSelected(event: ProvinceSelectionEvent): void {
    this.selectedProvince = event.province;

    // Count locations in this province
    this.locationCount = this.locationData.filter(loc => loc.province === event.province).length;

    // If Quebec is selected, we can pre-load its detailed data
    if (event.province === 'Quebec') {
      setTimeout(() => {
        // Wait a moment to let zoom animation complete
        this.heatmapComponent.setDetailedData(this.quebecDetailedData);
      }, 800);
    }
  }

  onRequestDetailData(event: {province: string, bounds: [[number, number], [number, number]], zoomLevel: number}): void {
    // Simulate fetching detailed data based on province and zoom level
    if (event.province === 'Quebec') {
      if (event.zoomLevel > 8) {
        // Very detailed level - e.g., Montreal neighborhoods
        this.heatmapComponent.setDetailedData(this.montrealDetailedData);
      } else {
        // Province level detail
        this.heatmapComponent.setDetailedData(this.quebecDetailedData);
      }
    } else if (event.province === 'Ontario') {
      // Would fetch Ontario detailed data from an API
    } else {
      //TODO: phase 4
    }
  }
}
