<ng-container *ngIf='!loading, else isLoading'>
  <form *ngIf='filterForm' [formGroup]="filterForm">
    <button *ngIf="canModifyOtp" class='mr--md' mat-button color="primary" (click)='openRecalculatePanel()'>Recalculate</button>
    <mat-form-field appearance="outline" class='mr--md'>
      <mat-label>Interval</mat-label>
      <mat-select formControlName="interval">
        <mat-option *ngFor='let interval of timeIntervals' value="{{ interval }}">{{ interval | titlecase }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class='mr--md'>
      <mat-label>Period</mat-label>
      <mat-select formControlName="period">
        <mat-option *ngFor='let bucket of timeBuckets' value="{{ bucket.id }}">{{ bucket.displayName }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>History</mat-label>
      <mat-select formControlName="history">
        <mat-option *ngFor='let option of historyOptions' [value]="option">{{ option }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
