<div class="dashboard">
  <!-- Standard Header Bar -->
  <div class='row'>
    <div class='column--12'>
      <mat-card class="summary-card">
        <mat-card-content class='card p--md'>
          <div class='row'>
            <div class='column--12 align-right'>
              <div class="header-actions">
                <button mat-raised-button color="primary">Trigger Metrics</button>
                <button mat-raised-button color="basic">Export</button>
              </div>
            </div>
            <div class='column-12'>
              <!-- Filter Bar -->
              <div class="filter-bar">
                <mat-form-field appearance="outline" class='mr--md'>
                  <mat-select placeholder="Date Range">
                    <mat-option value="range1">Range 1</mat-option>
                    <mat-option value="range2">Range 2</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class='mr--md'>
                  <mat-select placeholder="Courier">
                    <mat-option value="dhl">DHL</mat-option>
                    <mat-option value="purolator">Purolator</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class='mr--md'>
                  <mat-select placeholder="Service Type">
                    <mat-option value="express">Express</mat-option>
                    <mat-option value="ground">Ground</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Region">
                    <mat-option value="north">North</mat-option>
                    <mat-option value="south">South</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class='column-12'>
              <!-- Navigation Tabs -->
              <div class="navigation-tabs">
                <button mat-button>Overall Performance</button>
                <button mat-button>Courier Comparison</button>
                <button mat-raised-button color="primary">Regional Analysis</button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class='column--12'>
      <!-- Route Details Header -->
      <mat-card class="summary-card">
        <mat-card-content class='card p--md'>
          <div class='row'>
            <div class='column--8'>
              <h2>Toronto, ON → Montreal, QC</h2>
              <p>Route Details | Time Period: 2024-W06</p>
            </div>
            <div class='column--4 align-right'>
              <button mat-raised-button color="basic">Back</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class='column--12'>
      <div class='row'>
        <div class='column--3'>
          <mat-card class="summary-card">
            <mat-card-content class='card p--md metric-card'>
              <h3>Total Volume</h3>
              <p class="value">3,245</p>
              <p class="subtext">shipments this period</p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class='column--3'>
          <mat-card class="summary-card">
            <mat-card-content class='card p--md metric-card'>
              <h3>On-time Performance</h3>
              <p class="value">95.2%</p>
              <p class="subtext">on-time delivery rate</p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class='column--3'>
          <mat-card class="summary-card">
            <mat-card-content class='card p--md metric-card'>
              <h3>Average Delay</h3>
              <p class="value">1.2h</p>
              <p class="subtext">average delay time</p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class='column--3'>
          <mat-card class="summary-card">
            <mat-card-content class='card p--md metric-card'>
              <h3>Service Coverage</h3>
              <p class="value">4/5</p>
              <p class="subtext">active couriers</p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    <div class='column--12'>
      <!-- Courier Performance Comparison -->
      <mat-card class="summary-card">
        <mat-card-content class='card p--md'>
          <h2>Courier Performance on this Route</h2>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
            <!-- Courier Column -->
            <ng-container matColumnDef="courier">
              <th mat-header-cell *matHeaderCellDef>Courier</th>
              <td mat-cell *matCellDef="let element">{{ element.courier }}</td>
            </ng-container>

            <!-- Volume Column -->
            <ng-container matColumnDef="volume">
              <th mat-header-cell *matHeaderCellDef>Volume</th>
              <td mat-cell *matCellDef="let element">{{ element.volume }}</td>
            </ng-container>

            <!-- On-time % Column -->
            <ng-container matColumnDef="onTime">
              <th mat-header-cell *matHeaderCellDef>On-time %</th>
              <td mat-cell *matCellDef="let element">{{ element.onTime }}</td>
            </ng-container>

            <!-- Avg Delay Column -->
            <ng-container matColumnDef="avgDelay">
              <th mat-header-cell *matHeaderCellDef>Avg Delay</th>
              <td mat-cell *matCellDef="let element">{{ element.avgDelay }}</td>
            </ng-container>

            <!-- Service Types Column -->
            <ng-container matColumnDef="serviceTypes">
              <th mat-header-cell *matHeaderCellDef>Service Types</th>
              <td mat-cell *matCellDef="let element">{{ element.serviceTypes }}</td>
            </ng-container>

            <!-- Delay Distribution Column -->
            <ng-container matColumnDef="delayDistribution">
              <th mat-header-cell *matHeaderCellDef>Delay Distribution</th>
              <td mat-cell *matCellDef="let element">
                <div class="delay-distribution">
                  <div class="delay-bar" [style.width]="element.delayDistribution[0] + '%'" style="background-color: #198754;"></div>
                  <div class="delay-bar" [style.width]="element.delayDistribution[1] + '%'" style="background-color: #28a745;"></div>
                  <div class="delay-bar" [style.width]="element.delayDistribution[2] + '%'" style="background-color: #ffc107;"></div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
