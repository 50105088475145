import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import {
  BillingTypes,
  BillingTypesVerbose,
  CompanyNoteType,
  CompanyNoteVerbose,
  EcommerceProviderNames,
  EcommerceProviders,
  InsuranceProviderNames,
  InsuranceProviders,
  LegacyShipmentStatuses,
  PanelWidths,
  PaymentTypes,
  PaymentTypesVerbose,
  PricingTiers,
  PricingTierVerbose,
  Roles,
  RolesVerbose,
  ShipmentStatuses,
  ShipmentStatusesVerbose,
  ShippingProviderNames,
  ShippingProviders,
  SupportedReconProvider,
  TRACKING_TIMESTAMP_EXT_FORMAT,
  TRACKING_TIMESTAMP_FORMAT,
} from '../constants';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { CurrencyPipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class HelpersService {
  constructor(private sessionStorageService: SessionStorageService) {}

  public sumRates(rates): number {
    return _.reduce(rates, (sum, t) => sum + t.amount, 0);
  }

  public sumObjValues(objArray: any[], key: string): number {
    if (Array.isArray(objArray) && objArray.length > 0) {
      return objArray.reduce((sum, value) => sum + value[key] || 0, 0);
    } else {
      return 0;
    }
  }

  public getAuthHeaders(): { uid: string; authorization: string } {
    const currentUser = this.sessionStorageService.retrieve('currentUser');
    if (!currentUser) {
      return { uid: '', authorization: '' };
    } else {
      return {
        uid: currentUser.uid,
        authorization: 'Bearer ' + currentUser.idToken,
      };
    }
  }

  public convertSupportedReconProvidersEnumToString(
    provider: SupportedReconProvider
  ): ShippingProviderNames | InsuranceProviderNames {
    switch (provider) {
      case SupportedReconProvider.PUROLATOR:
        return ShippingProviderNames.PUROLATOR;
      case SupportedReconProvider.DHL:
        return ShippingProviderNames.DHL;
      case SupportedReconProvider.GLS:
        return ShippingProviderNames.GLS;
      case SupportedReconProvider.NATIONEX:
        return ShippingProviderNames.NATIONEX;
      case SupportedReconProvider.LIVRAPIDE:
        return ShippingProviderNames.LIVRAPIDE;
      case SupportedReconProvider.CANPAR:
        return ShippingProviderNames.CANPAR;
      case SupportedReconProvider.FEDEX:
        return ShippingProviderNames.FEDEX;
      case SupportedReconProvider.CANADA_POST:
        return ShippingProviderNames.CANADA_POST;
      case SupportedReconProvider.CANADA_POST_3PL:
        return ShippingProviderNames.CANADA_POST_3PL;
      case SupportedReconProvider.UNIUNI:
        return ShippingProviderNames.UNIUNI;
      case SupportedReconProvider.FLASHBOX:
        return ShippingProviderNames.FLASHBOX;
      case SupportedReconProvider.CHASSEURS_COURRIER:
        return ShippingProviderNames.CHASSEURS_COURRIER;
      case SupportedReconProvider.UPS:
        return ShippingProviderNames.UPS;
      case SupportedReconProvider.INTELCOM_MULTI_LEG:
        return ShippingProviderNames.INTELCOM_MULTI_LEG;
      case SupportedReconProvider.APPLE_EXPRESS_MULTI_LEG:
        return ShippingProviderNames.APPLE_EXPRESS_MULTI_LEG;
      case SupportedReconProvider.UNIUNI_MULTI_LEG:
        return ShippingProviderNames.UNIUNI_MULTI_LEG;
      case SupportedReconProvider.T_FORCE_MULTI_LEG:
        return ShippingProviderNames.T_FORCE_MULTI_LEG;
      case SupportedReconProvider.ICS_COURIER:
        return ShippingProviderNames.ICS_COURIER;
      case SupportedReconProvider.COURANT_PLUS:
        return ShippingProviderNames.COURANT_PLUS;
      case SupportedReconProvider.GOBOLT:
        return ShippingProviderNames.GOBOLT;
      case SupportedReconProvider.DELIVRO:
        return ShippingProviderNames.DELIVRO;
      case SupportedReconProvider.DAYROSS:
        return ShippingProviderNames.DAYROSS;
      case SupportedReconProvider.MARSH:
        return InsuranceProviderNames.MARSH;
      case SupportedReconProvider.UPIC:
        return InsuranceProviderNames.UPIC;
      case SupportedReconProvider.RIVO:
      case SupportedReconProvider.RIVO_MULTI_LEG:
        return ShippingProviderNames.AIR_CANADA;
      case SupportedReconProvider.LOOMIS_EXPRESS:
        return ShippingProviderNames.LOOMIS_EXPRESS;
      default:
        return InsuranceProviderNames.UNKNOWN;
    }
  }

  public convertPaymentTypesEnumToString(paymentTypes: PaymentTypes): PaymentTypesVerbose {
    switch (paymentTypes) {
      case PaymentTypes.EFT:
        return PaymentTypesVerbose.EFT;
      case PaymentTypes.E_TRANSFER:
        return PaymentTypesVerbose.E_TRANSFER;
      case PaymentTypes.CHEQUE:
      default:
        return PaymentTypesVerbose.CHEQUE;
    }
  }

  public convertPricingTiersEnumToString(tier: PricingTiers): PricingTierVerbose {
    switch (tier) {
      case PricingTiers.BRONZE:
        return PricingTierVerbose.BRONZE;
      case PricingTiers.SILVER:
        return PricingTierVerbose.SILVER;
      case PricingTiers.GOLD:
        return PricingTierVerbose.GOLD;
      case PricingTiers.AFFINITY_CQCD:
        return PricingTierVerbose.AFFINITY_CQCD;
      case PricingTiers.CORPORATE:
        return PricingTierVerbose.CORPORATE;
      case PricingTiers.EMPLOYEE:
        return PricingTierVerbose.EMPLOYEE;
      case PricingTiers.AFFINITY_RCC:
        return PricingTierVerbose.AFFINITY_RCC;
      case PricingTiers.AFFINITY_SHIPALBERTA:
        return PricingTierVerbose.AFFINITY_SHIPALBERTA;
      case PricingTiers.PERSONAL:
        return PricingTierVerbose.PERSONAL;
      default:
        return PricingTierVerbose.BRONZE;
    }
  }

  public convertBillingTypesEnumToString(billingType: BillingTypes): BillingTypesVerbose {
    switch (billingType) {
      case BillingTypes.CREDIT_CARD:
        return BillingTypesVerbose.CREDIT_CARD;
      case BillingTypes.MACHOOL_ACCOUNT:
        return BillingTypesVerbose.MACHOOL_ACCOUNT;
      case BillingTypes.THIRDPARTY_ACCOUNT:
        return BillingTypesVerbose.THIRDPARTY_ACCOUNT;
      default:
        return BillingTypesVerbose.CREDIT_CARD;
    }
  }

  public convertNoteTypesEnumToString(noteType: CompanyNoteType): CompanyNoteVerbose {
    switch (noteType) {
      case CompanyNoteType.HAPPY:
        return CompanyNoteVerbose.HAPPY;
      case CompanyNoteType.CONFUSED:
        return CompanyNoteVerbose.CONFUSED;
      case CompanyNoteType.NEUTRAL:
        return CompanyNoteVerbose.NEUTRAL;
      case CompanyNoteType.UNHAPPY:
      default:
        return CompanyNoteVerbose.UNHAPPY;
    }
  }

  public convertRolesEnumToString(role: Roles): RolesVerbose {
    switch (role) {
      case Roles.USER:
        return RolesVerbose.USER;
      case Roles.ADMINISTRATOR:
        return RolesVerbose.ADMINISTRATOR;
      case Roles.OWNER:
        return RolesVerbose.OWNER;
      case Roles.MACHOOL:
        return RolesVerbose.MACHOOL;
      case Roles.SUPPORT:
        return RolesVerbose.SUPPORT;
      default:
        return RolesVerbose.USER;
    }
  }

  public convertShipmentStatusEnumToString(status: ShipmentStatuses): ShipmentStatusesVerbose {
    switch (status) {
      case ShipmentStatuses.CREATED:
        return ShipmentStatusesVerbose.CREATED;
      case ShipmentStatuses.CONFIRMED:
        return ShipmentStatusesVerbose.CONFIRMED;
      case ShipmentStatuses.CANCELLED:
        return ShipmentStatusesVerbose.CANCELLED;
      case ShipmentStatuses.UNCONFIRMED:
        return ShipmentStatusesVerbose.UNCONFIRMED;
      case ShipmentStatuses.PENDING_CANCEL:
        return ShipmentStatusesVerbose.PENDING_CANCEL;
      case ShipmentStatuses.CANCEL_REJECTED:
        return ShipmentStatusesVerbose.CANCEL_REJECTED;
      case ShipmentStatuses.PARTIALLY_DELIVERED:
        return ShipmentStatusesVerbose.PARTIALLY_DELIVERED;
      default:
        return ShipmentStatusesVerbose.UNKNOWN;
    }
  }

  public convertLegacyShipmentStatusEnumToString(status: LegacyShipmentStatuses): ShipmentStatusesVerbose {
    switch (status) {
      case LegacyShipmentStatuses.CREATED:
        return ShipmentStatusesVerbose.CREATED;
      case LegacyShipmentStatuses.CONFIRMED:
        return ShipmentStatusesVerbose.CONFIRMED;
      case LegacyShipmentStatuses.CANCELLED:
        return ShipmentStatusesVerbose.CANCELLED;
      case LegacyShipmentStatuses.UNCONFIRMED:
        return ShipmentStatusesVerbose.UNCONFIRMED;
      case LegacyShipmentStatuses.PENDING_CANCEL:
        return ShipmentStatusesVerbose.PENDING_CANCEL;
      case LegacyShipmentStatuses.CANCEL_REJECTED:
        return ShipmentStatusesVerbose.CANCEL_REJECTED;
      case LegacyShipmentStatuses.PARTIALLY_DELIVERED:
        return ShipmentStatusesVerbose.PARTIALLY_DELIVERED;
      case LegacyShipmentStatuses.TRACKING_DELIVERED:
        return ShipmentStatusesVerbose.DELIVERED;
      case LegacyShipmentStatuses.TRACKING_ATTENTION:
        return ShipmentStatusesVerbose.ATTENTION;
      case LegacyShipmentStatuses.TRACKING_IN_TRANSIT:
        return ShipmentStatusesVerbose.IN_TRANSIT;
      default:
        return ShipmentStatusesVerbose.UNKNOWN;
    }
  }

  public convertEcommerceProviderToString(provider: EcommerceProviders): EcommerceProviderNames | string {
    switch (provider) {
      case EcommerceProviders.SHOPIFY:
        return EcommerceProviderNames.SHOPIFY;
      case EcommerceProviders.LIGHTSPEED:
        return EcommerceProviderNames.LIGHTSPEED;
      case EcommerceProviders.WOOCOMMERCE:
        return EcommerceProviderNames.WOOCOMMERCE;
      case EcommerceProviders.MAGENTO:
        return EcommerceProviderNames.MAGENTO;
      case EcommerceProviders.WIX:
        return EcommerceProviderNames.WIX;
      case EcommerceProviders.ETSY:
        return EcommerceProviderNames.ETSY;
      case EcommerceProviders.BIGCOMMERCE:
        return EcommerceProviderNames.BIGCOMMERCE;
      case EcommerceProviders.DVORE:
        return EcommerceProviderNames.DVORE;
      case EcommerceProviders.PANIERDACHAT:
        return EcommerceProviderNames.PANIERDACHAT;
      case EcommerceProviders.SQUARESPACE:
        return EcommerceProviderNames.SQUARESPACE;
      case EcommerceProviders.TECHPOS:
        return EcommerceProviderNames.TECHPOS;
      case EcommerceProviders.XCART:
        return EcommerceProviderNames.XCART;
      case EcommerceProviders.LEPANIERBLEU:
        return EcommerceProviderNames.LEPANIERBLEU;
      case EcommerceProviders.LIGHTSPEED_E_SERIES:
        return EcommerceProviderNames.LIGHTSPEED_E_SERIES;
      default:
        return '';
    }
  }

  public convertShipmentProviderToString(
    provider: ShippingProviders,
    serviceName?: string,
    isSearchName = false
  ): ShippingProviderNames | string {
    switch (provider) {
      case ShippingProviders.CANADA_POST:
        return ShippingProviderNames.CANADA_POST;
      case ShippingProviders.CANADA_POST_3PL:
        return ShippingProviderNames.CANADA_POST_3PL;
      case ShippingProviders.CANPAR:
        return ShippingProviderNames.CANPAR;
      case ShippingProviders.CHASSEURS_COURRIER:
        return ShippingProviderNames.CHASSEURS_COURRIER;
      case ShippingProviders.GLS:
        return ShippingProviderNames.GLS;
      case ShippingProviders.DHL:
        return ShippingProviderNames.DHL;
      case ShippingProviders.INTELCOM:
        return ShippingProviderNames.INTELCOM;
      case ShippingProviders.FEDEX:
        return ShippingProviderNames.FEDEX;
      case ShippingProviders.FEDEX_EXPRESS.toLowerCase():
        return ShippingProviderNames.FEDEX_EXPRESS;
      case ShippingProviders.FEDEX_GROUND.toLowerCase():
        return ShippingProviderNames.FEDEX_GROUND;
      case ShippingProviders.PUROLATOR:
        return ShippingProviderNames.PUROLATOR;
      case ShippingProviders.UPS:
        return ShippingProviderNames.UPS;
      case ShippingProviders.DHL:
        return ShippingProviderNames.DHL;
      case ShippingProviders.NATIONEX:
        return ShippingProviderNames.NATIONEX;
      case ShippingProviders.SHIFT:
        return ShippingProviderNames.SHIFT;
      case ShippingProviders.EEKO:
        return ShippingProviderNames.EEKO;
      case ShippingProviders.RIVO:
        return serviceName || (isSearchName ? 'Multi-Courier' : 'Air Canada Cargo');
      case ShippingProviders.UNIUNI:
        return ShippingProviderNames.UNIUNI;
      case ShippingProviders.FLASHBOX:
        return ShippingProviderNames.FLASHBOX;
      case ShippingProviders.SPRING:
        return ShippingProviderNames.SPRING;
      case ShippingProviders.TREXITY:
        return ShippingProviderNames.TREXITY;
      case ShippingProviders.APPLE_EXPRESS:
        return ShippingProviderNames.APPLE_EXPRESS;
      case ShippingProviders.TFORCE_LOGISTICS:
        return ShippingProviderNames.TFORCE_LOGISTICS;
      case ShippingProviders.LIVRAPIDE:
        return ShippingProviderNames.LIVRAPIDE;
      case ShippingProviders.ICS_COURIER:
        return ShippingProviderNames.ICS_COURIER;
      case ShippingProviders.EVA:
        return ShippingProviderNames.EVA;
      case ShippingProviders.DELIVRO:
        return ShippingProviderNames.DELIVRO;
      case ShippingProviders.DEELEEO:
        return ShippingProviderNames.DEELEEO;
      case ShippingProviders.ULALA:
        return ShippingProviderNames.ULALA;
      case ShippingProviders.MDX:
        return ShippingProviderNames.MDX;
      case ShippingProviders.LOOMIS_EXPRESS:
        return ShippingProviderNames.LOOMIS_EXPRESS;
      case ShippingProviders.MACHOOL_LOGISTICS:
        return ShippingProviderNames.MACHOOL_LOGISTICS;
      // TODO: Legacy providers. Remove once old shipment data archived (4266)
      case ShippingProviders.GOBOLT:
        return ShippingProviderNames.GOBOLT;
      case ShippingProviders.COURANT_PLUS:
        return ShippingProviderNames.COURANT_PLUS;
      case ShippingProviders.UBER:
        return ShippingProviderNames.UBER;
      case ShippingProviders.DAYROSS:
        return ShippingProviderNames.DAYROSS;
      case ShippingProviders.FREIGHT_AIR_CANADA:
        return ShippingProviderNames.FREIGHT_AIR_CANADA;
      case ShippingProviders.FREIGHT_GLS:
        return ShippingProviderNames.FREIGHT_GLS;
      default:
        return ShippingProviderNames.UNKNOWN;
    }
  }

  public convertInsuranceProviderToString(insuranceProvider: InsuranceProviders): InsuranceProviderNames {
    switch (insuranceProvider) {
      case InsuranceProviders.UPIC:
        return InsuranceProviderNames.UPIC;
      case InsuranceProviders.MARSH:
        return InsuranceProviderNames.MARSH;
      default:
        return InsuranceProviderNames.UNKNOWN;
    }
  }

  public formatToTwoLinesAddress(
    line1: string,
    line2: string,
    city: string,
    province: string,
    country: string,
    postalCode: string
  ): { firstLine: string; secondLine: string } {
    let firstLine = line1;
    if (line2) firstLine += ' ' + line2;

    const secondLineArray = [];
    if (city) secondLineArray.push(city);
    if (province) secondLineArray.push(province);
    if (postalCode) secondLineArray.push(postalCode.toUpperCase());
    if (country) secondLineArray.push(country);
    const secondLine = secondLineArray.join(', ');

    return {
      firstLine,
      secondLine,
    };
  }

  public b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  public getDrawerWidth() {
    const screenWidth = window.innerWidth;
    if (screenWidth < parseInt(PanelWidths.desktopFull.replace('px', ''))) {
      return PanelWidths.mobileFull;
    } else {
      return PanelWidths.desktopFull;
    }
  }

  public getValidDateTime(dateString: string, opts: any): DateTime {
    const isUtc = _.isEmpty(opts) || !_.get(opts, 'zone', false);
    let timeObj;
    if (DateTime.fromISO(dateString).isValid) {
      timeObj = DateTime.fromISO(dateString, opts);
    } else if (DateTime.fromFormat(dateString, TRACKING_TIMESTAMP_FORMAT, opts).isValid) {
      timeObj = DateTime.fromFormat(dateString, TRACKING_TIMESTAMP_FORMAT, opts);
    } else if (DateTime.fromFormat(dateString, TRACKING_TIMESTAMP_EXT_FORMAT, opts).isValid) {
      timeObj = DateTime.fromFormat(dateString, TRACKING_TIMESTAMP_EXT_FORMAT, opts);
    } else {
      const error = `Unrecognized dateString format: ${dateString}`;
      console.error(error);
      return DateTime.invalid(error);
    }
    return isUtc ? timeObj.toUTC() : timeObj;
  }

  public getEnumTotal(e: any): number {
    const valueArray = this.getValues(e);
    return valueArray.reduce((sum, value) => sum + value, 0);
  }

  private getValues<T extends number>(e: any) {
    return this.getObjValues(e).filter((v) => typeof v === 'number') as T[];
  }

  private getObjValues(e: any): any[] {
    return Object.values(e).map((k: any) => e[k]);
  }

  public convertObjToQueryParams(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }

  public compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public sortArray(sortArray: any[], attribute: string, isAsc: boolean = false) {
    if (!Array.isArray(sortArray)) return sortArray;

    return sortArray.sort((a, b) => this.compare(a[attribute], b[attribute], isAsc))
  }

  public roundNumber(value: number): string {
    return parseFloat(`${value}`).toFixed(2);
  }

  public convertToCurrency(value: number): string {
    const currencyPipe = new CurrencyPipe('en');
    return currencyPipe.transform(value, null, 'symbol', '1.2-2');
  }

  public capitalizeFirstChar(target: string): string {
    return target.replace(/^./, (str) => str.toUpperCase()) || target;
  }

  public toTitleCase(value: string): string {
    return value
      .toLowerCase()
      .split(' ')
      .map((word) => {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(' ');
  }
}
