import { Injectable } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { ErrorMessages } from '@app/shared/constants';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ReportsService {
  constructor(
    private helperService: HelpersService,
    private http: HttpClient
  ) {}

  public getSummaryCard(params: any): any {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/reports/summary_card`, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public getSummaryCardData(params: any): any {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/reports/summary_card_data`, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }



  public getFilterQuery(range: any, filterForm: FormGroup): any {
    const query = {
      start_date: DateTime.fromJSDate(range.value.start).startOf('day').toUTC().toISO({ includeOffset: false }),
      end_date: DateTime.fromJSDate(range.value.end).endOf('day').toUTC().toISO({ includeOffset: false }),
      report_card_group: undefined,
    };

    if (!_.isEmpty(filterForm.value.provinces) && _.isArray(filterForm.value.provinces)) {
      query['province'] = filterForm.value.provinces;
    }

    if (!_.isEmpty(filterForm.value.couriers) && _.isArray(filterForm.value.couriers)) {
      query['provider'] = filterForm.value.couriers;
    }

    if (!_.isEmpty(filterForm.value.accountTypes) && _.isArray(filterForm.value.accountTypes)) {
      query['account_type'] = filterForm.value.accountTypes;
    }

    return query;
  }
}
