<ng-container *ngIf="!loading, else isLoading" class='mt--lg'>
  <div class='row row-start'>
    <div class='column--12 column-xxl--9'>
      <div class='row'>
        <div class='column--12'>
          <button mat-button color='primary' [routerLink]="['/users']">< Back to search</button>
        </div>
      </div>
      <div class='row'>
        <div class='column--12'>
          <h3 class='mb--xxs mt--none'>{{ company.name }} | {{ owner.firstName }} {{ owner.lastName }}</h3>
          <p class='mt--none'>{{ owner.email }} | {{ company.phone }}<br />
            {{ formattedAddress.firstLine }}, {{ formattedAddress.secondLine }}</p>
        </div>
        <div class='column--12'>
          <mat-card>
            <mat-card-content class='px--none mat-elevation-z0'>
              <div class='row'>
                <div class='column--6 column-sm--3 column-md--1 align-center'>
                  <div class='semi-bold text-larger summary-title'>{{ company.numberOfUsers }}</div>
                  <div class='semi-bold text--sm lineheight-larger'>Users</div>
                </div>
                <div class='column--6 column-sm--4 column-md--3 align-center column--border-left'>
                  <div class='semi-bold text-larger summary-title'>{{ billingType }}</div>
                  <div class='semi-bold text--sm lineheight-larger'>Payment</div>
                </div>
                <ng-container *ngIf='isCCUser, else isMAUser'>
                  <div class='column--6 column-sm--3 column-md--2 align-center column-sm--border-left'>
                    <div class='semi-bold text-larger summary-title'>{{ company.walletBalance | currency }}</div>
                    <div class='semi-bold text--sm lineheight-larger'>Wallet</div>
                  </div>
                </ng-container>
                <ng-template #isMAUser>
                  <div class='column--6 column-sm--3 column-md--2 align-center column-sm--border-left'>
                    <div class='semi-bold text-larger summary-title'>{{ company.billingAmount | currency }}</div>
                    <div class='semi-bold text--sm lineheight-larger'>Balance</div>
                  </div>
                </ng-template>
                <div class='column--6 column-sm--3 column-md--2 align-center column--border-left'>
                  <div class='semi-bold text-larger summary-title pricing-tier-text' [ngClass]='company.pricingTier'>{{company.tierName}} /{{company.pricingMargin}}</div>
                  <div class='semi-bold text--sm lineheight-larger'>Tier</div>
                </div>
                <div class='column--6 column-sm--4 column-md--1 align-center column--border-left'>
                  <div class='semi-bold text-larger summary-title'>{{ company.notes ? company.notes.length : 0 }}</div>
                  <div class='semi-bold text--sm lineheight-larger'>Notes</div>
                </div>
                <div class='column--6 column-sm--4 column-md--2 align-center column-md--border-left'>
                  <div class='semi-bold text-larger summary-title'>{{ company.salesRep.firstName }} {{ company.salesRep.lastName }}</div>
                  <div class='semi-bold text--sm lineheight-larger'>Sales</div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <mat-tab-group
        dynamicHeight
        animationDuration='0ms'
        class='tab-group-vertical tab-no-animation'
        [selectedIndex]="selectedTab"
        (selectedIndexChange)='selectedTab = $event'
      >
        <mat-tab *ngFor='let tab of tabs' [label]='tab.label'>
          <ng-template matTabContent>
            <app-payment-type
              *ngIf="tab.id === 'app-payment-type'"
              [company]='company'
              [user]='user'
              (refreshCompany)="getCompany()"
            ></app-payment-type>
            <app-billing-info
              *ngIf="tab.id === 'app-billing-info'"
              [company]='company'
              [user]='user'
              (refreshCompany)="getCompany()"
            ></app-billing-info>
            <app-company-charges
              *ngIf="tab.id === 'app-company-charges'"
              [company]='company'
              [user]='user'
              (refreshCompany)="getCompany()"
            ></app-company-charges>
            <app-wallet-transactions
              *ngIf="tab.id === 'app-wallet-transactions'"
              [company]='company'
              [user]='user'
              (refreshCompany)="getCompany()"
            ></app-wallet-transactions>
            <app-tier
              *ngIf="tab.id === 'app-tier'"
              [company]='company'
              [user]='user'
              (refreshCompany)="getCompany()"
            ></app-tier>
            <app-courier-modifiers
              *ngIf="tab.id === 'app-courier-modifiers'"
              [company]='company'
              [user]='user'
              (refreshCompany)="getCompany()"
            ></app-courier-modifiers>
            <app-dynamic-pricing
              *ngIf="tab.id === 'app-dynamic-pricing'"
              [company]='company'
              [user]='user'
              (refreshCompany)="getCompany()"
            ></app-dynamic-pricing>
            <app-couriers
              *ngIf="tab.id === 'app-couriers'"
              [company]='company'
              [user]='user'
              (refreshCompany)="getCompany()"
            ></app-couriers>
            <app-notes
              *ngIf="tab.id === 'app-notes'"
              [company]='company'
              [user]='user'
              (refreshCompany)="getCompany()"
            ></app-notes>
            <app-pickup
              *ngIf="tab.id === 'app-pickup'"
              [company]='company'
              [user]='user'
              (refreshCompany)="getCompany()"
            ></app-pickup>
            <app-sales
              *ngIf="tab.id === 'app-sales'"
              [company]='company'
              [user]='user'
              (refreshCompany)="getCompany()"
            ></app-sales>
            <app-company-settings
              *ngIf="tab.id === 'app-company-settings'"
              [company]='company'
              [user]='user'
              (refreshCompany)="getCompany()"
            ></app-company-settings>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
