import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoggerService } from '@app/shared/services/logger.service';
import { OtpService } from '@app/shared/services/otp.service';
import { StateService, TimeBucketInfoInterface } from '@app/shared/services/state.service';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { PanelWidths } from '@app/shared/constants';
import { RecalculateOtpComponent } from '@app/pages/authenticated/pages/otp/components/recalculate-otp/recalculate-otp.component';
import { AdminUser } from '@app/shared/services/user.service';

@Component({
  selector: 'app-time-bucket-filter',
  templateUrl: './time-bucket-filter.component.html',
})
export class TimeBucketFilterComponent implements OnInit, OnDestroy {
  @Input() adminUser: AdminUser;
  @Input() canModifyOtp: boolean;
  @Output() bucketChange = new EventEmitter<TimeBucketInfoInterface>();

  public loading = true;
  public filterForm: FormGroup;
  public timeBucket$ = this.stateService.timeBucketInfo$;
  public bucketTypes: {
    name: string;
    buckets: { id: string; displayName: string; dateRange: { startDate: string; endDate: string } }[];
  }[] = [];
  public currentInterval: string;
  public currentBucket: string;
  public currentHistory: number = 1;
  public timeIntervals = [];
  public timeBuckets = [];
  public historyOptions = [];

  constructor(
    private formBuilder: FormBuilder,
    private logger: LoggerService,
    private otpService: OtpService,
    private stateService: StateService,
    private drawer: MtxDrawer
  ) {}
  ngOnInit() {
    this.loading = true;
    this.timeBucket$.subscribe((timeBucketInfo: TimeBucketInfoInterface | null): void => {
      this.currentInterval = timeBucketInfo.interval;
      this.currentBucket = timeBucketInfo.timeBucket;
      this.currentHistory = timeBucketInfo.history || 1;
    });
    this.otpService.getTimeBuckets().subscribe(
      (buckets: any) => {
        this.logger.log('GET Otp buckets', buckets);
        Object.keys(buckets).forEach((bucket) => {
          if (Array.isArray(buckets[bucket])) {
            this.timeIntervals.push(bucket);
            const bucketInfo = {
              name: bucket,
              buckets: buckets[bucket],
            };
            this.bucketTypes.push(bucketInfo);
          }
        });
        this.timeBuckets = buckets[this.currentInterval || 'weekly'];
        this.currentInterval = this.currentInterval || 'weekly';
        this.currentBucket = this.currentBucket || this.timeBuckets[0].id;
        this.createForm();
        this.loading = false;
      },
      (error) => {
        this.logger.error('GET Otp buckets error', error);
      }
    );
  }
  ngOnDestroy() {
    if (this.drawer) {
      this.drawer.dismiss();
    }
  }

  public createForm() {
    this.filterForm = this.formBuilder.group({
      interval: [this.currentInterval],
      period: [this.currentBucket],
      history: [this.currentHistory],
    });

    this.updateHistoryOptions();

    this.filterForm.valueChanges.subscribe(() => this.updateFilters());

    this.stateService.setTimeBucketData(this.currentBucket, this.currentInterval, this.currentHistory);
    this.bucketChange.emit({
      timeBucket: this.currentBucket,
      interval: this.currentInterval,
      history: this.currentHistory,
    });
  }

  private updateHistoryOptions() {
    this.historyOptions = Array.from({ length: this.timeBuckets.length }, (_, i) => i + 1);
    this.currentHistory = Math.min(4, this.historyOptions.length);
  }

  public openRecalculatePanel() {
    const drawerRef = this.drawer.open(RecalculateOtpComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { adminUser: this.adminUser },
    });
  }

  private updateFilters() {
    const filters = this.filterForm.value;
    if (filters.interval !== this.currentInterval) {
      const newBucket = this.bucketTypes.find((bucket) => bucket.name === filters.interval);
      if (newBucket) {
        this.timeBuckets = newBucket.buckets;
        this.currentInterval = filters.interval;
        this.currentBucket = this.timeBuckets[0].id;

        this.updateHistoryOptions();

        this.filterForm.patchValue({
          period: this.currentBucket,
          history: this.currentHistory,
        });

        this.stateService.setTimeBucketData(this.currentBucket, this.currentInterval, this.currentHistory);
        this.bucketChange.emit({
          timeBucket: this.currentBucket,
          interval: this.currentInterval,
          history: this.currentHistory,
        });
      }
    } else if (filters.period !== this.currentBucket || filters.history !== this.currentHistory) {
      this.currentBucket = filters.period;
      this.currentHistory = filters.history;

      this.stateService.setTimeBucketData(this.currentBucket, this.currentInterval, this.currentHistory);
      this.bucketChange.emit({
        timeBucket: this.currentBucket,
        interval: this.currentInterval,
        history: this.currentHistory,
      });
    }
  }
}
