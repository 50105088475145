import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { AdminUser } from '@app/shared/services/user.service';

export interface TimeBucketInfoInterface {
  timeBucket: string;
  interval: string;
  history: number;
}

@Injectable({ providedIn: 'root' })
export class StateService {
  private adminUserSubject = new BehaviorSubject<any>(null);
  adminUserInfo$ = this.adminUserSubject.asObservable();

  private timeBucketInfoSubject = new BehaviorSubject<any>(null);
  timeBucketInfo$ = this.timeBucketInfoSubject.asObservable();
  private courierFilterSubject = new BehaviorSubject<any>(null);
  courierFilter$ = this.courierFilterSubject.asObservable();

  constructor(private localStorageService: LocalStorageService) {
    const savedTimeBucketData = this.localStorageService.retrieve('timeBucketInfo');
    if (savedTimeBucketData) {
      this.timeBucketInfoSubject.next(savedTimeBucketData);
    }
    const savedCourierFilterData = this.localStorageService.retrieve('courierFilter');
    if (savedCourierFilterData) {
      this.courierFilterSubject.next(savedCourierFilterData);
    }
  }

  setAdminUserData(adminUser: AdminUser) {
    this.adminUserSubject.next(adminUser);
  }

  clearAdminUserData() {
    this.adminUserSubject.next(null);
  }

  setTimeBucketData(timeBucket: string, interval: string, history: number) {
    // Save form data to localStorage
    this.localStorageService.store('timeBucketInfo', { timeBucket, interval, history });
    this.timeBucketInfoSubject.next({ timeBucket, interval, history });
  }

  clearTimeBucketData() {
    // Remove form data from localStorage
    this.localStorageService.clear('timeBucketInfo');
    this.timeBucketInfoSubject.next(null);
  }

  setCourierFilterData(courierFilter: string[]) {
    // Save form data to localStorage
    this.localStorageService.store('courierFilter', courierFilter);
    this.courierFilterSubject.next(courierFilter);
  }

  clearCourierFilterData() {
    // Remove form data from localStorage
    this.localStorageService.clear('courierFilter');
    this.courierFilterSubject.next(null);
  }
}
