import {
  Component,
  ElementRef, EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import { environment } from '../../../../../../../environments/environment';

export interface LocationData {
  longitude: number;
  latitude: number;
  volume: number;
  name?: string;
  province?: string;
  // For sub-locations
  subLocations?: LocationData[];
  // Additional metadata for tooltip display
  metadata?: Record<string, any>;
}

export interface ProvinceSelectionEvent {
  province: string;
  bounds: [[number, number], [number, number]]; // Geographic bounds [[west, south], [east, north]]
}

@Component({
  selector: 'app-canada-heatmap',
  templateUrl: './canada-heat-map.component.html',
  styleUrls: ['./canada-heat-map.component.scss']
})
export class CanadaHeatmapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: LocationData[] = [];
  @Input() minRadius = 5;
  @Input() maxRadius = 30;
  @Input() colorScale: string[] = ['#f7fbff', '#deebf7', '#c6dbef', '#9ecae1', '#6baed6', '#4292c6', '#2171b5', '#084594'];
  @Input() maxZoom = 20;
  @Input() detailZoomThreshold = 5; // Zoom level at which to show detailed data
  @Input() pointBreakdownFactor = 0.2; // Controls how far apart broken down points are scattered
  @Input() tooltipFields: {label: string, field: string}[] = []; // Fields to display in tooltip

  // Events
  @Output() provinceSelected = new EventEmitter<ProvinceSelectionEvent>();
  @Output() zoomLevelChanged = new EventEmitter<number>();
  @Output() requestDetailData = new EventEmitter<{province: string, bounds: [[number, number], [number, number]], zoomLevel: number}>();
  @Output() pointClicked = new EventEmitter<LocationData>();

  private svg: any;
  private g: any;
  private projection: any;
  private geoJson: any;
  private width = 0;
  private height = 0;
  private zoom: any;
  private currentTransform = d3.zoomIdentity;
  private radiusScale: any;
  private resizeTimer: any;
  private mapContainer: any;
  private provincesGroup: any;
  private markersGroup: any;
  private detailedMarkersGroup: any;
  private tooltip: any;

  // State tracking
  private currentProvince: string | null = null;
  private currentZoomLevel = 1;
  private isShowingDetailedData = false;
  private detailedData: LocationData[] = [];
  private previousZoomLevel = 1;
  private generatedPoints: Map<string, LocationData[]> = new Map(); // Cache for generated breakdown points

  // Province color scales
  private defaultProvinceColor = '#f2f2f2';
  private selectedProvinceColor = '#e6e6e6';
  private provinceStrokeDefault = '#ccc';
  private provinceStrokeSelected = '#666';

  private CDN_URL: string = environment.CDN_URL;
  private geoJsonAsset: string = this.CDN_URL + '/admin/data/canada_provinces.geojson';

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.mapContainer = d3.select('#map-container');
    this.initializeSvg();
    this.initializeTooltip();
    this.loadCanadaMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && !changes['data'].firstChange && this.svg) {
      this.updateHeatmap();
    }
  }

  ngOnDestroy(): void {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }
    // Remove tooltip when component is destroyed
    if (this.tooltip) {
      this.tooltip.remove();
    }
  }

  private initializeTooltip(): void {
    this.tooltip = d3.select('body').append('div')
      .attr('class', 'heatmap-tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background-color', 'white')
      .style('border', '1px solid #ddd')
      .style('border-radius', '4px')
      .style('padding', '10px')
      .style('box-shadow', '0 2px 4px rgba(0,0,0,0.1)')
      .style('z-index', '10000')
      .style('pointer-events', 'none');
  }

  private initializeSvg(): void {
    // Clear any existing SVG first
    this.mapContainer.selectAll('svg').remove();

    this.width = this.elementRef.nativeElement.offsetWidth;
    this.height = this.elementRef.nativeElement.offsetHeight || this.width * 0.6;

    // Setup zoom behavior
    this.zoom = d3.zoom()
      .scaleExtent([1, this.maxZoom])
      .on('zoom', (event) => {
        this.previousZoomLevel = this.currentZoomLevel;
        this.currentTransform = event.transform;
        this.currentZoomLevel = event.transform.k;
        this.g.attr('transform', event.transform);

        // Scale elements inversely to maintain visual size during zoom
        this.adjustElementSizesForZoom(event.transform.k);

        // Check if we should show/hide detailed data
        this.handleZoomLevelChange(event.transform.k);

        // Emit zoom level for parent component
        this.zoomLevelChanged.emit(event.transform.k);
      });

    // Create SVG and main group
    this.svg = this.mapContainer
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${this.width} ${this.height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .call(this.zoom)
      .on('dblclick.zoom', null); // Disable double-click zoom for better UX

    // Create a group to hold all elements (this is what gets transformed)
    this.g = this.svg.append('g');

    // Create separate groups for provinces and markers for better z-index control
    this.provincesGroup = this.g.append('g').attr('class', 'provinces-group');
    this.markersGroup = this.g.append('g').attr('class', 'markers-group');
    this.detailedMarkersGroup = this.g.append('g').attr('class', 'detailed-markers-group');
  }

  private loadCanadaMap(): void {
    // Focus on Canadian provinces
    this.projection = d3.geoMercator()
      .center([-95, 50])
      .scale(this.width * 0.7)
      .translate([this.width / 2, this.height / 2]);

    const path = d3.geoPath().projection(this.projection);

    // Load Canada GeoJSON
    d3.json(this.geoJsonAsset).then((geoJson: any) => {
      this.geoJson = geoJson;

      // Clear existing paths first
      this.provincesGroup.selectAll('.province').remove();

      // Draw provinces
      this.provincesGroup
        .selectAll('path')
        .data(geoJson.features)
        .enter()
        .append('path')
        .attr('d', path)
        .attr('class', 'province')
        .attr('fill', this.defaultProvinceColor)
        .attr('stroke', this.provinceStrokeDefault)
        .attr('stroke-width', 0.5 / this.currentTransform.k)
        .attr('data-province', (d: any) => d.properties.name)
        .on('click', (event: any, d: any) => this.onProvinceClick(event, d))
        .on('mouseover', function() {
          d3.select(this).attr('cursor', 'pointer');
        })
        .on('mouseout', function() {
          d3.select(this).attr('cursor', 'default');
        });

      this.updateHeatmap();
    });
  }

  private onProvinceClick(event: any, province: any): void {
    const provinceName = province.properties.name;

    // Get the geographic bounds of the province
    const bounds = d3.geoBounds(province);

    // Calculate the center point of the province
    const provincePath = d3.geoPath().projection(this.projection);
    const centroid = this.projection(d3.geoCentroid(province));

    // Calculate an appropriate zoom level based on province size
    const [[x0, y0], [x1, y1]] = provincePath.bounds(province);
    const provinceWidth = x1 - x0;
    const provinceHeight = y1 - y0;
    const scale = Math.min(
      8, // Max zoom for a province
      0.9 * Math.min(
        this.width / provinceWidth,
        this.height / provinceHeight
      )
    );

    // Reset previously selected province style
    if (this.currentProvince) {
      this.provincesGroup.selectAll('.province')
        .filter((d: any) => d.properties.name === this.currentProvince)
        .attr('fill', this.defaultProvinceColor)
        .attr('stroke', this.provinceStrokeDefault);
    }

    // Set new selected province
    this.currentProvince = provinceName;

    // Highlight selected province
    this.provincesGroup.selectAll('.province')
      .filter((d: any) => d.properties.name === provinceName)
      .attr('fill', this.selectedProvinceColor)
      .attr('stroke', this.provinceStrokeSelected);

    // Zoom to the province
    this.svg.transition().duration(750).call(
      this.zoom.transform,
      d3.zoomIdentity
        .translate(this.width / 2, this.height / 2)
        .scale(scale)
        .translate(-centroid[0], -centroid[1])
    );

    // Emit the province selection event
    this.provinceSelected.emit({
      province: provinceName,
      bounds: bounds
    });
  }

  private updateHeatmap(): void {
    if (!this.data || !this.projection || !this.markersGroup) return;

    // Remove existing circles
    this.markersGroup.selectAll('.location-marker').remove();

    // Calculate radius scale based on data volumes
    const volumeExtent = d3.extent(this.data, d => d.volume) as [number, number];
    this.radiusScale = d3.scaleLinear()
      .domain(volumeExtent)
      .range([this.minRadius, this.maxRadius]);

    // Calculate color scale
    const colorScaleD3 = d3.scaleQuantile<string>()
      .domain(this.data.map(d => d.volume))
      .range(this.colorScale);

    // Add circles for each location
    const circles = this.markersGroup.selectAll('.location-marker')
      .data(this.data)
      .enter()
      .append('circle')
      .attr('class', 'location-marker')
      .attr('cx', (d: LocationData) => {
        const point = this.projection([d.longitude, d.latitude]);
        return point ? point[0] : 0;
      })
      .attr('cy', (d: LocationData) => {
        const point = this.projection([d.longitude, d.latitude]);
        return point ? point[1] : 0;
      })
      .attr('r', (d: LocationData) => this.radiusScale(d.volume) / this.currentTransform.k)
      .attr('fill', (d: LocationData) => colorScaleD3(d.volume))
      .attr('fill-opacity', 0.7)
      .attr('stroke', '#fff')
      .attr('stroke-width', 0.5 / this.currentTransform.k)
      .attr('data-province', (d: LocationData) => d.province || '')
      .attr('data-id', (d: LocationData, i: number) => `marker-${i}`)
      .on('click', (event: any, d: LocationData) => this.onLocationClick(event, d))
      .on('mouseover', (event: any, d: LocationData) => this.showTooltip(event, d))
      .on('mouseout', () => this.hideTooltip());
  }

  private showTooltip(event: any, data: LocationData): void {
    // Position the tooltip near the mouse but not directly under it
    const x = event.pageX + 15;
    const y = event.pageY - 28;

    let content = `<div><strong>${data.name || 'Location'}</strong></div>`;
    content += `<div>Volume: ${data.volume.toLocaleString()}</div>`;

    if (data.province) {
      content += `<div>Province: ${data.province}</div>`;
    }

    // Add custom fields from metadata if available and tooltipFields is specified
    if (data.metadata && this.tooltipFields.length > 0) {
      this.tooltipFields.forEach(field => {
        if (data.metadata && data.metadata[field.field] !== undefined) {
          content += `<div>${field.label}: ${data.metadata[field.field]}</div>`;
        }
      });
    }

    this.tooltip
      .html(content)
      .style('left', `${x}px`)
      .style('top', `${y}px`)
      .style('visibility', 'visible');
  }

  private hideTooltip(): void {
    this.tooltip.style('visibility', 'hidden');
  }

  private updateDetailedData(): void {
    if (!this.detailedData.length || !this.projection || !this.detailedMarkersGroup) return;

    // Remove existing detailed markers
    this.detailedMarkersGroup.selectAll('.detailed-marker').remove();

    // Calculate radius scale for detailed data
    const volumeExtent = d3.extent(this.detailedData, d => d.volume) as [number, number];
    const detailedRadiusScale = d3.scaleLinear()
      .domain(volumeExtent)
      .range([this.minRadius * 0.6, this.maxRadius * 0.6]); // Smaller markers for detailed data

    // Color scale for detailed data
    const detailedColorScale = d3.scaleQuantile<string>()
      .domain(this.detailedData.map(d => d.volume))
      .range(this.colorScale);

    // Add circles for detailed locations
    const detailedCircles = this.detailedMarkersGroup.selectAll('.detailed-marker')
      .data(this.detailedData)
      .enter()
      .append('circle')
      .attr('class', 'detailed-marker')
      .attr('cx', (d: LocationData) => {
        const point = this.projection([d.longitude, d.latitude]);
        return point ? point[0] : 0;
      })
      .attr('cy', (d: LocationData) => {
        const point = this.projection([d.longitude, d.latitude]);
        return point ? point[1] : 0;
      })
      .attr('r', (d: LocationData) => detailedRadiusScale(d.volume) / this.currentTransform.k)
      .attr('fill', (d: LocationData) => detailedColorScale(d.volume))
      .attr('fill-opacity', 0.8)
      .attr('stroke', '#fff')
      .attr('stroke-width', 0.3 / this.currentTransform.k)
      .on('mouseover', (event: any, d: LocationData) => this.showTooltip(event, d))
      .on('mouseout', () => this.hideTooltip())
      .on('click', (event: any, d: LocationData) => this.pointClicked.emit(d));
  }

  private onLocationClick(event: any, location: LocationData): void {
    // Emit the clicked point for parent component handling
    this.pointClicked.emit(location);

    // Zoom to the clicked location
    this.zoomToLocation(location.longitude, location.latitude, 8);

    // If the location has sub-locations, load them
    if (location.subLocations && location.subLocations.length) {
      this.showDetailedData(location.subLocations);
    } else {
      // If we don't have predefined sub-locations, generate them
      this.generateAndShowDetailedPoints(location);
    }
  }

  private generateAndShowDetailedPoints(location: LocationData): void {
    const locationId = `${location.longitude}-${location.latitude}`;

    // Check if we've already generated points for this location
    if (this.generatedPoints.has(locationId)) {
      this.showDetailedData(this.generatedPoints.get(locationId)!);
      return;
    }

    // Generate a number of points around the original location
    // The number is proportional to the volume
    const pointCount = Math.max(5, Math.min(20, Math.ceil(location.volume / 100)));
    const generatedPoints: LocationData[] = [];

    // Create a random distribution of points around the center
    for (let i = 0; i < pointCount; i++) {
      // Calculate how to distribute the volume
      const volumeShare = location.volume / pointCount;

      // Create slight variations in coordinates
      // Use the pointBreakdownFactor to control how far apart the points are
      const jitterFactor = this.pointBreakdownFactor / Math.max(1, this.currentZoomLevel / 2);
      const lonOffset = (Math.random() - 0.5) * jitterFactor;
      const latOffset = (Math.random() - 0.5) * jitterFactor;

      generatedPoints.push({
        longitude: location.longitude + lonOffset,
        latitude: location.latitude + latOffset,
        volume: volumeShare,
        name: location.name ? `${location.name} (${i+1})` : `Location ${i+1}`,
        province: location.province,
        metadata: location.metadata
      });
    }

    // Cache the generated points
    this.generatedPoints.set(locationId, generatedPoints);

    // Show the generated points
    this.showDetailedData(generatedPoints);
  }

  // Set detailed data from parent component
  setDetailedData(data: LocationData[]): void {
    this.detailedData = data;
    this.updateDetailedData();
  }

  private showDetailedData(data: LocationData[]): void {
    this.detailedData = data;
    this.isShowingDetailedData = true;
    this.updateDetailedData();

    // Optional: Make main markers slightly transparent when showing detailed view
    this.markersGroup.style('opacity', 0.3);
  }

  private hideDetailedData(): void {
    this.isShowingDetailedData = false;
    this.detailedMarkersGroup.selectAll('.detailed-marker').remove();

    // Show main markers again
    this.markersGroup.style('opacity', 1);
  }

  private handleZoomLevelChange(zoomLevel: number): void {
    // Determine if we should show or hide detailed data based on zoom level
    if (zoomLevel >= this.detailZoomThreshold) {
      if (!this.isShowingDetailedData) {
        // If we have a selected point, break it down
        if (this.currentProvince) {
          const bounds = this.calculateCurrentViewBounds();
          // Request detailed data from parent component
          this.requestDetailData.emit({
            province: this.currentProvince,
            bounds: bounds,
            zoomLevel: zoomLevel
          });

          // Find visible markers in the current view
          const visibleMarkers = this.findVisibleMarkers();
          if (visibleMarkers.length === 1) {
            // If only one marker is visible, break it down
            this.generateAndShowDetailedPoints(visibleMarkers[0]);
          }
        }
      }
    } else {
      // If zooming out below threshold
      if (this.isShowingDetailedData && this.previousZoomLevel >= this.detailZoomThreshold) {
        this.hideDetailedData();
      }
    }
  }

  private findVisibleMarkers(): LocationData[] {
    const bounds = this.calculateCurrentViewBounds();
    const [[west, south], [east, north]] = bounds;

    // Filter markers that are within the visible bounds
    return this.data.filter(d => {
      return d.longitude >= west && d.longitude <= east &&
        d.latitude >= south && d.latitude <= north;
    });
  }

  private calculateCurrentViewBounds(): [[number, number], [number, number]] {
    // Calculate the current view bounds in geographic coordinates
    const topLeft = this.projection.invert([0, 0].map(this.currentTransform.invert.bind(this.currentTransform)));
    const bottomRight = this.projection.invert([this.width, this.height].map(this.currentTransform.invert.bind(this.currentTransform)));

    return [topLeft, bottomRight];
  }

  private adjustElementSizesForZoom(scale: number): void {
    // Adjust province stroke width
    this.provincesGroup.selectAll('.province')
      .attr('stroke-width', 0.5 / scale);

    // Adjust main marker sizes
    this.markersGroup.selectAll('.location-marker')
      .attr('r', (d: LocationData) => this.radiusScale(d.volume) / scale)
      .attr('stroke-width', 0.5 / scale);

    // Adjust detailed marker sizes if they exist
    this.detailedMarkersGroup.selectAll('.detailed-marker')
      .attr('r', (d: LocationData) => {
        const detailedRadiusScale = d3.scaleLinear()
          .domain(d3.extent(this.detailedData, d => d.volume) as [number, number])
          .range([this.minRadius * 0.6, this.maxRadius * 0.6]);
        return detailedRadiusScale(d.volume) / scale;
      })
      .attr('stroke-width', 0.3 / scale);
  }

  // Method to programmatically zoom to a specific location
  zoomToLocation(longitude: number, latitude: number, zoomLevel: number = 4): void {
    const point = this.projection([longitude, latitude]);
    if (!point) return;

    const x = point[0];
    const y = point[1];

    this.svg.transition().duration(750).call(
      this.zoom.transform,
      d3.zoomIdentity
        .translate(this.width / 2, this.height / 2)
        .scale(zoomLevel)
        .translate(-x, -y)
    );
  }

  @HostListener('window:resize')
  onResize(): void {
    // Debounce resize event
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }

    this.resizeTimer = setTimeout(() => {
      // Save current transform and state
      const savedTransform = this.currentTransform;
      const savedProvince = this.currentProvince;
      const savedDetailedData = this.detailedData;
      const wasShowingDetailedData = this.isShowingDetailedData;

      // Update dimensions
      this.width = this.elementRef.nativeElement.offsetWidth;
      this.height = this.elementRef.nativeElement.offsetHeight || this.width * 0.6;

      // Completely reinitialize the SVG
      this.initializeSvg();

      // Restore the map
      this.loadCanadaMap();

      // Restore transform
      if (savedTransform) {
        this.svg.call(this.zoom.transform, savedTransform);
      }

      // Restore selected province
      if (savedProvince) {
        this.currentProvince = savedProvince;
        this.provincesGroup.selectAll('.province')
          .filter((d: any) => d.properties.name === savedProvince)
          .attr('fill', this.selectedProvinceColor)
          .attr('stroke', this.provinceStrokeSelected);
      }

      // Restore detailed data if needed
      if (wasShowingDetailedData && savedDetailedData.length) {
        this.detailedData = savedDetailedData;
        this.isShowingDetailedData = true;
        this.updateDetailedData();
      }
    }, 250);
  }
}
