import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { ProvidersService } from '@app/shared/services/providers.service';
import { DateTime } from 'luxon';
import { ShippingProviders } from '@app/shared/constants';
import { PageEvent } from '@angular/material/paginator';
import { Observable, Subscription } from 'rxjs';
import { StateService, TimeBucketInfoInterface } from '@app/shared/services/state.service';

@Component({
  selector: 'app-otp-services',
  templateUrl: './otp-services.component.html',
  styleUrls: ['./otp-services.component.scss'],
})
export class OtpServicesComponent implements OnInit, OnDestroy {

  public timeBucket$ = this.stateService.timeBucketInfo$;
  private timeBucketSubscription: Subscription;
  public courierFilter$ = this.stateService.courierFilter$;
  private courierFilterSubscription: Subscription;
  public adminUserInfo$: Observable<any> = this.stateService.adminUserInfo$;
  private adminUserInfoSubscription: Subscription;
  public canViewOtp: boolean = false;

  public loading: boolean = true;
  public form: FormGroup | undefined;
  public currentPage: number = 0;
  public itemsPerPage: number = 5;
  public itemsPerPageOptions: number[] = [5, 10, 25];

  public daterange = {
    start: DateTime.now().minus({ day: 7 }).toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  };
  public range = new FormGroup({
    start: new FormControl<Date | null>(this.daterange.start),
    end: new FormControl<Date | null>(this.daterange.end),
  });
  public readonly couriersDropdown = [];

  // Donut chart data
  public donutChartData = [
    { name: 'Guaranteed', value: 43 },
    { name: 'Non-Guaranteed', value: 57 },
  ];

  // Bar chart data
  public barChartData = [
    { name: 'Express Worldwide', value: 94 },
    { name: 'Next Day Air', value: 90 },
    { name: 'Priority Overnight', value: 87 },
    { name: 'Standard Express', value: 76 },
    { name: 'Ground', value: 65 },
  ];

  // Line chart data
  public lineChartData = [
    {
      name: 'Express Worldwide',
      series: [
        { name: 'Jan', value: 94 },
        { name: 'Feb', value: 90 },
        { name: 'Mar', value: 87 },
        { name: 'Apr', value: 76 },
        { name: 'May', value: 65 },
        { name: 'Jun', value: 60 },
      ],
    },
    {
      name: 'Next Day Air',
      series: [
        { name: 'Jan', value: 90 },
        { name: 'Feb', value: 85 },
        { name: 'Mar', value: 80 },
        { name: 'Apr', value: 75 },
        { name: 'May', value: 70 },
        { name: 'Jun', value: 65 },
      ],
    },
    {
      name: 'Priority Overnight',
      series: [
        { name: 'Jan', value: 87 },
        { name: 'Feb', value: 82 },
        { name: 'Mar', value: 77 },
        { name: 'Apr', value: 72 },
        { name: 'May', value: 67 },
        { name: 'Jun', value: 62 },
      ],
    },
  ];

  // Table data
  public tableData = [
    {
      serviceType: 'Express Worldwide',
      courier: 'DHL',
      volume: 1230,
      onTimePercent: 94,
      avgDelay: '0.8h',
      cost: '$$$',
    },
    {
      serviceType: 'Next Day Air',
      courier: 'UPS',
      volume: 980,
      onTimePercent: 90,
      avgDelay: '1.2h',
      cost: '$$$',
    },
    {
      serviceType: 'Priority Overnight',
      courier: 'FedEx',
      volume: 865,
      onTimePercent: 87,
      avgDelay: '1.5h',
      cost: '$$$',
    },
    {
      serviceType: 'Xpresspost',
      courier: 'Canada Post',
      volume: 640,
      onTimePercent: 75,
      avgDelay: '5.4h',
      cost: '$$',
    },
    {
      serviceType: 'Ground',
      courier: 'UPS',
      volume: 890,
      onTimePercent: 65,
      avgDelay: '12.7h',
      cost: '$',
    },
  ];

  // Columns to display in the table
  public displayedColumns: string[] = [
    'serviceType',
    'courier',
    'volume',
    'onTimePercent',
    'avgDelay',
    'cost',
  ];


  constructor(
    private formBuilder: FormBuilder,
    private helpersService: HelpersService,
    private stateService: StateService,
    private logger: LoggerService,
    private userService: UserService,
    private providersService: ProvidersService,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.adminUserInfoSubscription = this.adminUserInfo$.subscribe((adminUserInfo: AdminUser) => {
      this.canViewOtp = this.userService.hasAuthScope(adminUserInfo, [AdminScopes.VIEW_OTP]);
      if (this.canViewOtp) {
        // TODO - Get content
        this.createForm();
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.adminUserInfoSubscription?.unsubscribe();
    this.timeBucketSubscription?.unsubscribe();
    this.courierFilterSubscription?.unsubscribe();
  }

  private createForm(){
    this.form = this.formBuilder.group({
      couriers: ['', []],
      period: ['', []],
    });

    const allProviders: any[] = this.providersService.getProvidersList();
    allProviders.forEach((provider) => {
      if ([ShippingProviders.RIVO].includes(provider.enum)) {
        this.couriersDropdown.push({
          id: provider.enum,
          text: this.helpersService.convertShipmentProviderToString(provider.enum, undefined, true),
        });
      } else if (![ShippingProviders.INTELCOM].includes(provider.enum)) {
        this.couriersDropdown.push({
          id: provider.enum,
          text: provider.name,
        });
      }
    });
    this.providersService.getFreightProvidersList().forEach((provider) => {
      this.couriersDropdown.push({ id: provider.enum, text: provider.name });
    });
    // Sort the couriers alphabetically
    this.couriersDropdown.sort((a, b) => (a.text < b.text ? -1 : 1));
  }

  public pageChanged(pageEvent: PageEvent): void {
    this.itemsPerPage = pageEvent.pageSize;
    this.currentPage = pageEvent.pageIndex;
    const end = this.itemsPerPage * (this.currentPage + 1);
    const start = end - this.itemsPerPage;
  }
}
