<div class='row mt--md'>
  <div class='column--12'>
    <h3>Couriers by revenue</h3>
  </div>
</div>
<div class='row'>
  <div class='column--12 pr--md pb--sm'>
    <mat-card>
      <mat-card-content>
        <ng-container *ngIf="!loadingTopCouriers, else loadingTop">
          <div class='responsive-table' *ngIf='!topCouriersAccountsEmpty, else emptyRevenueAccounts'>
            <table
              mat-table
              matSort
              matSortActive="totalMARevenue"
              matSortDirection="desc"
              (matSortChange)="sortData($event)"
              [dataSource]="sortedTopCouriersAccounts">
              <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef style='width: 50px' mat-sort-header></th>
                <td mat-cell *matCellDef="let element; let i = index;">
                  {{ i+1 }}
                </td>
              </ng-container>
              <ng-container matColumnDef="providerName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Courier</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.providerName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="totalNumShipments">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Total # of Shipments</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.totalNumShipmentsFormatted }}
                </td>
              </ng-container>
              <ng-container matColumnDef="totalNumMAShipments">
                <th mat-header-cell *matHeaderCellDef mat-sort-header># of MA Shipments</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.totalNumMAShipmentsFormatted }}
                </td>
              </ng-container>
              <ng-container matColumnDef="totalMARevenue">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header>MA Revenue</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.totalMARevenue | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="totalNumBYOAShipments">
                <th mat-header-cell *matHeaderCellDef mat-sort-header># of BYOA Shipments</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.totalNumBYOAShipmentsFormatted }}
                </td>
              </ng-container>
              <ng-container matColumnDef="totalBYOARevenue">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>BYOA</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.totalBYOARevenue | currency }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="topCouriersColumns"></tr>
              <tr mat-row *matRowDef="let element; columns: topCouriersColumns; let i = index" class="top-company-row">
              </tr>
            </table>
          </div>
          <ng-template #emptyRevenueAccounts>
            <div class='h1 mt--sm mb--none align-center'>
              <i class='icon-warn text--warn'></i>
              <div class='text-center'>Ship! Nothing matches the search criteria.</div>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #loadingTop>
          <div class='align-center mt--sm'>
            <app-loading></app-loading>
          </div>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
</div>
