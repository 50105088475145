import { ShippingProviders } from '@app/shared/constants';

// Basic types that are used across different responses
export enum PerformanceGrade {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  F = 'F',
}

export enum TrendDirection {
  IMPROVING = 'improving',
  DECLINING = 'declining',
  STABLE = 'stable',
}

export enum TimePeriod {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
}

export enum AggregationType {
  COURIER = 'courier',
  REGION = 'region',
  SERVICE_TYPE = 'service_type',
}

export interface TrendData {
  onTimePercentage: TrendDirection;
  averageDelay: TrendDirection;
}

export interface TimeBucketDateRange {
  startDate: string;
  endDate: string;
}

export interface TimeBucketItem {
  id: string;
  displayName: string;
  dateRange: TimeBucketDateRange;
}

export interface TimeBucketResponseMetadata {
  cached: boolean;
  timestamp: string;
}

// Time buckets response
export interface TimeBucketResponse {
  weekly: TimeBucketItem[];
  monthly: TimeBucketItem[];
  quarterly: TimeBucketItem[];
  metadata?: TimeBucketResponseMetadata;
}

// Basic metrics for a time bucket
export interface BucketMetrics {
  timeBucket: string;
  totalShipments: number;
  onTimePercentage: number;
  averageDelay: number;
  totalShipmentsTrend?: number; // Percentage change from previous period
  onTimePercentageTrend?: number; // Percentage point change
  averageDelayTrend?: number; // Absolute change in hours
}

// Courier Performance Interfaces
export interface GradeDistribution {
  [PerformanceGrade.A]: number;
  [PerformanceGrade.B]: number;
  [PerformanceGrade.C]: number;
  [PerformanceGrade.D]: number;
  [PerformanceGrade.F]: number;
}

export interface GuaranteedServiceMetrics {
  total: number;
  onTime: number;
  averageDelay: number;
}

export interface ServiceMetricsSummary {
  totalShipments: number;
  onTimeShipments: number;
  onTimePercentage: number;
  averageDelay: number;
  serviceName: string;
  isGuaranteed: boolean;
}

export interface CourierBucketMetrics extends BucketMetrics {
  onTimeShipments: number;
  gradeDistribution: GradeDistribution;
  guaranteedService: GuaranteedServiceMetrics;
  services: {
    [serviceType: string]: ServiceMetricsSummary;
  };
}

export interface CourierMetricsResponse {
  courier: ShippingProviders;
  data: CourierBucketMetrics[];
  trend: TrendData;
}

export interface CourierPerformanceResponse {
  timeBuckets: string[];
  metrics: CourierMetricsResponse[];
  totals: {
    data: BucketMetrics[];
    trend: TrendData;
  };
  gradeDistribution: {
    data: (BucketMetrics & GradeDistribution)[];
  };
}

// Service Type Performance Interfaces
export interface ServiceTypeBucketMetrics extends BucketMetrics {
  onTimeShipments: number;
  isGuaranteed: boolean | 'N/A';
}

export interface ServiceTypeMetricsResponse {
  courier: ShippingProviders;
  serviceType: string;
  serviceName: string;
  data: ServiceTypeBucketMetrics[];
  trend: TrendData;
}

export interface ServiceTypeBreakdown {
  [serviceType: string]: {
    serviceName: string;
    data: BucketMetrics[];
    trend: TrendData;
  };
}

export interface ServiceTypePerformanceResponse {
  timeBuckets: string[];
  metrics: ServiceTypeMetricsResponse[];
  totals: {
    data: BucketMetrics[];
    trend: TrendData;
  };
  serviceTypeBreakdown: ServiceTypeBreakdown;
}

// Region Performance Interfaces
export interface RegionLocation {
  city: string;
  province: string;
}

export interface RegionBucketMetrics extends BucketMetrics {
  onTimeShipments: number;
  bestCourier: ShippingProviders | null;
  worstCourier: ShippingProviders | null;
}

export interface RegionMetricsResponse {
  origin: RegionLocation;
  destination: RegionLocation;
  data: RegionBucketMetrics[];
  trend: TrendData;
}

export interface TopPerformingRoute {
  route: {
    origin: RegionLocation;
    destination: RegionLocation;
  };
  data: BucketMetrics[];
}

export interface TopPerformingCourier {
  courier: ShippingProviders;
  data: {
    timeBucket: string;
    performanceScore: number;
    totalShipments: number;
  }[];
}

export interface RegionPerformanceResponse {
  timeBuckets: string[];
  metrics: RegionMetricsResponse[];
  totals: {
    data: BucketMetrics[];
    trend: TrendData;
  };
  topPerformingRoutes: TopPerformingRoute[];
  topPerformingCouriers: TopPerformingCourier[];
}

// Dashboard Interfaces
export interface CourierPerformanceSummary {
  gradeDistribution: {
    data: (GradeDistribution & { timeBucket: string })[];
  };
  guaranteedServiceStats: {
    data: BucketMetrics[];
    trend: TrendData;
  };
}

export interface DashboardResponse {
  timeBuckets: string[];
  overallMetrics: {
    data: BucketMetrics[];
    trend: TrendData;
  };
  courierPerformance: CourierPerformanceSummary;
  serviceTypeBreakdown: ServiceTypeBreakdown;
  topRoutes: TopPerformingRoute[];
  topCouriers: TopPerformingCourier[];
}

// Shipment Performance Interfaces
export interface ShipmentPerformance {
  id: number;
  shipment_id: number;
  account_type?: string;
  courier: ShippingProviders;
  shipment_number?: string;
  tracking_number?: string;
  shipment_created_at?: string;
  estimated_delivery: string;
  pickup_time?: string;
  expected_delivery?: string;
  actual_delivery?: string;
  is_outlier: boolean;
  origin_city: string;
  origin_province: string;
  origin_country: string;
  destination_city: string;
  destination_province: string;
  destination_country: string;
  performance_grade?: PerformanceGrade;
  service_type: string;
  service_name: string;
  is_guaranteed: boolean;
  delay?: number;
  created_at: string;
  updated_at: string;
}

export interface ShipmentAggregateResponse {
  data: ShipmentPerformance[];
  pagination: {
    total: number;
    page: number;
    pageSize: number;
  };
}

export interface ShipmentAggregateCountResponse {
  count: number;
}

// API operation responses
export interface FlagShipmentOutlierResponse {
  shipmentId: number;
  isOutlier: boolean;
  updatedAt: string;
}

export interface ReprocessShipmentResponse {
  success: boolean;
  jobId: string;
  message: string;
}

export interface BulkReprocessResponse {
  success: boolean;
  message: string;
  count: number;
}

export interface TriggerMetricsCalculationResponse {
  success: boolean;
  jobIds: Record<string, string>;
  message: string;
}
