import { Injectable } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ErrorMessages } from '@app/shared/constants';

export interface GlobalConfiguration {
  byoa_charge_amount: string | number;
  return_centre_renewal: string | number;
  maximum_wallet_fund_attempts: number;
  wallet_fund_time_window: number; // in seconds
}

@Injectable({ providedIn: 'root' })
export class GlobalConfigurationService {
  constructor(
    private helperService: HelpersService,
    private http: HttpClient
  ) {}

  public getGlobalConfiguration(): Observable<GlobalConfiguration> {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/global_configuration`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public updateGlobalConfiguration(params: GlobalConfiguration): Observable<GlobalConfiguration> {
    return this.http
      .put(`${environment.APP_SERVICES_URL}/admin/global_configuration`, params)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }
}
