import { Component, Inject, OnInit } from '@angular/core';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@app/shared/services/logger.service';
import { OtpService } from '@app/shared/services/otp.service';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import * as _ from 'lodash';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-recalculate',
  templateUrl: './recalculate-otp.component.html'
})
export class RecalculateOtpComponent implements OnInit {
  public aggregateRecalculateForm: FormGroup;
  public bulkShipmentRecalculateForm: FormGroup;
  public loading: boolean = true;
  public timePeriods = [
    {
      id: 'weekly',
      text: 'Weekly',
    },
    {
      id: 'monthly',
      text: 'Monthly',
    },
    {
      id: 'quarterly',
      text: 'Quarterly',
    }
  ]

  constructor(
    private formBuilder: FormBuilder,
    private logger: LoggerService,
    private otpService: OtpService,
    private userService: UserService,
    private bannerNotificationService: BannerNotificationsService,
    public drawerRef: MtxDrawerRef<RecalculateOtpComponent>,
    @Inject(MTX_DRAWER_DATA) public data: { adminUser: AdminUser; },
  ) {}

  ngOnInit() {
    this.loading = true;
    if (
      this.data &&
      this.data.adminUser &&
      this.userService.hasAuthScope(this.data.adminUser, [AdminScopes.MODIFY_OTP])
    ) {
      this.createForms();
    } else {
      this.dismissDrawer();
    }
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  public recalculateAggregate() {
    this.loading = true;
    const params = {};
    if (this.aggregateRecalculateForm.value.date) {
      _.extend(params, { date: new Date(this.aggregateRecalculateForm.value.date).toISOString().split('T')[0] });
    }

    const periodFilter = this.aggregateRecalculateForm.value.period;

    if (this.aggregateRecalculateForm.value.period?.length > 0) {
      _.extend(params, { periods: periodFilter });
    }

    this.otpService.postRecalculate(params).subscribe(
      (response) => {
        this.logger.log('POST OTP Aggregate Recalculate', response);
        this.bannerNotificationService.success('OTP Data recalculated for this date');
        this.dismissDrawer();
        this.loading = false;
      },
      (error) => {
        this.logger.error('POST OTP Aggregate Recalculate', error);
        this.bannerNotificationService.error('There was a problem recalculating OTP for that date');
        this.loading = false;
      }
    )
  }

  public recalculateBulkShipments() {
    this.loading = true;
    const { redisKey } = this.bulkShipmentRecalculateForm.value;

    this.otpService.bulkReprocessShipments({ redisKey: redisKey?.trim() }).subscribe(
      (response) => {
        this.logger.log('POST OTP Bulk Shipment Recalculate', response);
        this.bannerNotificationService.success('Scheduled bulk reprocessing');
        this.dismissDrawer();
        this.loading = false;
      },
      (error) => {
        this.logger.error('POST OTP Bulk Shipment Recalculate', error);
        this.bannerNotificationService.error('Failed to schedule bulk reprocessing');
        this.loading = false;
      }
    )
  }


  private createForms(): void {
    this.aggregateRecalculateForm = this.formBuilder.group({
      date: [''],
      period: [''],
    });
    this.bulkShipmentRecalculateForm = this.formBuilder.group({
      redisKey: ['', Validators.required],
    });
    this.loading = false;
  }
}
