<ng-container *ngIf='!loading, else isLoading'>
  <div class='row'>
    <div class='column--12'>
      <!-- Service Type Summary Card -->
      <mat-card class="summary-card">
        <mat-card-content class='card p--md card-heatmap'>
          <app-canada-heatmap
            #heatMap
            [data]="locationData"
            [minRadius]="5"
            [maxRadius]="25"
            [maxZoom]="10"
            [detailZoomThreshold]="5"
            (provinceSelected)="onProvinceSelected($event)"
            (requestDetailData)="onRequestDetailData($event)"
          ></app-canada-heatmap>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
