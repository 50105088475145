import { Component, OnInit } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { SessionStorageService } from 'ngx-webstorage';
import * as _ from 'lodash';
import { BillingTypesVerbose, ShippingProviderNames } from '@app/shared/constants';
import { ProvidersService } from '@app/shared/services/providers.service';
import { Router } from '@angular/router';
import { CompanyService } from '@app/shared/services/company.service';
import { first } from 'rxjs';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public loading = false;
  public company: any;
  public owner: any;
  public formattedAddress: any;
  public machoolPickupProviderImages: string[] = [];
  public billingType: BillingTypesVerbose = BillingTypesVerbose.CREDIT_CARD;
  public isCCUser: boolean = true;
  public selectedTab: number = 0;
  public user: AdminUser;

  public tabs = [
    {
      label: 'Payment type',
      id: 'app-payment-type',
      scope: [AdminScopes.VIEW_USERS_SETTINGS_PAYMENT_TYPE],
    },
    {
      label: 'Billing info',
      id: 'app-billing-info',
      scope: [AdminScopes.VIEW_USERS_SETTINGS_BILLING],
    },
    {
      label: 'Company charges',
      id: 'app-company-charges',
      scope: [AdminScopes.VIEW_USERS_SETTINGS_COMPANY_CHARGES],
    },
    {
      label: 'Machool Wallet',
      id: 'app-wallet-transactions',
      scope: [AdminScopes.VIEW_USERS_SETTINGS_WALLET_TRANSACTIONS],
    },
    {
      label: 'Base tier',
      id: 'app-tier',
      scope: [AdminScopes.VIEW_USERS_SETTINGS_TIER],
    },
    {
      label: 'Courier modifier',
      id: 'app-courier-modifiers',
      scope: [AdminScopes.VIEW_USERS_SETTINGS_COURIER_MODIFIERS],
    },
    {
      label: 'Dynamic pricing',
      id: 'app-dynamic-pricing',
      scope: [AdminScopes.VIEW_USERS_SETTINGS_DYNAMIC_PRICING],
    },
    // Multi-Courier 3.0, hiding all machool pickup component for now
    // TODO: ACC - Revisit this when business logic is changed
    // {
    //   label: 'Pickup',
    //   id: 'app-pickup',
    //   scope: [AdminScopes.VIEW_USERS_SETTINGS_MACHOOL_PICKUP],
    // },
    {
      label: 'Notes',
      id: 'app-notes',
      scope: [AdminScopes.VIEW_USERS_SETTINGS_NOTES],
    },
    {
      label: 'Sales',
      id: 'app-sales',
      scope: [AdminScopes.VIEW_USERS_SETTINGS_SALES],
    },
    {
      label: 'Settings',
      id: 'app-company-settings',
      scope: [AdminScopes.VIEW_USERS_SETTINGS_COMPANY_SETTINGS],
    },
    // Removed for now
    // { label: 'Billing', id: 'app-billing', scope: [AdminScopes.VIEW_USERS_SETTINGS_BILLING] },
    // { label: 'Couriers', id: 'app-couriers', scope: [AdminScopes.VIEW_USERS_SETTINGS_COURIERS] },
  ];

  constructor(
    private companyService: CompanyService,
    private helpersService: HelpersService,
    private logger: LoggerService,
    private providersService: ProvidersService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    // this.initializeTabs();
    this.company = this.sessionStorageService.retrieve('settingsCompany');

    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_USERS_SETTINGS])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            this.cleanTabs();
            // this.getCompany();
            if (this.company) {
              const companyClone = _.cloneDeep(this.company);
              this.owner = companyClone.owner;
              this.formattedAddress = this.helpersService.formatToTwoLinesAddress(
                this.company.address.line1,
                this.company.address.line2,
                this.company.address.city,
                this.company.address.province,
                this.company.address.country,
                this.company.address.postalCode,
              );
              this.getCompany();
            } else {
              this.router.navigate(['users']);
            }
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['users']);
        },
      );
  }

  public getCompany(): void {
    this.loading = true;
    this.companyService.get(this.company.uuid, true).subscribe(
      (company: any) => {
        this.company = company;
        this.company.owner = this.owner;
        this.company.notes = _.orderBy(this.company.notes, (note) => note.id, 'desc');
        this.company.tierName = this.helpersService.convertPricingTiersEnumToString(company.pricingTier);
        this.getEnabledMachoolPickupProviders();
        this.billingType = this.companyService.getBillingTypeVerbose(company);
        this.isCCUser = this.billingType === BillingTypesVerbose.CREDIT_CARD;
        this.logger.log('Customer Settings - GET company', this.company);
        this.loading = false;
      },
      (err: Error) => {
        this.logger.error('Customer Settings - GET company error', err);
        this.loading = false;
      },
    );
  }

  private getEnabledMachoolPickupProviders(): void {
    this.machoolPickupProviderImages = [];
    const machoolPickupProviders = _.get(this.company, 'settings.machoolPickupProviders', []) || [];
    const foundAirCanadaProvider = machoolPickupProviders.find((machoolPickupProvider) =>
      [
        ShippingProviderNames.AIR_CANADA_DOMESTIC,
        ShippingProviderNames.AIR_CANADA_INTERNATIONAL,
        ShippingProviderNames.RIVO,
      ].includes(machoolPickupProvider),
    );

    // Use the same AirCanada logo for all domestic and intl
    if (foundAirCanadaProvider) {
      const providerImage = this.providersService.getProviderSmallLogo(
        ShippingProviderNames.RIVO.toLowerCase(),
        undefined,
        true,
      );
      this.machoolPickupProviderImages.push(providerImage);
    }

    // Avoid showing duplicate of AC logo for both domestic and intl
    const filteredMachoolPickupProviders = machoolPickupProviders.filter(
      (machoolPickupProvider) =>
        ![
          ShippingProviderNames.AIR_CANADA_DOMESTIC,
          ShippingProviderNames.AIR_CANADA_INTERNATIONAL,
          ShippingProviderNames.RIVO,
        ].includes(machoolPickupProvider),
    );

    filteredMachoolPickupProviders.forEach((provider: string) => {
      const providerImage = this.providersService.getProviderSmallLogo(provider, undefined, true);
      this.machoolPickupProviderImages.push(providerImage);
    });
  }

  private cleanTabs() {
    const originalTabs = _.clone(this.tabs);
    this.tabs = originalTabs.filter((tab) => {
      return this.userService.hasAuthScope(this.user, tab.scope);
    });
  }
}
