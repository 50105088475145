<div class='container mx--none'>
  <ng-container *ngIf='!loading, else isLoading'>
    <div class='row'>
      <div class='column--2'>
        <button mat-button color='primary' class='mt--sm' (click)="goTo('')">&lt; Back to dashboard</button>
      </div>
      <div class='column--8 align-right'>
        <app-time-bucket-filter [adminUser]='user' [canModifyOtp]='canModifyOtp'></app-time-bucket-filter>
      </div>
      <div class='column--2 align-right'>
        <form [formGroup]='form'>
          <mat-form-field class='w-100'>
            <mat-label>Couriers</mat-label>
            <mat-select formControlName="couriers" name='couriers' placeholder='All couriers' multiple>
              <mat-option *ngFor='let option of couriersDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>
    <nav
      mat-tab-nav-bar
      mat-stretch-tabs="false"
      [tabPanel]="tabPanel"
    >
      <a mat-tab-link
         *ngFor="let link of navLinks"
         [routerLink]="link.path"
         routerLinkActive #rla="routerLinkActive"
         [active]="rla.isActive">
        {{ link.label }}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <div class='mt--lg pb--md'>
        <router-outlet></router-outlet>
      </div>
    </mat-tab-nav-panel>
  </ng-container>
  <ng-template #isLoading>
    <div class='align-center mt--lg'>
      <app-loading></app-loading>
    </div>
  </ng-template>
</div>
