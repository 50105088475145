<div class='row row--valign-center'>
  <div class='column--8'>
    <h3>Recalculate Aggregate OTP Stats</h3>
  </div>
  <div class='column--4 align-right'>
    <button mat-icon-button color='primary' (click)='dismissDrawer()'><mat-icon>close</mat-icon></button>
  </div>
</div>

<ng-container *ngIf='!loading, else isLoading'>
  <form [formGroup]='aggregateRecalculateForm'>
    <div class='row mt--md'>
      <div class='column--10'>
        <mat-form-field class="full-width">
          <mat-label>Date</mat-label>
          <input matInput formControlName="date" [matDatepicker]="recalculateDatePicker">
          <mat-datepicker-toggle matIconSuffix [for]="recalculateDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #recalculateDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class='row mt--md'>
      <div class='column--10'>
        <mat-form-field appearance="outline">
          <mat-label>Period</mat-label>
          <mat-select formControlName="period" multiple>
            <mat-option *ngFor='let period of timePeriods' value="{{ period.id }}">{{ period.text }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class='row'>
      <div class='column--6'>
        <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
      </div>
      <div class='column--6 align-right'>
        <button mat-flat-button color='primary' (click)='recalculateAggregate()'>Recalculate</button>
      </div>
    </div>
  </form>
</ng-container>

<mat-divider class='mt--lg mb--lg'></mat-divider>

<div class='row row--valign-center'>
  <div class='column--8'>
    <h3>Recalculate bulk shipment OTP Stats</h3>
  </div>
</div>

<ng-container *ngIf='!loading, else isLoading'>
  <form [formGroup]='bulkShipmentRecalculateForm'>
    <div class='row mt--md'>
      <div class='column--10'>
        <mat-form-field class='full-width'>
          <mat-label>Redis key</mat-label>
          <input matInput formControlName='redisKey' name='redisKey' placeholder="otp:bulk-reprocess:batch123">
        </mat-form-field>
      </div>
    </div>

    <div class='row'>
      <div class='column--6'>
        <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
      </div>
      <div class='column--6 align-right'>
        <button mat-flat-button color='primary' (click)='recalculateBulkShipments()'>Recalculate</button>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>

