import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { first, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { StateService } from '@app/shared/services/state.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OtpService } from '@app/shared/services/otp.service';

@Component({
  selector: 'app-otp-details',
  templateUrl: './details.component.html',
})
export class OtpDetailsComponent implements OnInit, OnDestroy {
  public loading: boolean = true;
  public user: AdminUser;
  public canViewOtp: boolean = false;
  public canModifyOtp: boolean = false;
  public form: FormGroup | undefined;
  public couriersDropdown = [];
  public selectedCouriers: string[] = [];
  public courierFilter$: Observable<any> = this.stateService.courierFilter$;
  private courierFilterSubscription: Subscription;

  public navLinks = [
    { path: 'couriers', label: 'Couriers' },
    // { path: 'services', label: 'Services' },
    // { path: 'regions', label: 'Regions' },
    // { path: 'analytics', label: 'Analytics' },
  ];

  constructor(
    private userService: UserService,
    private stateService: StateService,
    private otpService: OtpService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}
  ngOnInit() {
    this.loading = true;
    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_OTP])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            this.stateService.setAdminUserData(adminUser);
            this.canViewOtp = this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_OTP]);
            this.canModifyOtp = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_OTP]);
            this.courierFilterSubscription = this.courierFilter$.subscribe((selectedCouriers) => {
              if (this.couriersDropdown.length === 0) {
                this.selectedCouriers = selectedCouriers;
                this.createForm();
              }
              this.loading = false;
            });
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['home']);
        }
      );
  }

  ngOnDestroy() {
    this.courierFilterSubscription.unsubscribe();
  }

  public goTo(urlStub?: string): void {
    const navigateTo: string[] = ['otp'];
    if (urlStub) {
      navigateTo.push(urlStub);
    }
    this.router.navigate(navigateTo);
  }

  private createForm(){
    this.form = this.formBuilder.group({
      couriers: ['', []],
    });
    const couriers = this.otpService.getSupportedCouriers();
    couriers.forEach((courier: any) => {
      this.couriersDropdown.push({ id: courier.id, text: courier.name, selected: this.selectedCouriers?.includes(courier.id) || false});
    })
    if (this.selectedCouriers) {
      this.form.patchValue(
        {
          couriers: this.selectedCouriers
        },
        {
          emitEvent: false
        },
      );
    }
    this.form.valueChanges.subscribe(() => {
      const couriers = this.form.value.couriers;
      this.stateService.setCourierFilterData(couriers);
    });
  }
}
