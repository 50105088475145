import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import {
  RouteData
} from '@app/pages/authenticated/pages/otp/components/interactive-route-explorer/interactive-route-explorer.component';
import { Observable, Subscription } from 'rxjs';
import { StateService } from '@app/shared/services/state.service';
import { HelpersService } from '@app/shared/services/helpers.service';
import { OtpService } from '@app/shared/services/otp.service';

@Component({
  selector: 'app-otp-analytics',
  templateUrl: './otp-analytics.component.html',
  styleUrls: ['./otp-analytics.component.scss'],
})
export class OtpAnalyticsComponent implements OnInit, OnDestroy {

  public timeBucket$ = this.stateService.timeBucketInfo$;
  private timeBucketSubscription: Subscription;
  public courierFilter$ = this.stateService.courierFilter$;
  private courierFilterSubscription: Subscription;
  public adminUserInfo$: Observable<any> = this.stateService.adminUserInfo$;
  private adminUserInfoSubscription: Subscription;
  public canViewOtp: boolean = false;

  public loading: boolean = true;

  public routesData: RouteData[] = [
    {
      origin: { longitude: -79.3832, latitude: 43.6532, name: 'Toronto' },
      destination: { longitude: -73.5673, latitude: 45.5017, name: 'Montreal' },
      performance: 94.5,
      volume: 128,
    },
    {
      origin: { longitude: -79.3832, latitude: 43.6532, name: 'Toronto' },
      destination: { longitude: -123.1207, latitude: 49.2827, name: 'Vancouver' },
      performance: 89.4,
      volume: 85,
    },
  ];

  constructor(
    private helpersService: HelpersService,
    private otpService: OtpService,
    private userService: UserService,
    private stateService: StateService,
  ){}

  ngOnInit() {
    this.loading = true;
    this.adminUserInfo$.subscribe((adminUserInfo: AdminUser) => {
      this.canViewOtp = this.userService.hasAuthScope(adminUserInfo, [AdminScopes.VIEW_OTP]);
      if (this.canViewOtp) {
        // TODO - Get content
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.adminUserInfoSubscription?.unsubscribe();
    this.timeBucketSubscription?.unsubscribe();
    this.courierFilterSubscription?.unsubscribe();
  }

}
