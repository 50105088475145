<div class="row">
  <div class='column--3'>
    <mat-card class="summary-card">
      <mat-card-content class='card p--md card-route-filters'>
        <h3 class='pt--none mt--none'>Route Filters</h3>
        <form *ngIf='filterForm' [formGroup]="filterForm">
          <!-- Origin Province -->
          <mat-form-field appearance="outline">
            <mat-label>Origin Province</mat-label>
            <mat-select formControlName="originProvince">
              <mat-option value="ontario">Ontario</mat-option>
              <!-- Add more provinces here -->
            </mat-select>
          </mat-form-field>

          <!-- Origin City -->
          <mat-form-field appearance="outline">
            <mat-label>Origin City</mat-label>
            <mat-select formControlName="originCity">
              <mat-option value="toronto">Toronto</mat-option>
              <!-- Add more cities here -->
            </mat-select>
          </mat-form-field>

          <!-- Destination Province -->
          <mat-form-field appearance="outline">
            <mat-label>Destination Province</mat-label>
            <mat-select formControlName="destinationProvince">
              <mat-option value="all">All</mat-option>
              <!-- Add more provinces here -->
            </mat-select>
          </mat-form-field>

          <!-- Destination City -->
          <mat-form-field appearance="outline">
            <mat-label>Destination City</mat-label>
            <mat-select formControlName="destinationCity">
              <mat-option value="all">All</mat-option>
              <!-- Add more cities here -->
            </mat-select>
          </mat-form-field>

          <!-- Courier -->
          <mat-form-field appearance="outline">
            <mat-label>Courier</mat-label>
            <mat-select formControlName="courier">
              <mat-option value="all">All Couriers</mat-option>
              <!-- Add more couriers here -->
            </mat-select>
          </mat-form-field>

          <!-- Service Type -->
          <mat-form-field appearance="outline">
            <mat-label>Service Type</mat-label>
            <mat-select formControlName="serviceType">
              <mat-option value="all">All Services</mat-option>
              <!-- Add more service types here -->
            </mat-select>
          </mat-form-field>

          <!-- On-Time Performance Slider -->
          <div class="slider-container">
            <label>On-Time Performance</label>
            <mat-slider min="0" max="100" step="1">
              <input matSliderStartThumb value="50" />
              <input matSliderEndThumb value="100" />
            </mat-slider>
            <div class="slider-labels">
              <span>Min: {{ performanceRange[0] }}%</span>
              <span>Max: {{ performanceRange[1] }}%</span>
            </div>
          </div>

          <!-- Apply Filters Button -->
          <button mat-raised-button color="primary" class="apply-button" (click)="updateRoutes()">
            Apply Filters
          </button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div class='column--9'>
    <div class='row'>
      <div class='column--12'>
        <mat-card class="summary-card">
          <mat-card-content class='card p--md card-route-explorer'>
            <!-- Map Section -->
            <div #routeMap class="map-container" id='route-map-container'></div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class='column--12'>
        <mat-card class="summary-card">
          <mat-card-content class='card p--md card-route-details'>
            <!-- Route Details Section -->
            <h3 class='py--none my--none'>Toronto → Montreal Route Details</h3>
            <div class="row route-stats">
              <div class='column--4'><p>Total Shipments: 128</p></div>
              <div class='column--4'><p>On-Time: 94.5%</p></div>
              <div class='column--4'><p>Average Delay: 1.2 hours</p></div>
            </div>
            <div class="mt-md courier-performance">
              <h4 class='pb--none mb--none'>Courier Performance:</h4>
              <div class='row'>
                <div class='column--2'><p>DHL: 97.2%</p></div>
                <div class='column--2'><p>UPS: 94.8%</p></div>
                <div class='column--2'><p>FedEx: 92.5%</p></div>
                <div class='column--2'><p>Purolator: 90.1%</p></div>
                <div class='column--4 align-right'>
                  <div class="button-group">
                    <button mat-raised-button color="primary" class='mr--md'>Full Analysis</button>
                    <button mat-raised-button color="accent">Outliers</button>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
