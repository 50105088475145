<ng-container *ngIf='!loading, else isLoading'>
  <ng-container *ngIf='canViewACCReports && canViewMachoolReports'>
    <mat-tab-group
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      dynamicHeight
      animationDuration='0ms'
      [selectedIndex]="selectedTab"
      (selectedIndexChange)='selectedTab = $event'
    >
      <mat-tab *ngFor='let tab of tabs' [label]='tab.label'>
        <ng-template matTabContent>
          <div class='mt--lg'>
            <app-machool-reports
              *ngIf="canViewMachoolReports && tab.id === 'app-machool-reports'"
              [user]='user'
              [canViewMachoolReports]='canViewMachoolReports'
              [canDownloadCardData]='canDownloadCardData'
              [canViewCards]='canViewCards'
              [canViewTopCouriers]='canViewTopCouriers'
              [canViewTopRevenue]='canViewTopRevenue'
            ></app-machool-reports>
            <app-acc-reports
              *ngIf="canViewACCReports && tab.id === 'app-acc-reports'"
              [user]='user'
              [canViewACCReports]='canViewACCReports'
            ></app-acc-reports>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-container *ngIf='canViewMachoolReports && !canViewACCReports'>
    <app-machool-reports
      *ngIf='canViewMachoolReports'
      [user]='user'
      [canViewMachoolReports]='canViewMachoolReports'
      [canViewTopCouriers]='canViewTopCouriers'
      [canDownloadCardData]='canDownloadCardData'
      [canViewCards]='canViewCards'
      [canViewTopRevenue]='canViewTopRevenue'
    ></app-machool-reports>
  </ng-container>
  <ng-container *ngIf='!canViewMachoolReports && canViewACCReports'>
    <app-acc-reports
      *ngIf='canViewACCReports'
      [user]='user'
      [canViewACCReports]='canViewACCReports'
    ></app-acc-reports>
  </ng-container>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
