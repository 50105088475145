import { firebase, firebaseui } from 'firebaseui-angular';

export const commonEnv = {
  production: false,
  staging: false,
  testing: false,
  development: false,
  OLD_CDN_URL: 'https://storage.googleapis.com/assets.machool.com/', // TODO - Remove once app-cli is using the CDN as well
  CDN_URL: 'https://cdn.machool.com',

  // The following are all modifiable preset
  APP_SERVICES_URL: 'http://<hostname>:3001/api/v1',
  INTEGRATION_SERVICES_URL: 'http://<hostname>:3010/api/v1',
  UTILITY_SERVICES_URL: 'http://<hostname>:3020/api/v1',
  TIMESERIES_SERVICES_URL: 'http://<hostname>:3030/api/v1',
  ADMIN_PORTAL_URL: 'http://<hostname>:4200',
  APP_CLI_URL: 'http://<hostname>',
  firebaseTenantID: '',
  firebaseConfig: {
    apiKey: 'AIzaSyDlonGDCbF0gQFJoVA-huv1ZcsvFw-GUEk',
    authDomain: 'udo-staging.firebaseapp.com',
    projectId: 'udo-staging',
    storageBucket: 'udo-staging.appspot.com',
    messagingSenderId: '371213079555',
    appId: '1:371213079555:web:a2ec979e0efc4b06696a36',
  },

  firebaseUiAuthConfig: {
    signInFlow: 'popup',
    signInOptions: [
      {
        requireDisplayName: false,
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      },
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  },
};
