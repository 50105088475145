import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HelpersService } from '@app/shared/services/helpers.service';
import { AdminScopes, AdminUser } from '@app/shared/services/user.service';
import { ProvidersService } from '@app/shared/services/providers.service';
import {
  AccountTypes,
  AccountTypesVerbose,
  ProvinceList,
  ShippingProviders,
} from '@app/shared/constants';
import { DateTime } from 'luxon';
import {
  MachoolReportTopRevenueComponent
} from '@app/pages/authenticated/pages/reports/components/machool-report-top-revenue/machool-report-top-revenue.component';
import {
  MachoolReportTopCourierComponent
} from '@app/pages/authenticated/pages/reports/components/machool-report-top-courier/machool-report-top-courier.component';

@Component({
  selector: 'app-machool-reports',
  templateUrl: './machool-reports.component.html',
})
export class MachoolReportsComponent implements OnInit {
  @ViewChild('machoolReportCardsComponent') machoolReportCardsComponent: MachoolReportsComponent;
  @ViewChild('machoolReportTopRevenueComponent') machoolReportTopRevenueComponent: MachoolReportTopRevenueComponent;
  @ViewChild('machoolReportTopCourierComponent') machoolReportTopCourierComponent: MachoolReportTopCourierComponent;

  @Input() user: AdminUser;
  @Input() canViewMachoolReports: boolean = false;
  @Input() canViewCards: boolean = false;
  @Input() canDownloadCardData: boolean = false;
  @Input() canViewTopRevenue: boolean = false;
  @Input() canViewTopCouriers: boolean = false;

  public loading: boolean = true;
  public selectedTab: number = 0;
  public tabs = [];

  public filterForm: FormGroup | undefined;

  public readonly provincesDropdown = ProvinceList;

  public readonly couriersDropdown = [];

  public readonly accountTypeDropdown = [
    { id: AccountTypes.BYOA, text: AccountTypesVerbose.BYOA },
    { id: AccountTypes.MACHOOL, text: AccountTypesVerbose.MACHOOL },
  ];

  public daterange = {
    start: DateTime.now().minus({ day: 7 }).toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  };

  public range = new FormGroup({
    start: new FormControl<Date | null>(this.daterange.start),
    end: new FormControl<Date | null>(this.daterange.end),
  });

  constructor(
    private formBuilder: FormBuilder,
    private helpersService: HelpersService,
    private providersService: ProvidersService,
  ) {}
  ngOnInit() {
    this.loading = true;
    if (this.canViewMachoolReports) {
      if (this.canViewCards) {
        this.tabs.push(
          {
            label: 'Reports',
            id: 'app-report-cards',
            scope: [AdminScopes.VIEW_MACHOOL_REPORTS],
          }
        );
      }
      if (this.canViewTopRevenue) {
        this.tabs.push(
          {
            label: 'Customers',
            id: 'app-report-top-customers',
            scope: [AdminScopes.VIEW_REPORTS_TOP_REVENUE],
          }
        );
      }
      if (this.canViewTopCouriers) {
        this.tabs.push(
          {
            label: 'Couriers',
            id: 'app-report-top-couriers',
            scope: [AdminScopes.VIEW_REPORTS_TOP_COURIERS],
          }
        );
      }
      this.createForm();
      this.getAllInformation();
      this.loading = false;
    }
  }

  // fetch all the data for the cards
  public getAllInformation(): void {
    if (this.canViewCards && this.machoolReportCardsComponent) {
      this.machoolReportCardsComponent.getAllInformation();
    }
    if (this.canViewTopRevenue && this.machoolReportTopRevenueComponent) {
      this.machoolReportTopRevenueComponent.getTopRevenueList();
    }
    if (this.canViewTopCouriers && this.machoolReportTopCourierComponent) {
      this.machoolReportTopCourierComponent.getTopCouriersList();
    }
  }

  // INTERNAL HELPER FUNCTIONS
  private createForm() {
    // Create the base form
    this.filterForm = this.formBuilder.group({
      provinces: ['', []],
      couriers: ['', []],
      accountTypes: ['', []],
    });

    // Populate the couriers dropdown
    const allProviders: any[] = this.providersService.getProvidersList();
    allProviders.forEach((provider) => {
      if ([ShippingProviders.RIVO].includes(provider.enum)) {
        this.couriersDropdown.push({
          id: provider.enum,
          text: this.helpersService.convertShipmentProviderToString(provider.enum, undefined, true),
        });
      } else if (![ShippingProviders.INTELCOM].includes(provider.enum)) {
        this.couriersDropdown.push({
          id: provider.enum,
          text: provider.name,
        });
      }
    });
    this.providersService.getFreightProvidersList().forEach((provider) => {
      this.couriersDropdown.push({ id: provider.enum, text: provider.name });
    });
    // Sort the couriers alphabetically
    this.couriersDropdown.sort((a, b) => (a.text < b.text ? -1 : 1));
  }
}
