<div class="dashboard">
  <div class='row'>
    <div class='column--12'>
      <!-- Standard Header Bar -->
      <mat-card class="summary-card">
        <mat-card-content class='card p--md'>
          <div class='row'>
            <div class='column--12'>
              <div class='row'>
                <div class='column--6'>
                  <h2>Regional Performance</h2>
                </div>
                <div class='column--6 align-right'>
                  <div class="header-actions">
                    <button mat-raised-button color="primary">Trigger Metrics</button>
                    <button mat-raised-button color="basic">Export</button>
                  </div>
                </div>
              </div>
            </div>
            <div class='column--12'>
              <!-- Filter Controls -->
              <div class="filter-controls">
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Period: Weekly">
                    <mat-option value="weekly">Weekly</mat-option>
                    <mat-option value="monthly">Monthly</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-select placeholder="2025-W10">
                    <mat-option value="2025-W10">2025-W10</mat-option>
                    <mat-option value="2025-W09">2025-W09</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Origin: All">
                    <mat-option value="all">All</mat-option>
                    <mat-option value="toronto">Toronto</mat-option>
                    <mat-option value="vancouver">Vancouver</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Destination: All">
                    <mat-option value="all">All</mat-option>
                    <mat-option value="montreal">Montreal</mat-option>
                    <mat-option value="calgary">Calgary</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class='column--12'>
      <mat-card class="summary-card">
        <mat-card-content class='card p--md'>
          <!-- Top Performing Routes Card -->
          <h3>Top Performing Routes</h3>
          <table mat-table [dataSource]="topRoutes" class="mat-elevation-z0">
            <!-- Origin → Destination Column -->
            <ng-container matColumnDef="route">
              <th mat-header-cell *matHeaderCellDef>Origin → Destination</th>
              <td mat-cell *matCellDef="let element">{{ element.route }}</td>
            </ng-container>

            <!-- Total Column -->
            <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef>Total</th>
              <td mat-cell *matCellDef="let element">{{ element.total }}</td>
            </ng-container>

            <!-- On-Time % Column -->
            <ng-container matColumnDef="onTime">
              <th mat-header-cell *matHeaderCellDef>On-Time %</th>
              <td mat-cell *matCellDef="let element">{{ element.onTime }}</td>
            </ng-container>

            <!-- Best Courier Column -->
            <ng-container matColumnDef="bestCourier">
              <th mat-header-cell *matHeaderCellDef>Best Courier</th>
              <td mat-cell *matCellDef="let element">{{ element.bestCourier }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="topRoutesColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: topRoutesColumns;"></tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
    <div class='column--12'>
      <mat-card class="summary-card">
        <mat-card-content class='card p--md'>
          <!-- Worst Performing Routes Card -->
          <h3>Worst Performing Routes</h3>
          <table mat-table [dataSource]="worstRoutes" class="mat-elevation-z0">
            <!-- Origin → Destination Column -->
            <ng-container matColumnDef="route">
              <th mat-header-cell *matHeaderCellDef>Origin → Destination</th>
              <td mat-cell *matCellDef="let element">{{ element.route }}</td>
            </ng-container>

            <!-- Total Column -->
            <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef>Total</th>
              <td mat-cell *matCellDef="let element">{{ element.total }}</td>
            </ng-container>

            <!-- On-Time % Column -->
            <ng-container matColumnDef="onTime">
              <th mat-header-cell *matHeaderCellDef>On-Time %</th>
              <td mat-cell *matCellDef="let element">{{ element.onTime }}</td>
            </ng-container>

            <!-- Worst Courier Column -->
            <ng-container matColumnDef="worstCourier">
              <th mat-header-cell *matHeaderCellDef>Worst Courier</th>
              <td mat-cell *matCellDef="let element">{{ element.worstCourier }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="worstRoutesColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: worstRoutesColumns;"></tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
    <div class='column--12'>
      <mat-card class="summary-card">
        <mat-card-content class='card p--md'>
          <!-- Regional Performance Heatmap -->
          <h3>Regional Performance Heatmap</h3>
          <div class='row'>
            <div class='column--8'>
              <ngx-charts-heat-map
                [results]="heatmapData"
                [scheme]="colorScheme"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="true"
                [gradient]="true"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false">
              </ngx-charts-heat-map>
            </div>
            <div class='column--4'>
              <!-- Key Regional Statistics -->
              <h4>Key Regional Statistics</h4>
              <ul>
                <li>Toronto-Montreal corridor: Highest volume (305 shipments)</li>
                <li>DHL dominates in Ontario (92.8% on-time)</li>
                <li>Coast-to-coast routes have lowest performance (67.2%)</li>
              </ul>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
