
<div class='row'>
  <div class='column--12'>
    <h3>Accounts</h3>
  </div>
</div>
<div class='row'>
  <div class='column--6 column-xl--4 mt--sm'>
    <ng-template #accountSubStats>(Active accounts: {{accountsWithOne}})</ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingAccountsCard"
      [loadingCardFailed]="loadingAccountsCardFailed"
      iconClass="icon-user bg-report-red"
      titleClass="text-report-red"
      cardTitle="ALL ACCOUNTS"
      [totalStat]="totalAccounts"
      [subStatsTemplate]="accountSubStats"
      (refresh)="refreshAccountsCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.ACCOUNTS)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm'>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingNewAccountsCard"
      [loadingCardFailed]="loadingNewAccountsCardFailed"
      iconClass="icon-users bg-report-red"
      titleClass="text-report-red"
      cardTitle="NEW SIGNUPS"
      [totalStat]="totalNewAccounts"
      (refresh)="refreshNewAccountsCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.NEW_ACCOUNTS)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm with-gutter'>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingStartedShippingCard"
      [loadingCardFailed]="loadingStartedShippingCardFailed"
      iconClass="icon-users bg-report-red"
      titleClass="text-report-red"
      cardTitle="STARTED SHIPPING"
      [totalStat]="totalStartedShipping"
      (refresh)="refreshStartedShippingCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.STARTED_SHIPPING)'
    ></app-report-card>
  </div>
</div>
<div class='row mt--md'>
  <div class='column--12'>
    <h3>Revenue</h3>
  </div>
</div>
<div class='row'>
  <div class='column--6 column-xl--4 mt--sm'>
    <ng-template #revenueSubbStats>
      (BYOA: {{(byoaRevenue | currency : 'CAD' : 'symbol-narrow')}},
      MA: {{(maRevenue | currency : 'CAD' : 'symbol-narrow')}})
    </ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingRevenueCard"
      [loadingCardFailed]="loadingRevenueCardFailed"
      iconClass="icon-history bg-report-indigo"
      titleClass="text-report-indigo"
      cardTitle="REVENUE (by ship date)"
      [totalStat]="totalRevenue"
      [isCurrency]="true"
      [subStatsTemplate]="revenueSubbStats"
      (refresh)="refreshRevenueCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.REVENUE)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm'>
    <ng-template #revenueChargedSubbStats>
      (BYOA: {{(byoaChargedRevenue | currency : 'CAD' : 'symbol-narrow')}},
      MA: {{(maChargedRevenue | currency : 'CAD' : 'symbol-narrow')}})
    </ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingChargedRevenueCard"
      [loadingCardFailed]="loadingChargedRevenueCardFailed"
      iconClass="icon-history bg-report-indigo"
      titleClass="text-report-indigo"
      cardTitle="REVENUE (by charge date)"
      [totalStat]="totalChargedRevenue"
      [isCurrency]="true"
      [subStatsTemplate]="revenueChargedSubbStats"
      (refresh)="refreshChargedRevenueCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.REVENUE_BY_CHARGE)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm'>
    <ng-template #greenSubbStats>(Number of shipments: {{greenShipments}})</ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingGreenShipmentsCard"
      [loadingCardFailed]="loadingGreenShipmentsCardFailed"
      iconClass="icon-leaf bg-report-green"
      titleClass="text-report-green"
      cardTitle="GREEN SHIPMENTS REVENUE"
      [totalStat]="greenRevenue"
      [isCurrency]="true"
      [subStatsTemplate]="greenSubbStats"
      (refresh)="refreshGreenShipmentsCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.GREEN_SHIPMENTS_REVENUE)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm'>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingStripeCard"
      [loadingCardFailed]="loadingStripeCardFailed"
      iconClass="icon-creditcard bg-report-stripe"
      titleClass="text-report-stripe"
      cardTitle="STRIPE"
      [totalStat]="stripeRevenue"
      [isCurrency]="true"
      (refresh)="refreshStripeCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.STRIPE)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm'>
    <ng-template #reconSubbStats>
      (Stripe: {{stripeReconAdjustment | currency : 'CAD' : 'symbol-narrow'}},
      Machool billing: {{maReconAdjustment | currency : 'CAD' : 'symbol-narrow'}})
    </ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingReconAdjustmentsCard"
      [loadingCardFailed]="loadingReconAdjustmentsCardFailed"
      iconClass="icon-charge bg-report-recon"
      titleClass="text-report-recon"
      cardTitle="RECON ADJUSTMENTS"
      [totalStat]="reconAdjustment"
      [isCurrency]="true"
      [subStatsTemplate]="reconSubbStats"
      (refresh)="refreshReconAdjustmentsCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.RECON_ADJUSTMENTS)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm'>
    <ng-template #cqcdRevenueSubbStats>
      (BYOA: {{(byoaCQCDRevenue | currency : 'CAD' : 'symbol-narrow')}},
      MA: {{(maCQCDRevenue | currency : 'CAD' : 'symbol-narrow')}})
    </ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingCQCDRevenueCard"
      [loadingCardFailed]="loadingCQCDRevenueCardFailed"
      iconClass="icon-cqcd bg-tier-cqcd"
      titleClass="text-tier-cqcd"
      cardTitle="CQCD"
      [totalStat]="totalCQCDRevenue"
      [isCurrency]="true"
      [subStatsTemplate]="cqcdRevenueSubbStats"
      (refresh)="refreshCQCDRevenueCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.CQCD)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm'>
    <ng-template #pdcRevenueSubbStats>
      (BYOA: {{(byoaPDCRevenue | currency : 'CAD' : 'symbol-narrow')}},
      MA: {{(maPDCRevenue | currency : 'CAD' : 'symbol-narrow')}})
    </ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingPDCRevenueCard"
      [loadingCardFailed]="loadingPDCRevenueCardFailed"
      iconClass="icon-panierdachat bg-tier-panier"
      titleClass="text-tier-panier"
      cardTitle="PANIERDACHAT"
      [totalStat]="totalPDCRevenue"
      [isCurrency]="true"
      [subStatsTemplate]="pdcRevenueSubbStats"
      (refresh)="refreshPDCRevenueCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.PANIER_DA_CHAT)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm'>
    <ng-template #walletSubbStats>
      (Accounts with active wallet: {{ totalWalletAccounts }})
    </ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingWalletBalanceCard"
      [loadingCardFailed]="loadingWalletBalanceCardFailed"
      iconClass="icon-logo bg-report-wallet-balance"
      titleClass="text-report-wallet-balance"
      cardTitle="Machool Wallet"
      [totalStat]="totalWalletBalance"
      [subStatsTemplate]="walletSubbStats"
      [isCurrency]="true"
      (refresh)="refreshWalletBalanceCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.WALLET_BALANCE)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm with-gutter'>
    <ng-template #byoaSubbStats>(Number of shipments: {{totalByoaShipments}})</ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingByoaRevenueCard"
      [loadingCardFailed]="loadingByoaRevenueCardFailed"
      iconClass="icon-history bg-report-indigo"
      titleClass="text-report-indigo"
      cardTitle="BYOA REVENUE"
      [totalStat]="totalByoaRevenue"
      [subStatsTemplate]="byoaSubbStats"
      [isCurrency]="true"
      (refresh)="refreshByoaRevenueCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.REVENUE_BY_BYOA)'
    ></app-report-card>
  </div>
</div>
<div class='row mt--md'>
  <div class='column--12'>
    <h3>Shipments</h3>
  </div>
</div>
<div class='row mb--lg'>
  <div class='column--6 column-xl--4 mt--sm'>
    <ng-template #allShipmentsSubbStats>
      (BYOA: {{byoaShipments}}, MA: {{maShipments}})
    </ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingAllShipmentsCard"
      [loadingCardFailed]="loadingAllShipmentsCardFailed"
      iconClass="icon-box bg-report-aqua"
      titleClass="text-report-aqua"
      cardTitle="SHIPMENTS - ALL"
      [totalStat]="totalShipments"
      [subStatsTemplate]="allShipmentsSubbStats"
      (refresh)="refreshAllShipmentsCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.SHIPMENTS)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm'>
    <ng-template #manualShipmentsSubbStats>
      (BYOA: {{manualByoaShipments}}, MA: {{manualMaShipments}})
    </ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingManualShipmentsCard"
      [loadingCardFailed]="loadingManualShipmentsCardFailed"
      iconClass="icon-box bg-report-aqua"
      titleClass="text-report-aqua"
      cardTitle="SHIPMENTS - MANUAL"
      [totalStat]="totalManualShipments"
      [subStatsTemplate]="manualShipmentsSubbStats"
      (refresh)="refreshManualShipmentsCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.SHIPMENTS_MANUAL)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm'>
    <ng-template #ecomShipmentsSubbStats>
      (BYOA: {{ecommerceByoaShipments}}, MA: {{ecommerceMaShipments}})
    </ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingEcommShipmentsCard"
      [loadingCardFailed]="loadingEcommShipmentsCardFailed"
      iconClass="icon-box bg-report-aqua"
      titleClass="text-report-aqua"
      cardTitle="SHIPMENTS - E-COM"
      [totalStat]="totalEcommerceShipments"
      [subStatsTemplate]="ecomShipmentsSubbStats"
      (refresh)="refreshEcommShipmentsCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.SHIPMENTS_ECOMMERCE)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm'>
    <ng-template #apiShipmentsSubbStats>
      (BYOA: {{apiByoaShipments}}, MA: {{apiMaShipments}})
    </ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingApiShipmentsCard"
      [loadingCardFailed]="loadingApiShipmentsCardFailed"
      iconClass="icon-box bg-report-aqua"
      titleClass="text-report-aqua"
      cardTitle="SHIPMENTS - API"
      [totalStat]="totalApiShipments"
      [subStatsTemplate]="apiShipmentsSubbStats"
      (refresh)="refreshApiShipmentsCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.SHIPMENTS_API)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm'>
    <ng-template #connectShipmentsSubbStats>
      (BYOA: {{connectByoaShipments}}, MA: {{connectMaShipments}})
    </ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingConnectShipmentsCard"
      [loadingCardFailed]="loadingConnectShipmentsCardFailed"
      iconClass="icon-box bg-report-aqua"
      titleClass="text-report-aqua"
      cardTitle="SHIPMENTS - BULK SHIP"
      [totalStat]="totalConnectShipments"
      [subStatsTemplate]="connectShipmentsSubbStats"
      (refresh)="refreshConnectShipmentsCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.SHIPMENTS_CONNECT)'
    ></app-report-card>
  </div>
  <div class='column--6 column-xl--4 mt--sm with-gutter'>
    <ng-template #freightShipmentsSubbStats>
      (BYOA: {{freightByoaShipments}}, MA: {{freightMaShipments}})
    </ng-template>
    <app-report-card
      [canDownloadData]='canDownloadCardData'
      [loadingData]='downloadingReportData'
      [loadingCard]="loadingFreightShipmentsCard"
      [loadingCardFailed]="loadingFreightShipmentsCardFailed"
      iconClass="icon-box bg-report-aqua"
      titleClass="text-report-aqua"
      cardTitle="SHIPMENTS - FREIGHT"
      [totalStat]="totalFreightShipments"
      [subStatsTemplate]="freightShipmentsSubbStats"
      (refresh)="refreshFreightShipmentsCard()"
      (downloadData)='downloadCardData(ReportCardGroupTypes.SHIPMENTS_FREIGHT)'
    ></app-report-card>
  </div>
</div>
