<ng-container *ngIf='!loading, else isLoading'>
  <form [formGroup]='form'>
    <div class='row mb--md'>
      <!-- Filters -->
      <div class='column--6 column-md--2'>
        <mat-form-field appearance="outline">
          <mat-label>PERIOD</mat-label>
          <mat-select>
            <mat-option value="weekly">Weekly ▼</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class='column--6 column-md--2'>
        <mat-form-field class='full-width'>
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>
      <div class='column--6 column-md--2'>
        <mat-form-field class='full-width'>
          <mat-label>Couriers</mat-label>
          <mat-select formControlName="couriers" name='couriers' placeholder='All couriers' multiple>
            <mat-option *ngFor='let option of couriersDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class='column--6 align-right'>
        <button mat-raised-button color="primary">Export</button>
      </div>
    </div>
  </form>
  <div class='row'>
    <div class='column--12 column-md--6'>
      <!-- Service Type Summary Card -->
      <mat-card class="summary-card">
        <mat-card-content class='card p--md'>
          <mat-card-title>SERVICE TYPE SUMMARY</mat-card-title>
          <div class="row summary-content">
            <div class='column--6'>
              <p>Total Services</p>
              <h2>24</h2>
            </div>
            <div class='column--6'>
              <p>Total Shipments</p>
              <h2>8,452</h2>
            </div>
          </div>
          <div class='row'>
            <div class='column--6'>
              <p>Avg On-Time Rate</p>
              <h2>76%</h2>
            </div>
            <div class='column--6'>
              <p>Guaranteed Services</p>
              <h2>12</h2>
            </div>
          </div>
          <div class='row'>
            <div class='column--6'>
              <p>Non-Guaranteed</p>
              <h2>12</h2>
            </div>
          </div>
          <button mat-button color="primary">View Details</button>
        </mat-card-content>
      </mat-card>
    </div>
    <div class='column--12 column-md--6'>
      <!-- Performance By Service Type Card -->
      <mat-card class="chart-card">
        <mat-card-content class='card p--md'>
          <mat-card-title>PERFORMANCE BY SERVICE TYPE</mat-card-title>
          <div class='chart-container'>
            <ngx-charts-bar-vertical
              [results]="barChartData"
              [view]="[500, 300]"
              [xAxis]="true"
              [yAxis]="true">
            </ngx-charts-bar-vertical>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class='column--12 column-md--6'>
      <!-- Guaranteed vs Non-Guaranteed Card -->
      <mat-card class="chart-card">
        <mat-card-content class='card p--md'>
          <mat-card-title>GUARANTEED VS NON-GUARANTEED</mat-card-title>
          <div class='chart-container'>
            <ngx-charts-pie-chart
              [results]="donutChartData"
              [view]="[400, 300]"
              [legend]="true"
              [labels]="true">
            </ngx-charts-pie-chart>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class='column--12 column-md--6'>
      <!-- Service Type Volume Card -->
      <mat-card class="chart-card">
        <mat-card-content class='card p--md'>
          <mat-card-title>SERVICE TYPE VOLUME</mat-card-title>
          <div class='chart-container'>
            <ngx-charts-bar-horizontal
              [results]="barChartData"
              [view]="[500, 300]"
              [xAxis]="true"
              [yAxis]="true">
            </ngx-charts-bar-horizontal>
          </div>
          <button mat-button color="primary">View by Volume</button>
        </mat-card-content>
      </mat-card>
    </div>
    <div class='column--12 with-gutter'>
      <!-- Service Type Comparison Table -->
      <mat-card class="table-card">
        <mat-card-content class='card p--md'>
          <mat-card-title>SERVICE TYPE COMPARISON</mat-card-title>
          <div class='responsive-table'>
            <table mat-table [dataSource]="tableData" class="mat-elevation-z8">
            <!-- Service Type Column -->
            <ng-container matColumnDef="serviceType">
              <th mat-header-cell *matHeaderCellDef>SERVICE TYPE</th>
              <td mat-cell *matCellDef="let element">{{ element.serviceType }}</td>
            </ng-container>

            <!-- Courier Column -->
            <ng-container matColumnDef="courier">
              <th mat-header-cell *matHeaderCellDef>COURIER</th>
              <td mat-cell *matCellDef="let element">{{ element.courier }}</td>
            </ng-container>

            <!-- Volume Column -->
            <ng-container matColumnDef="volume">
              <th mat-header-cell *matHeaderCellDef>VOLUME</th>
              <td mat-cell *matCellDef="let element">{{ element.volume }}</td>
            </ng-container>

            <!-- On-Time % Column -->
            <ng-container matColumnDef="onTimePercent">
              <th mat-header-cell *matHeaderCellDef>ON-TIME %</th>
              <td mat-cell *matCellDef="let element">{{ element.onTimePercent }}%</td>
            </ng-container>

            <!-- Avg Delay Column -->
            <ng-container matColumnDef="avgDelay">
              <th mat-header-cell *matHeaderCellDef>AVG DELAY</th>
              <td mat-cell *matCellDef="let element">{{ element.avgDelay }}</td>
            </ng-container>

            <!-- Cost Column -->
            <ng-container matColumnDef="cost">
              <th mat-header-cell *matHeaderCellDef>COST</th>
              <td mat-cell *matCellDef="let element">{{ element.cost }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class='column--12 with-gutter'>
      <!-- Performance Trends Section -->
      <mat-card class="chart-card">
        <mat-card-content class='card p--md'>
          <mat-card-title>PERFORMANCE TRENDS BY SERVICE TYPE</mat-card-title>
          <div class='chart-container'>
            <ngx-charts-line-chart
              [results]="lineChartData"
              [view]="[800, 400]"
              [xAxis]="true"
              [yAxis]="true">
            </ngx-charts-line-chart>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
