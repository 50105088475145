import { Component } from '@angular/core';

@Component({
  selector: 'app-route-detail',
  templateUrl: './route-detail.component.html',
  styleUrls: ['./route-detail.component.scss']
})
export class RouteDetailComponent {
  // Data for the bar chart
  public courierComparison = [
    { name: 'DHL', value: 98 },
    { name: 'FedEx', value: 96 },
    { name: 'UPS', value: 94 },
    { name: 'Canada Post', value: 91 }
  ];

  // Data for the pie chart
  public serviceTypeBreakdown = [
    { name: 'Express Worldwide', value: 185 },
    { name: 'Priority Overnight', value: 72 },
    { name: 'Express 9:00', value: 49 },
    { name: 'Standard Express', value: 22 }
  ];

  // Data for the line chart
  public monthlyPerformance = [
    {
      name: 'On-Time %',
      series: [
        { name: 'Jun-24', value: 92.4 },
        { name: 'Jul-24', value: 93.0 },
        { name: 'Aug-24', value: 91.8 },
        { name: 'Sep-24', value: 93.6 },
        { name: 'Oct-24', value: 92.4 },
        { name: 'Nov-24', value: 91.8 },
        { name: 'Dec-24', value: 93.0 },
        { name: 'Jan-25', value: 94.2 },
        { name: 'Feb-25', value: 92.4 },
        { name: 'Mar-25', value: 91.8 },
        { name: 'Apr-25', value: 91.8 },
        { name: 'May-25', value: 91.2 }
      ]
    }
  ];

  // Table data
  public shipments = [
    { date: '05/12/25', tracking: 'DHL7283922', courier: 'DHL', service: 'Exp World', status: 'On-Time', delivery: '0.3h early' },
    { date: '05/11/25', tracking: 'FDX5429871', courier: 'FedEx', service: 'Priority', status: 'On-Time', delivery: '0.5h early' },
    { date: '05/11/25', tracking: 'UPS3641092', courier: 'UPS', service: 'Next Day', status: 'On-Time', delivery: '0.1h early' },
    { date: '05/10/25', tracking: 'DHL7283845', courier: 'DHL', service: 'Exp World', status: 'On-Time', delivery: 'On schedule' },
    { date: '05/10/25', tracking: 'CP9267340', courier: 'CanPost', service: 'Xpresspost', status: 'Delayed', delivery: '2.4h late' }
  ];

  // Columns to be displayed in the table
  public displayedColumns: string[] = ['date', 'tracking', 'courier', 'service', 'status', 'delivery'];

  // Color scheme for charts
  public colorScheme: any = {
    domain: ['#3b82f6', '#10b981', '#f59e0b', '#6366f1']
  };

  constructor() {}
}
