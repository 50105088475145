<ng-container *ngIf='!loading, else isLoading'>
  <div class='row mb--md'>
    <div class='column--12 column-md--6'>
      <!-- Performance Summary Card -->
      <mat-card class="summary-card">
        <mat-card-content class='card p--md'>
          <mat-card-title>PERFORMANCE SUMMARY</mat-card-title>
          <div class="row summary-content">
            <div class='column--6'>
              <p>Total Shipments</p>
              <h2>{{ totalShipments | number }}</h2>
            </div>
            <div class='column--6'>
              <p>On-Time Rate</p>
              <h2>{{ onTimeRate | number : '1.2-2' }}%</h2>
            </div>
          </div>
          <div class='row'>
            <div class='column--6'>
              <p>Average Delay</p>
              <h2>{{ averageDelay | number : '1.2-2' }}h</h2>
            </div>
            <!-- <div class='column--6'>
              <p>vs Last Period</p>
              <h2 class='text-primary-green'>↑ 2%</h2>
            </div> -->
          </div>
          <!-- <button mat-button color="primary">View Details</button> -->
        </mat-card-content>
      </mat-card>
    </div>
    <div class='column--12 column-md--6'>
      <!-- Grade Distribution Card -->
      <mat-card class="chart-card">
        <mat-card-content class='card p--md'>
          <mat-card-title>GRADE DISTRIBUTION</mat-card-title>
          <div class='chart-container'>
            <ngx-charts-pie-chart
              [results]="gradeDistribution"
              [legend]="true"
              [labels]="true">
            </ngx-charts-pie-chart>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class='row mb--md'>
    <div class='column--12 column-md--6'>
      <!-- Service Breakdown Card -->
      <mat-card class="chart-card">
        <mat-card-content class='card p--md'>
          <mat-card-title>SERVICE BREAKDOWN</mat-card-title>
          <div class='chart-container'>
            <ngx-charts-bar-horizontal
              [results]="serviceBreakdown"
              [xAxis]="true"
              [yAxis]="true">
            </ngx-charts-bar-horizontal>
          </div>
          <button mat-button color="primary" (click)='showAllServices()'>View All Services</button>
        </mat-card-content>
      </mat-card>
    </div>
    <div class='column--12 column-md--6'>
      <!-- Guaranteed Service Metrics -->
      <mat-card class="summary-card">
        <mat-card-content class='card p--md'>
          <mat-card-title>GUARANTEED SERVICE METRICS</mat-card-title>
          <div class="row summary-content">
            <div class='column--6'>
              <p>Total Shipments</p>
              <h2>{{ gTotalShipments | number }}</h2>
            </div>
            <div class='column--6'>
              <p>Success Rate</p>
              <h2 class='text-primary-green'>{{ gOnTimeRate | number : '1.2-2' }}%</h2>
            </div>
          </div>
          <div class='row'>
            <div class='column--6'>
              <p>On-Time Count</p>
              <h2>{{ gOnTimeCount | number }}</h2>
            </div>
            <div class='column--6'>
              <p>Average Delay</p>
              <h2>{{ gAverageDelay | number : '1.2-2' }}h</h2>
            </div>
          </div>
          <!-- <button mat-button color="primary">View Details</button> -->
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class='row mb--md' *ngIf="!isSingleCourierSelected">
    <div class='column--12'>
      <!-- Service Breakdown Card -->
      <mat-card class="chart-card">
        <mat-card-content class='card p--md'>
          <mat-card-title>Courier Breakdown</mat-card-title>
          <div class='chart-container'>
            <ngx-charts-bar-horizontal
              [results]="courierComparison"
              [xAxis]="true"
              [yAxis]="true">
            </ngx-charts-bar-horizontal>
          </div>
          <!-- <button mat-button color="primary">Show More</button> -->
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Performance Trends Section -->
<!--    <div class='column&#45;&#45;12 with-gutter'>-->
<!--      <mat-card class="chart-card">-->
<!--        <mat-card-content class='card p&#45;&#45;md'>-->
<!--          <mat-card-title>PERFORMANCE TRENDS</mat-card-title>-->
<!--          <div class='chart-container'>-->
<!--            <ngx-charts-line-chart-->
<!--              [results]="performanceTrends"-->
<!--              [legend]="true"-->
<!--              [xAxis]="true"-->
<!--              [yAxis]="false">-->
<!--            </ngx-charts-line-chart>-->
<!--          </div>-->
<!--        </mat-card-content>-->
<!--      </mat-card>-->
<!--    </div>-->
  </div>
  <div class='row mb--md'>
    <div class='column--12'>
      <!-- Detailed Metrics Table -->
      <mat-card class="table-card">
        <mat-card-content class='card p--md'>
          <mat-card-title>DETAILED METRICS</mat-card-title>
          <div class='responsive-table'>
            <table mat-table [dataSource]="detailedMetrics">
              <!-- Courier Column -->
              <ng-container matColumnDef="courier">
                <th mat-header-cell *matHeaderCellDef>COURIER</th>
                <td mat-cell *matCellDef="let element">{{ element.courier }}</td>
              </ng-container>

              <!-- Total Column -->
              <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef>TOTAL</th>
                <td mat-cell *matCellDef="let element">{{ element.total | number }}</td>
              </ng-container>

              <!-- On-Time Column -->
              <ng-container matColumnDef="onTime">
                <th mat-header-cell *matHeaderCellDef>ON-TIME</th>
                <td mat-cell *matCellDef="let element">{{ element.onTime | number : '1.2-2' }}</td>
              </ng-container>

              <!-- On-Time % Column -->
              <ng-container matColumnDef="onTimePercent">
                <th mat-header-cell *matHeaderCellDef>ON-TIME %</th>
                <td mat-cell *matCellDef="let element">{{ element.onTimePercent | number : '1.2-2' }}%</td>
              </ng-container>

              <!-- Avg Delay Column -->
              <ng-container matColumnDef="avgDelay">
                <th mat-header-cell *matHeaderCellDef>AVG DELAY</th>
                <td mat-cell *matCellDef="let element">{{ element.avgDelay | number : '1.2-2' }}</td>
              </ng-container>

              <!-- Grade A % Column -->
              <ng-container matColumnDef="gradeA">
                <th mat-header-cell *matHeaderCellDef>GRADE A %</th>
                <td mat-cell *matCellDef="let element">{{ element.gradeA | percent : '1.2-2' }}</td>
              </ng-container>

              <!-- Grade B % Column -->
              <ng-container matColumnDef="gradeB">
                <th mat-header-cell *matHeaderCellDef>GRADE B %</th>
                <td mat-cell *matCellDef="let element">{{ element.gradeB | percent : '1.2-2' }}</td>
              </ng-container>

              <!-- Grade C % Column -->
              <ng-container matColumnDef="gradeC">
                <th mat-header-cell *matHeaderCellDef>GRADE C %</th>
                <td mat-cell *matCellDef="let element">{{ element.gradeC | percent : '1.2-2' }}</td>
              </ng-container>

              <!-- Grade D % Column -->
              <ng-container matColumnDef="gradeD">
                <th mat-header-cell *matHeaderCellDef>GRADE D %</th>
                <td mat-cell *matCellDef="let element">{{ element.gradeD | percent : '1.2-2' }}</td>
              </ng-container>

              <!-- Grade F % Column -->
              <ng-container matColumnDef="gradeF">
                <th mat-header-cell *matHeaderCellDef>GRADE F %</th>
                <td mat-cell *matCellDef="let element">{{ element.gradeF | percent : '1.2-2' }}</td>
              </ng-container>

              <!-- G-Service Column -->
              <ng-container matColumnDef="gService">
                <th mat-header-cell *matHeaderCellDef>G-SERVICE</th>
                <td mat-cell *matCellDef="let element">{{ element.gService | number : '1.2-2' }}%</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <mat-paginator
            (page)="pageChanged($event)"
            [length]='detailedMetrics.length'
            [pageSize]="itemsPerPage"
            [pageSizeOptions]="itemsPerPageOptions"
            [pageIndex]='currentPage'
            [disabled]='detailedMetrics.length <= itemsPerPage'
          >
          </mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- Courier-Specific Shipments Table - Only shown when a single courier is selected -->
  <div class='row mb--md' *ngIf="isSingleCourierSelected">
    <div class='column--12'>
      <mat-card class="table-card">
        <mat-card-content class='card p--md'>
          <mat-card-title>SHIPMENTS FOR {{ selectedCourierName }}</mat-card-title>
          <div class='responsive-table'>
            <table mat-table [dataSource]="courierShipments">
              <!-- Account Type Column -->
              <ng-container matColumnDef="accountType">
                <th mat-header-cell *matHeaderCellDef>ACCOUNT TYPE</th>
                <td mat-cell *matCellDef="let element">{{ element.account_type || 'N/A' }}</td>
              </ng-container>

              <!-- Courier Column -->
              <ng-container matColumnDef="courier">
                <th mat-header-cell *matHeaderCellDef>COURIER</th>
                <td mat-cell *matCellDef="let element">{{ element.courier_name }}</td>
              </ng-container>

              <!-- Tracking Number Column -->
              <ng-container matColumnDef="trackingNumber">
                <th mat-header-cell *matHeaderCellDef>TRACKING #</th>
                <td mat-cell *matCellDef="let element">{{ element.tracking_number }}</td>
              </ng-container>

              <!-- Shipment Created At Column -->
              <ng-container matColumnDef="shipmentCreatedAt">
                <th mat-header-cell *matHeaderCellDef>CREATED AT</th>
                <td mat-cell *matCellDef="let element">{{ element.shipment_created_at | date:'short' }}</td>
              </ng-container>

              <!-- Estimated Delivery Column -->
              <ng-container matColumnDef="estimatedDelivery">
                <th mat-header-cell *matHeaderCellDef>ESTIMATED DELIVERY</th>
                <td mat-cell *matCellDef="let element">{{ element.estimated_delivery | date:'short' }}</td>
              </ng-container>

              <!-- Pickup Time Column -->
              <ng-container matColumnDef="pickupTime">
                <th mat-header-cell *matHeaderCellDef>PICKUP TIME</th>
                <td mat-cell *matCellDef="let element">{{ element.pickup_time | date:'short' }}</td>
              </ng-container>

              <!-- Expected Delivery Column -->
              <ng-container matColumnDef="expectedDelivery">
                <th mat-header-cell *matHeaderCellDef>EXPECTED DELIVERY</th>
                <td mat-cell *matCellDef="let element">{{ element.expected_delivery | date:'short' }}</td>
              </ng-container>

              <!-- Actual Delivery Column -->
              <ng-container matColumnDef="actualDelivery">
                <th mat-header-cell *matHeaderCellDef>ACTUAL DELIVERY</th>
                <td mat-cell *matCellDef="let element">{{ element.actual_delivery | date:'short' }}</td>
              </ng-container>

              <!-- Origin City Column -->
              <ng-container matColumnDef="originCity">
                <th mat-header-cell *matHeaderCellDef>ORIGIN CITY</th>
                <td mat-cell *matCellDef="let element">{{ element.origin_city }}</td>
              </ng-container>

              <!-- Origin Province Column -->
              <ng-container matColumnDef="originProvince">
                <th mat-header-cell *matHeaderCellDef>ORIGIN PROVINCE</th>
                <td mat-cell *matCellDef="let element">{{ element.origin_province }}</td>
              </ng-container>

              <!-- Destination City Column -->
              <ng-container matColumnDef="destinationCity">
                <th mat-header-cell *matHeaderCellDef>DESTINATION CITY</th>
                <td mat-cell *matCellDef="let element">{{ element.destination_city }}</td>
              </ng-container>

              <!-- Destination Province Column -->
              <ng-container matColumnDef="destinationProvince">
                <th mat-header-cell *matHeaderCellDef>DESTINATION PROVINCE</th>
                <td mat-cell *matCellDef="let element">{{ element.destination_province }}</td>
              </ng-container>

              <!-- Performance Grade Column -->
              <ng-container matColumnDef="performanceGrade">
                <th mat-header-cell *matHeaderCellDef>GRADE</th>
                <td mat-cell *matCellDef="let element">{{ element.performance_grade || 'N/A' }}</td>
              </ng-container>

              <!-- Service Name Column -->
              <ng-container matColumnDef="serviceName">
                <th mat-header-cell *matHeaderCellDef>SERVICE</th>
                <td mat-cell *matCellDef="let element">{{ element.service_name }}</td>
              </ng-container>

              <!-- Is Guaranteed Column -->
              <ng-container matColumnDef="isGuaranteed">
                <th mat-header-cell *matHeaderCellDef>GUARANTEED</th>
                <td mat-cell *matCellDef="let element">{{ element.is_guaranteed ? 'Yes' : 'No' }}</td>
              </ng-container>

              <!-- Delay Column -->
              <ng-container matColumnDef="delay">
                <th mat-header-cell *matHeaderCellDef>DELAY (HOURS)</th>
                <td mat-cell *matCellDef="let element">{{ element.delay | number:'1.2-2' }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="shipmentColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: shipmentColumns;"></tr>
            </table>
          </div>
          <mat-paginator
            (page)="shipmentPageChanged($event)"
            [length]='totalShipmentsCount'
            [pageSize]="shipmentsPerPage"
            [pageSizeOptions]="itemsPerPageOptions"
            [pageIndex]='currentShipmentsPage'
          >
          </mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
