import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ElementRef,
  Input,
  SimpleChanges,
  OnChanges,
  AfterViewInit, ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as d3 from 'd3';
import { environment } from '../../../../../../../environments/environment';

export interface RouteData {
  origin: { longitude: number; latitude: number; name: string };
  destination: { longitude: number; latitude: number; name: string };
  performance: number; // On-time performance percentage
  volume: number; // Total shipments
  color?: string; // Optional: Custom color for the route
}

@Component({
  selector: 'app-interactive-route-explorer',
  templateUrl: './interactive-route-explorer.component.html',
  styleUrls: ['./interactive-route-explorer.component.scss'],
})
export class InteractiveRouteExplorerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('routeMap') routeMapContainer: ElementRef;
  @Input() routes: RouteData[] = [];

  public filterForm: FormGroup;
  public performanceRange: number[] = [50, 100];

  private svg: any;
  private g: any;
  private projection: any;
  private geoJson: any;
  private width = 0;
  private height = 0;
  private strokeModifier: number = 1;
  private zoom: any;
  private currentTransform = d3.zoomIdentity;
  private resizeTimer: any;
  private mapContainer: any;
  private provincesGroup: any;
  private routesGroup: any;
  private pointsGroup: any;
  private tooltip: any;
  private selectedLocation: string | null = null;
  private CDN_URL: string = environment.CDN_URL;
  private geoJsonAsset: string = this.CDN_URL + '/admin/data/canada_provinces.geojson';

  constructor(private formBuilder: FormBuilder) {
    this.filterForm = this.formBuilder.group({
      originProvince: ['ontario'],
      originCity: ['toronto'],
      destinationProvince: ['all'],
      destinationCity: ['all'],
      courier: ['all'],
      serviceType: ['all'],
      performanceRange: [this.performanceRange],
    });
  }

  ngOnInit(): void {
    this.mapContainer = d3.select('#route-map-container');
    // Update routes when filters change
    this.filterForm.valueChanges.subscribe(() => this.updateRoutes());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['routes'] && !changes['routes'].firstChange && this.svg) {
      this.updateRoutes();
    }
  }

  ngAfterViewInit(): void {
    this.initializeSvg();
    this.initializeTooltip();
    this.loadCanadaMap();
  }

  ngOnDestroy(): void {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }
    if (this.tooltip) {
      this.tooltip.remove();
    }
  }

  private initializeTooltip(): void {
    this.tooltip = d3.select('body').append('div')
      .attr('class', 'route-tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background-color', 'white')
      .style('border', '1px solid #ddd')
      .style('border-radius', '4px')
      .style('padding', '10px')
      .style('box-shadow', '0 2px 4px rgba(0,0,0,0.1)')
      .style('z-index', '10000')
      .style('pointer-events', 'none');
  }

  private initializeSvg(): void {
    this.mapContainer.selectAll('svg').remove();

    this.width = this.routeMapContainer.nativeElement.offsetWidth;
    this.height = this.routeMapContainer.nativeElement.offsetHeight || this.width * 0.6;

    this.zoom = d3.zoom()
      .scaleExtent([1, 20])
      .on('zoom', (event) => {
        this.currentTransform = event.transform;
        this.g.attr('transform', event.transform);
        this.adjustElementSizesForZoom(event.transform.k);
      });

    this.svg = this.mapContainer
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${this.width} ${this.height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .call(this.zoom)
      .on('dblclick.zoom', null);

    this.g = this.svg.append('g');
    this.provincesGroup = this.g.append('g').attr('class', 'provinces-group');
    this.routesGroup = this.g.append('g').attr('class', 'routes-group');
    this.pointsGroup = this.g.append('g').attr('class', 'points-group'); // Group for points
  }

  private loadCanadaMap(): void {
    this.projection = d3.geoMercator()
      .center([-95, 50])
      .scale(this.width * 0.7)
      .translate([this.width / 2, this.height / 2]);

    const path = d3.geoPath().projection(this.projection);

    d3.json(this.geoJsonAsset).then((geoJson: any) => {
      this.geoJson = geoJson;

      // Draw provinces
      this.provincesGroup
        .selectAll('path')
        .data(geoJson.features)
        .enter()
        .append('path')
        .attr('d', path)
        .attr('class', 'province')
        .attr('fill', '#f2f2f2')
        .attr('stroke', '#ccc')
        .attr('stroke-width', this.strokeModifier / this.currentTransform.k);

      this.updateRoutes(); // Render routes after loading the map
    });
  }

  public updateRoutes(): void {
    if (!this.routes || !this.projection || !this.routesGroup || !this.pointsGroup) return;

    // Apply filters
    const filters = this.filterForm.value;
    const filteredRoutes = this.routes.filter((route) => {
      return (
        route.performance >= filters?.performanceRange[0] &&
        route.performance <= filters?.performanceRange[1]
      );
    });

    // Remove existing routes and points
    this.routesGroup.selectAll('.route').remove();
    this.pointsGroup.selectAll('.point').remove();

    // Add filtered routes
    this.routesGroup
      .selectAll('.route')
      .data(filteredRoutes)
      .enter()
      .append('line')
      .attr('class', 'route')
      .attr('x1', (d: RouteData) => this.projection([d.origin.longitude, d.origin.latitude])[0])
      .attr('y1', (d: RouteData) => this.projection([d.origin.longitude, d.origin.latitude])[1])
      .attr('x2', (d: RouteData) => this.projection([d.destination.longitude, d.destination.latitude])[0])
      .attr('y2', (d: RouteData) => this.projection([d.destination.longitude, d.destination.latitude])[1])
      .attr('stroke', (d: RouteData) => d.color || this.getRouteColor(d.performance))
      .attr('stroke-width', 2 / this.currentTransform.k)
      .attr('stroke-opacity', 0.7)
      .on('mouseover', (event: any, d: RouteData) => this.showRouteTooltip(event, d))
      .on('mouseout', () => this.hideTooltip());

    // Add points for origin and destination
    const uniqueLocations = this.getUniqueLocations(filteredRoutes);
    this.pointsGroup
      .selectAll('.point')
      .data(uniqueLocations)
      .enter()
      .append('circle')
      .attr('class', 'point')
      .attr('cx', (d: any) => this.projection([d.longitude, d.latitude])[0])
      .attr('cy', (d: any) => this.projection([d.longitude, d.latitude])[1])
      .attr('r', 8 / this.currentTransform.k)
      .attr('fill', '#0d6efd')
      .attr('stroke', '#fff')
      .attr('stroke-width', 2 / this.currentTransform.k)
      .on('click', (event: any, d: any) => this.onPointClick(event, d))
      .on('mouseover', (event: any, d: any) => this.showPointTooltip(event, d))
      .on('mouseout', () => this.hideTooltip());
  }

  private getUniqueLocations(routes: RouteData[]): any[] {
    const locations = new Map<string, { longitude: number; latitude: number; name: string }>();

    routes.forEach((route) => {
      locations.set(route.origin.name, route.origin);
      locations.set(route.destination.name, route.destination);
    });

    return Array.from(locations.values());
  }

  private onPointClick(event: any, location: any): void {
    this.selectedLocation = location.name;
    this.highlightConnectedRoutes(location.name);
  }

  private highlightConnectedRoutes(locationName: string): void {
    // Reset all routes and points
    this.routesGroup.selectAll('.route').attr('stroke-opacity', 0.2);
    this.pointsGroup.selectAll('.point').attr('fill', '#0d6efd');

    // Highlight connected routes and points
    const connectedRoutes = this.routes.filter(
      (route) => route.origin.name === locationName || route.destination.name === locationName
    );

    connectedRoutes.forEach((route) => {
      this.routesGroup.selectAll('.route')
        .filter((d: RouteData) => d === route)
        .attr('stroke-opacity', 0.7);

      this.pointsGroup.selectAll('.point')
        .filter((d: any) => d.name === route.origin.name || d.name === route.destination.name)
        .attr('fill', '#ffc107'); // Highlight connected points
    });
  }

  private showPointTooltip(event: any, location: any): void {
    const x = event.pageX + 15;
    const y = event.pageY - 28;

    this.tooltip
      .html(`<div><strong>${location.name}</strong></div>`)
      .style('left', `${x}px`)
      .style('top', `${y}px`)
      .style('visibility', 'visible');
  }

  private getRouteColor(performance: number): string {
    if (performance >= 90) return '#198754'; // Green for high performance
    if (performance >= 80) return '#ffc107'; // Yellow for medium performance
    return '#dc3545'; // Red for low performance
  }

  private showRouteTooltip(event: any, route: RouteData): void {
    const x = event.pageX + 15;
    const y = event.pageY - 28;

    let content = `<div><strong>${route.origin.name} → ${route.destination.name}</strong></div>`;
    content += `<div>Performance: ${route.performance.toFixed(1)}%</div>`;
    content += `<div>Volume: ${route.volume.toLocaleString()}</div>`;

    this.tooltip
      .html(content)
      .style('left', `${x}px`)
      .style('top', `${y}px`)
      .style('visibility', 'visible');
  }

  private hideTooltip(): void {
    this.tooltip.style('visibility', 'hidden');
  }

  private adjustElementSizesForZoom(scale: number): void {
    // Adjust province stroke width
    this.provincesGroup.selectAll('.province')
      .attr('stroke-width', 0.5 / scale);

    // Adjust route stroke width
    this.routesGroup.selectAll('.route')
      .attr('stroke-width', 2 / scale);

    // Adjust point sizes
    this.pointsGroup.selectAll('.point')
      .attr('r', 8 / scale)
      .attr('stroke-width', 2 / scale);
  }

  @HostListener('window:resize')
  onResize(): void {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }

    this.resizeTimer = setTimeout(() => {
      this.width = this.routeMapContainer.nativeElement.offsetWidth;
      this.height = this.routeMapContainer.nativeElement.offsetHeight || this.width * 0.6;

      // Reinitialize the SVG
      this.initializeSvg();
      this.initializeTooltip();
      this.loadCanadaMap();
    }, 250);
  }
}
