<ng-container *ngIf='!loading, else isLoading'>
  <div class='row'>
    <div class='column--12'>
      <app-interactive-route-explorer
        #routeExplorer
        [routes]='routesData'
      ></app-interactive-route-explorer>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
