import { Injectable } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  ErrorMessages,
  InsuranceProviderNames,
  InsuranceProviders,
  ProviderNotificationCapabilities,
  ShippingProviderNames,
  ShippingProviders,
} from '@app/shared/constants';

export enum GlobalProvidersCategory {
  SHIPPING = 'S',
  ECOMMERCE = 'E',
}

export interface GlobalChildProviderInterface {
  provider: ShippingProviders;
  enabled: boolean;
  name?: string;
  markupPercentageValue?: number;
  markupDollarValue?: number;
  deliveryDate?: string;
}

export interface GlobalCourierRO {
  provider: ShippingProviders;
  category: GlobalProvidersCategory;
  country: 'CA' | 'US';
  enabled: boolean;
  name?: string;
  markupPercentageValue?: number;
  markupDollarValue?: number;
  note?: string;
  deliveryDate?: string;
  metadata?: any;
  childProviders?: GlobalChildProviderInterface[];
}

export interface CourierModifierDTO {
  id?: number;
  provider: ShippingProviders;
  lastMileProvider?: ShippingProviders;
  markupPercentageValue?: number;
  markupDollarValue?: number;
  deliveryDate?: string;
  deliveryDateDisplay?: string;
  providerName?: string;
  lastMileProviderName?: string;
  markups?: string;
}

export interface DynamicPricingDTO {
  id?: number;
  sourceCourier: ShippingProviders;
  targetCourier: ShippingProviders;
  minMarginPercentageValue: number;
  beatPriceByPercentageValue: number;
}

@Injectable({ providedIn: 'root' })
export class ProvidersService {
  constructor(private helperService: HelpersService, private http: HttpClient) {}

  public getProviderSmallLogo(provider: string, service?: any, forceUsingNewACBrand = false): string {
    let imageProvider = provider.toLowerCase();
    if (
      (service &&
        service.provider &&
        (service.code || '').toUpperCase().includes('AC') &&
        service.provider === ShippingProviders.RIVO) ||
      (forceUsingNewACBrand && imageProvider === ShippingProviders.RIVO)
    ) {
      imageProvider = 'ac';
    } else if (imageProvider === ShippingProviders.INTELCOM) {
      imageProvider = 'intelcom_dragonfly';
    }

    return `${environment.CDN_URL}/shared/images/couriers/${imageProvider}-square.svg`;
  }

  public getAirCanadaImageByServiceName(serviceName: string): string {
    if ((serviceName || '').toUpperCase().includes('CANADA POST')) {
      return 'ac_cp';
    } else if ((serviceName || '').toUpperCase().includes('INTELCOM')) {
      return 'ac_ic';
    } else if ((serviceName || '').toUpperCase().includes('UNIUNI')) {
      return 'ac_un';
    } else if ((serviceName || '').toUpperCase().includes('TFORCE')) {
      return 'ac_tf';
    } else if ((serviceName || '').toUpperCase().includes('APPLE')) {
      return 'ac_ae';
    } else if ((serviceName || '').toUpperCase().includes('TREXITY')) {
      return 'ac_tx';
    } else if ((serviceName || '').toUpperCase().includes('SPRING')) {
      return 'ac_sp';
    } else {
      return 'ac';
    }
  }

  public get(query?: any): Observable<any> {
    const queryParams = _.get(query, 'params') ? query : { params: query };
    return this.http.get(`${environment.APP_SERVICES_URL}/admin/couriers`, queryParams).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  public getEnabledGlobalProviders(): Observable<{
    globalProviders: GlobalCourierRO[];
  }> {
    return this.http.get(`${environment.APP_SERVICES_URL}/admin/enabled_couriers`).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  public getGlobalProviders(query?: any): Observable<{
    globalProviders: GlobalCourierRO[];
  }> {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/global_providers`, {
        params: query,
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any) => throwError(error || ErrorMessages.UNEXPECTED))
      );
  }

  public updateGlobalProvider(query: any) {
    return this.http.put(`${environment.APP_SERVICES_URL}/admin/global_providers/${query.provider}`, query).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  public deleteCacheKey(): Observable<any> {
    return this.http.delete(`${environment.APP_SERVICES_URL}/admin/global_providers/cache`).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  public getEcommercePlatforms(query?: any): Observable<{
    globalProviders: GlobalCourierRO[];
  }> {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/ecommerce_platforms`, {
        params: query,
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any) => throwError(error || ErrorMessages.UNEXPECTED))
      );
  }

  public updateEcommercePlatform(query: any) {
    return this.http.put(`${environment.APP_SERVICES_URL}/admin/ecommerce_platforms/${query.provider}`, query).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  public deleteEcommerceCacheKey(): Observable<any> {
    return this.http.delete(`${environment.APP_SERVICES_URL}/admin/ecommerce_platforms/cache`).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  public getInsuranceProvidersList(): any {
    return [
      {
        enum: InsuranceProviders.MARSH,
        name: InsuranceProviderNames.MARSH,
        provider: InsuranceProviders.MARSH,
      },
      {
        enum: InsuranceProviders.UPIC,
        name: InsuranceProviderNames.UPIC,
        provider: InsuranceProviders.UPIC,
      },
    ];
  }

  public getProvidersList(): any {
    return [
      {
        enum: ShippingProviders.GLS,
        name: ShippingProviderNames.GLS,
        provider: ShippingProviders.GLS,
        shipmentNotifications: 0,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.CANADA_POST,
        name: ShippingProviderNames.CANADA_POST,
        provider: ShippingProviders.CANADA_POST,
        shipmentNotifications:
          ProviderNotificationCapabilities.IS_EDITABLE_RECIPIENT +
          ProviderNotificationCapabilities.DEFAULT_ON_RECIPIENT,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.CANPAR,
        name: ShippingProviderNames.CANPAR,
        provider: ShippingProviders.CANPAR,
        shipmentNotifications:
          ProviderNotificationCapabilities.IS_EDITABLE_RECIPIENT + ProviderNotificationCapabilities.IS_EDITABLE_SENDER,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.CHASSEURS_COURRIER,
        name: ShippingProviderNames.CHASSEURS_COURRIER,
        provider: ShippingProviders.CHASSEURS_COURRIER,
        shipmentNotifications: 0,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.FEDEX,
        name: ShippingProviderNames.FEDEX,
        provider: ShippingProviders.FEDEX,
        shipmentNotifications:
          ProviderNotificationCapabilities.IS_EDITABLE_RECIPIENT +
          ProviderNotificationCapabilities.DEFAULT_ON_RECIPIENT +
          ProviderNotificationCapabilities.IS_EDITABLE_SENDER +
          ProviderNotificationCapabilities.DEFAULT_ON_SENDER,
        // USEND-4772
        machoolAccount: 'unavailable',
      },
      {
        enum: ShippingProviders.PUROLATOR,
        name: ShippingProviderNames.PUROLATOR,
        provider: ShippingProviders.PUROLATOR,
        shipmentNotifications:
          ProviderNotificationCapabilities.IS_EDITABLE_RECIPIENT +
          ProviderNotificationCapabilities.DEFAULT_ON_RECIPIENT +
          ProviderNotificationCapabilities.IS_EDITABLE_SENDER +
          ProviderNotificationCapabilities.DEFAULT_ON_SENDER,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.DHL,
        name: ShippingProviderNames.DHL,
        provider: ShippingProviders.DHL,
        shipmentNotifications:
          ProviderNotificationCapabilities.IS_EDITABLE_RECIPIENT +
          ProviderNotificationCapabilities.DEFAULT_ON_RECIPIENT,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.NATIONEX,
        name: ShippingProviderNames.NATIONEX,
        provider: ShippingProviders.NATIONEX,
        shipmentNotifications:
          ProviderNotificationCapabilities.IS_EDITABLE_RECIPIENT + ProviderNotificationCapabilities.IS_EDITABLE_SENDER,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.SHIFT,
        name: ShippingProviderNames.SHIFT,
        provider: ShippingProviders.SHIFT,
        shipmentNotifications: 0,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.EEKO,
        name: ShippingProviderNames.EEKO,
        provider: ShippingProviders.EEKO,
        shipmentNotifications: 0,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.RIVO,
        name: ShippingProviderNames.RIVO,
        provider: ShippingProviders.RIVO,
        shipmentNotifications: 0,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.UPS,
        name: ShippingProviderNames.UPS,
        provider: ShippingProviders.UPS,
        shipmentNotifications:
          ProviderNotificationCapabilities.IS_EDITABLE_RECIPIENT +
          ProviderNotificationCapabilities.DEFAULT_ON_RECIPIENT +
          ProviderNotificationCapabilities.IS_EDITABLE_SENDER +
          ProviderNotificationCapabilities.DEFAULT_ON_SENDER,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.INTELCOM,
        name: ShippingProviderNames.INTELCOM,
        provider: ShippingProviders.INTELCOM,
        shipmentNotifications: 0,
        // USEND-4615: disable intelcom as MA provider
        machoolAccount: 'unavailable',
      },
      {
        enum: ShippingProviders.UNIUNI,
        name: ShippingProviderNames.UNIUNI,
        provider: ShippingProviders.UNIUNI,
        shipmentNotifications:
          ProviderNotificationCapabilities.IS_EDITABLE_RECIPIENT +
          ProviderNotificationCapabilities.DEFAULT_ON_RECIPIENT,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.FLASHBOX,
        name: ShippingProviderNames.FLASHBOX,
        provider: ShippingProviders.FLASHBOX,
        shipmentNotifications:
          ProviderNotificationCapabilities.IS_EDITABLE_RECIPIENT +
          ProviderNotificationCapabilities.DEFAULT_ON_RECIPIENT,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.SPRING,
        name: ShippingProviderNames.SPRING,
        provider: ShippingProviders.SPRING,
        shipmentNotifications:
          ProviderNotificationCapabilities.IS_EDITABLE_RECIPIENT +
          ProviderNotificationCapabilities.DEFAULT_ON_RECIPIENT +
          ProviderNotificationCapabilities.IS_EDITABLE_SENDER +
          ProviderNotificationCapabilities.DEFAULT_ON_SENDER,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.TREXITY,
        name: ShippingProviderNames.TREXITY,
        provider: ShippingProviders.TREXITY,
        shipmentNotifications:
          ProviderNotificationCapabilities.IS_EDITABLE_RECIPIENT +
          ProviderNotificationCapabilities.DEFAULT_ON_RECIPIENT,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.LIVRAPIDE,
        name: ShippingProviderNames.LIVRAPIDE,
        provider: ShippingProviders.LIVRAPIDE,
        shipmentNotifications:
          ProviderNotificationCapabilities.IS_EDITABLE_RECIPIENT +
          ProviderNotificationCapabilities.DEFAULT_ON_RECIPIENT,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.ICS_COURIER,
        name: ShippingProviderNames.ICS_COURIER,
        provider: ShippingProviders.ICS_COURIER,
        shipmentNotifications:
          ProviderNotificationCapabilities.IS_EDITABLE_RECIPIENT +
          ProviderNotificationCapabilities.DEFAULT_ON_RECIPIENT,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.EVA,
        name: ShippingProviderNames.EVA,
        provider: ShippingProviders.EVA,
        shipmentNotifications: 0, // TODO: Customer notifications
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.CANADA_POST_3PL,
        name: ShippingProviderNames.CANADA_POST_3PL,
        provider: ShippingProviders.CANADA_POST_3PL,
        shipmentNotifications: 0, // TODO: Customer notifications
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.DELIVRO,
        name: ShippingProviderNames.DELIVRO,
        provider: ShippingProviders.DELIVRO,
        shipmentNotifications: 0,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.ULALA,
        name: ShippingProviderNames.ULALA,
        provider: ShippingProviders.ULALA,
        shipmentNotifications: 0,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.MDX,
        name: ShippingProviderNames.MDX,
        provider: ShippingProviders.MDX,
        shipmentNotifications: 0,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.LOOMIS_EXPRESS,
        name: ShippingProviderNames.LOOMIS_EXPRESS,
        provider: ShippingProviders.LOOMIS_EXPRESS,
        shipmentNotifications:
          ProviderNotificationCapabilities.IS_EDITABLE_SENDER,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.DEELEEO,
        name: ShippingProviderNames.DEELEEO,
        provider: ShippingProviders.DEELEEO,
        shipmentNotifications: 0,
        machoolAccount: 'available',
      },
    ];
  }

  public getFreightProvidersList(): any {
    return [
      {
        enum: ShippingProviders.DAYROSS,
        name: ShippingProviderNames.DAYROSS,
        provider: ShippingProviders.DAYROSS,
        shipmentNotifications: 0,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.FREIGHT_AIR_CANADA,
        name: ShippingProviderNames.FREIGHT_AIR_CANADA,
        provider: ShippingProviders.FREIGHT_AIR_CANADA,
        shipmentNotifications: 0,
        machoolAccount: 'available',
      },
      {
        enum: ShippingProviders.FREIGHT_GLS,
        name: ShippingProviderNames.FREIGHT_GLS,
        provider: ShippingProviders.FREIGHT_GLS,
        shipmentNotifications: 0,
        machoolAccount: 'available',
      },
    ];
  }
}
