import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

export interface CourierPerformance {
  courier: string;
  volume: number;
  onTime: string;
  avgDelay: string;
  serviceTypes: string;
  delayDistribution: number[];
}

@Component({
  selector: 'app-route-details-expanded',
  templateUrl: './route-details-expanded.component.html',
  styleUrls: ['./route-details-expanded.component.scss']
})
export class RouteDetailsExpandedComponent {
  displayedColumns: string[] = ['courier', 'volume', 'onTime', 'avgDelay', 'serviceTypes', 'delayDistribution'];
  dataSource: MatTableDataSource<CourierPerformance>;

  constructor() {
    const ELEMENT_DATA: CourierPerformance[] = [
      { courier: 'DHL', volume: 1245, onTime: '96.5%', avgDelay: '0.8h', serviceTypes: 'EXPRESS, EXPRESS WORLDWIDE', delayDistribution: [60, 30, 10] },
      { courier: 'Purolator', volume: 1012, onTime: '94.2%', avgDelay: '1.2h', serviceTypes: 'Express, Ground', delayDistribution: [50, 35, 15] }
    ];
    this.dataSource = new MatTableDataSource(ELEMENT_DATA);
  }
}
