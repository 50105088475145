<div class="row">
  <div class='column--12'>
    <!-- Route Summary -->
    <mat-card class="summary-card">
      <mat-card-content class='card p--md metric-card'>
        <h3>TORONTO TO MONTREAL ROUTE PERFORMANCE</h3>
        <div class="metrics">
          <div class="metric">
            <div class="label">Total Shipments</div>
            <div class="value">328</div>
            <div class="change">↑ 5% from last month</div>
          </div>
          <div class="metric">
            <div class="label">On-Time Percentage</div>
            <div class="value">96%</div>
            <div class="change">↑ 2% from last month</div>
          </div>
          <div class="metric">
            <div class="label">Average Delay</div>
            <div class="value">0.8h</div>
            <div class="change">↓ 0.1h from last month</div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class='column--12'>
    <!-- Courier Comparison and Service Type Breakdown -->
    <div class="row">
      <div class='column--6'>
        <mat-card class="summary-card">
          <mat-card-content class='card p--md metric-card'>
            <h3>COURIER COMPARISON</h3>
            <ngx-charts-bar-vertical
              [view]="[500, 300]"
              [results]="courierComparison"
              [xAxis]="true"
              [yAxis]="true"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true"
              [xAxisLabel]="'Courier'"
              [yAxisLabel]="'Percentage'"
              [scheme]="colorScheme">
            </ngx-charts-bar-vertical>
          </mat-card-content>
        </mat-card>
      </div>
      <div class='column--6'>
        <mat-card class="summary-card">
          <mat-card-content class='card p--md metric-card'>
            <h3>SERVICE TYPE BREAKDOWN</h3>
            <ngx-charts-pie-chart
              [view]="[500, 300]"
              [results]="serviceTypeBreakdown"
              [scheme]="colorScheme"
              [labels]="true">
            </ngx-charts-pie-chart>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <div class='column--12'>
    <!-- Monthly Performance Trend -->
    <mat-card class="summary-card">
      <mat-card-content class='card p--md metric-card'>
        <h3>MONTHLY PERFORMANCE TREND</h3>
        <ngx-charts-line-chart
          [view]="[1100, 300]"
          [results]="monthlyPerformance"
          [xAxis]="true"
          [yAxis]="true"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [xAxisLabel]="'Month'"
          [yAxisLabel]="'Percentage'"
          [scheme]="colorScheme"
        >
        </ngx-charts-line-chart>
      </mat-card-content>
    </mat-card>
  </div>
  <div class='column--12'>
    <!-- Shipment Details -->
    <mat-card class="summary-card">
      <mat-card-content class='card p--md metric-card'>
        <h3>SHIPMENT DETAILS</h3>
        <table mat-table [dataSource]="shipments">
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>DATE</th>
            <td mat-cell *matCellDef="let shipment">{{ shipment.date }}</td>
          </ng-container>
          <ng-container matColumnDef="tracking">
            <th mat-header-cell *matHeaderCellDef>TRACKING #</th>
            <td mat-cell *matCellDef="let shipment">{{ shipment.tracking }}</td>
          </ng-container>
          <ng-container matColumnDef="courier">
            <th mat-header-cell *matHeaderCellDef>COURIER</th>
            <td mat-cell *matCellDef="let shipment">{{ shipment.courier }}</td>
          </ng-container>
          <ng-container matColumnDef="service">
            <th mat-header-cell *matHeaderCellDef>SERVICE</th>
            <td mat-cell *matCellDef="let shipment">{{ shipment.service }}</td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>STATUS</th>
            <td mat-cell *matCellDef="let shipment">{{ shipment.status }}</td>
          </ng-container>
          <ng-container matColumnDef="delivery">
            <th mat-header-cell *matHeaderCellDef>DELIVERY TIME</th>
            <td mat-cell *matCellDef="let shipment">{{ shipment.delivery }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
