import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { ScopeGuard } from '@app/shared/guards/scope.guard';
import { AdminScopes } from '@app/shared/services/user.service';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { OtpComponent } from '@app/pages/authenticated/pages/otp/otp.component';
import {
  OtpCouriersComponent
} from '@app/pages/authenticated/pages/otp/components/otp-couriers/otp-couriers.component';
import {
  OtpServicesComponent
} from '@app/pages/authenticated/pages/otp/components/otp-services/otp-services.component';
import {
  CanadaHeatmapComponent
} from '@app/pages/authenticated/pages/otp/components/canada-heat-map/canada-heat-map.component';
import { OtpRegionsComponent } from '@app/pages/authenticated/pages/otp/components/otp-regions/otp-regions.component';
import {
  InteractiveRouteExplorerComponent
} from '@app/pages/authenticated/pages/otp/components/interactive-route-explorer/interactive-route-explorer.component';
import {
  OtpAnalyticsComponent
} from '@app/pages/authenticated/pages/otp/components/otp-analytics/otp-analytics.component';
import {
  RouteDetailsExpandedComponent
} from '@app/pages/authenticated/pages/otp/components/route-details-expanded/route-details-expanded.component';
import {
  RegionalAnalysisComponent
} from '@app/pages/authenticated/pages/otp/components/regional-analysis/regional-analysis.component';
import {
  RegionalPerformanceViewComponent
} from '@app/pages/authenticated/pages/otp/components/regional-performance-view/regional-performance-view.component';
import {
  RouteDetailComponent
} from '@app/pages/authenticated/pages/otp/components/route-detail/route-detail.component';
import {
  RecalculateOtpComponent
} from '@app/pages/authenticated/pages/otp/components/recalculate-otp/recalculate-otp.component';
import { OtpDetailsComponent } from '@app/pages/authenticated/pages/otp/components/details/details.component';
import { OtpSharedModule } from './otp-shared.module';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
  {
    path: '',
    component: OtpComponent,
    canActivate: [AngularFireAuthGuard, ScopeGuard],
    data: { scopes: [AdminScopes.VIEW_OTP] },
    canActivateChild: [ScopeGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full', data: { scopes: [AdminScopes.VIEW_OTP] } },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.OtpDashboardModule),
        data: { scopes: [AdminScopes.VIEW_OTP], authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'details',
        component: OtpDetailsComponent,
        data: { scopes: [AdminScopes.VIEW_OTP] },
        children: [
          { path: '', redirectTo: 'couriers', pathMatch: 'full' },
          { path: 'couriers', component: OtpCouriersComponent, data: { scopes: [AdminScopes.VIEW_OTP], authGuardPipe: redirectUnauthorizedToLogin }},
          { path: 'services', component: OtpServicesComponent, data: { scopes: [AdminScopes.VIEW_OTP], authGuardPipe: redirectUnauthorizedToLogin }},
          { path: 'regions', component: OtpRegionsComponent, data: { scopes: [AdminScopes.VIEW_OTP], authGuardPipe: redirectUnauthorizedToLogin }},
          { path: 'analytics', component: OtpAnalyticsComponent, data: { scopes: [AdminScopes.VIEW_OTP], authGuardPipe: redirectUnauthorizedToLogin }},
        ]
      },
      { path: 'route-details-expanded', component: RouteDetailsExpandedComponent, data: { scopes: [AdminScopes.VIEW_OTP], authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'route-analysis', component: RegionalAnalysisComponent, data: { scopes: [AdminScopes.VIEW_OTP], authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'route-detail', component: RouteDetailComponent, data: { scopes: [AdminScopes.VIEW_OTP], authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'regional-performance', component: RegionalPerformanceViewComponent, data: { scopes: [AdminScopes.VIEW_OTP], authGuardPipe: redirectUnauthorizedToLogin } },
    ]
  }
];

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    OtpSharedModule,
  ],
  exports: [RouterModule],
  declarations: [
    OtpComponent,
    OtpDetailsComponent,
    OtpCouriersComponent,
    OtpServicesComponent,
    OtpRegionsComponent,
    OtpAnalyticsComponent,
    CanadaHeatmapComponent,
    InteractiveRouteExplorerComponent,
    RouteDetailsExpandedComponent,
    RegionalAnalysisComponent,
    RegionalPerformanceViewComponent,
    RouteDetailComponent,
    RecalculateOtpComponent,
  ]
})
export class OtpModule { }
