import { Component, OnInit } from '@angular/core';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@app/shared/services/logger.service';
import { environment } from '../../../../../environments/environment';
import { GlobalConfiguration, GlobalConfigurationService } from '@app/shared/services/global-configuration.service';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
})
export class ConfigurationPageComponent implements OnInit {
  public loading: boolean = true;
  public user: AdminUser;
  public canEdit: boolean = false;
  public CDN_URL: string = environment.CDN_URL;
  public globalConfigurationForm: FormGroup;
  public globalConfiguration: GlobalConfiguration;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private logger: LoggerService,
    private router: Router,
    private globalConfigurationService: GlobalConfigurationService,
    private bannerNotificationsService: BannerNotificationsService
  ) {}
  ngOnInit() {
    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_CONFIGURATION])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            this.canEdit = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_CONFIGURATION]);
            this.getGlobalConfiguration();
          } else {
            this.router.navigate(['login']);
          }
        },
        (_) => {
          this.router.navigate(['home']);
        }
      );
  }

  public updateGlobalConfiguration(): void {
    if (this.canEdit) {
      this.loading = true;
      const globalConfigurationValue = this.globalConfigurationForm.value;

      const params: GlobalConfiguration = {
        byoa_charge_amount: globalConfigurationValue.byoaChargeAmount,
        return_centre_renewal: globalConfigurationValue.returnCentreRenewal,
        maximum_wallet_fund_attempts: globalConfigurationValue.maximumWalletFundAttempts,
        wallet_fund_time_window: globalConfigurationValue.walletFundTimeWindow,
      }

      this.globalConfigurationService.updateGlobalConfiguration(params).subscribe({
        next: (res: GlobalConfiguration): void => {
          this.logger.log('Updated configuration successfully', res);
          this.bannerNotificationsService.success(`Configuration was updated successfully`);
        },
        error: (error: Error): void => {
          this.bannerNotificationsService.error(`Updating configuration failed`);
          this.logger.error('Failed to update configuration', error);
        },
        complete: (): void => {
          this.getGlobalConfiguration()
        }
      })
    }
  }

  private createForm(): void {
    this.globalConfigurationForm = this.formBuilder.group({
      byoaChargeAmount: [
        {
          value: this.globalConfiguration.byoa_charge_amount || 0,
          disabled: !this.canEdit,
        },
        [Validators.required, Validators.max(99), Validators.min(0)],
      ],
      returnCentreRenewal: [
        {
          value: this.globalConfiguration.return_centre_renewal || 0,
          disabled: !this.canEdit,
        },
        [Validators.required, Validators.max(1000), Validators.min(0)],
      ],
      maximumWalletFundAttempts: [
        {
          value: this.globalConfiguration.maximum_wallet_fund_attempts || 0,
          disabled: !this.canEdit,
        },
        [Validators.required, Validators.max(99), Validators.min(0)],
      ],
      walletFundTimeWindow: [
        {
          value: this.globalConfiguration.wallet_fund_time_window || 0,
          disabled: !this.canEdit,
        },
        [Validators.required, Validators.max(10000), Validators.min(60)],
      ],
    });
    this.globalConfigurationForm.updateValueAndValidity();
    this.loading = false;
  }


  private getGlobalConfiguration(): void {
    this.loading = true;
    this.globalConfigurationService.getGlobalConfiguration().subscribe({
      next: (res: GlobalConfiguration): void => {
        this.globalConfiguration = res;
        this.createForm();
      },
      error: (error: Error): void => {
        this.logger.error('Failed to get global configuration', error);
      }
    })
  }
}
