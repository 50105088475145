import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

export interface RoutePerformance {
  route: string;
  total: number;
  onTime: string;
  bestCourier?: string;
  worstCourier?: string;
}

@Component({
  selector: 'app-regional-performance-view',
  templateUrl: './regional-performance-view.component.html',
  styleUrls: ['./regional-performance-view.component.scss']
})
export class RegionalPerformanceViewComponent {
  public topRoutesColumns: string[] = ['route', 'total', 'onTime', 'bestCourier'];
  public worstRoutesColumns: string[] = ['route', 'total', 'onTime', 'worstCourier'];

  public topRoutes: MatTableDataSource<RoutePerformance>;
  public worstRoutes: MatTableDataSource<RoutePerformance>;
  public colorScheme: any = {
    domain: ['#a50026', '#d73027', '#f46d43', '#fdae61', '#fee08b', '#d9ef8b', '#a6d96a', '#66bd63', '#1a9850']
  };

  public heatmapData = [
    {
      name: 'BC',
      series: [
        { name: 'Performance', value: 85 }
      ]
    },
    {
      name: 'AB',
      series: [
        { name: 'Performance', value: 90 }
      ]
    },
    {
      name: 'SK',
      series: [
        { name: 'Performance', value: 75 }
      ]
    },
    {
      name: 'MB',
      series: [
        { name: 'Performance', value: 70 }
      ]
    },
    {
      name: 'ON',
      series: [
        { name: 'Performance', value: 95 }
      ]
    },
    {
      name: 'QC',
      series: [
        { name: 'Performance', value: 88 }
      ]
    },
    {
      name: 'NB',
      series: [
        { name: 'Performance', value: 65 }
      ]
    },
    {
      name: 'NS',
      series: [
        { name: 'Performance', value: 60 }
      ]
    }
  ];

  constructor() {
    this.topRoutes = new MatTableDataSource([
      { route: 'Toronto → Montreal', total: 128, onTime: '94.5%', bestCourier: 'DHL' },
      { route: 'Vancouver → Calgary', total: 105, onTime: '92.3%', bestCourier: 'UPS' },
      { route: 'Montreal → Ottawa', total: 98, onTime: '91.8%', bestCourier: 'FedEx' },
      { route: 'Toronto → Vancouver', total: 76, onTime: '89.4%', bestCourier: 'DHL' }
    ]);

    this.worstRoutes = new MatTableDataSource([
      { route: 'Winnipeg → Halifax', total: 42, onTime: '65.3%', worstCourier: 'Purolator' },
      { route: 'Vancouver → Halifax', total: 38, onTime: '67.8%', worstCourier: 'Purolator' },
      { route: 'Quebec → Victoria', total: 35, onTime: '69.4%', worstCourier: 'UPS' },
      { route: 'Saskatoon → St John\'s', total: 28, onTime: '70.2%', worstCourier: 'FedEx' }
    ]);
  }
}
