import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

export interface RouteAnalysis {
  route: string;
  volume: number;
  onTime: string;
  avgDelay: string;
  bestCourier: string;
  activeCouriers: string;
  performanceTrend: string;
}

@Component({
  selector: 'app-regional-analysis',
  templateUrl: './regional-analysis.component.html',
  styleUrls: ['./regional-analysis.component.scss']
})
export class RegionalAnalysisComponent {
  displayedColumns: string[] = ['route', 'volume', 'onTime', 'avgDelay', 'bestCourier', 'activeCouriers', 'performanceTrend'];
  routeAnalysisData: MatTableDataSource<RouteAnalysis>;

  constructor() {
    const ROUTE_DATA: RouteAnalysis[] = [
      { route: 'Toronto, ON → Montreal, QC', volume: 3245, onTime: '95.2%', avgDelay: '1.2h', bestCourier: 'DHL (96.5%)', activeCouriers: '4 couriers', performanceTrend: 'M0,35 L20,25 L40,30 L60,20 L80,15' },
      { route: 'Vancouver, BC → Calgary, AB', volume: 2867, onTime: '92.8%', avgDelay: '1.8h', bestCourier: 'Purolator (94.2%)', activeCouriers: '3 couriers', performanceTrend: 'M0,35 L20,30 L40,35 L60,25 L80,30' }
    ];
    this.routeAnalysisData = new MatTableDataSource(ROUTE_DATA);
  }
}
