import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DEFAULT_LOCALE, ReportCardGroup } from '@app/shared/constants';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { ReportsService } from '@app/shared/services/reports.service';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';
import { formatNumber } from '@angular/common';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-machool-report-top-revenue',
  templateUrl: './machool-report-top-revenue.component.html',
})
export class MachoolReportTopRevenueComponent implements OnInit {
  @Input() filterForm: FormGroup | undefined;
  @Input() range: FormGroup | undefined;

  public loading = true;

  public loadingTopRevenue = false;
  public loadingTopRevenueFailed = false;
  public topRevenueAccounts: any[];
  public topRevenueAccountsEmpty: boolean = false;
  public topRevenueColumns = ['index', 'companyName', 'total', 'revenue', 'sales_rep'];

  constructor(
    private logger: LoggerService,
    private reportsService: ReportsService,
  ) {}

  ngOnInit() {
    this.getTopRevenueList();
  }

  public getTopRevenueList(): void {
    this.loadingTopRevenue = true;
    this.loadingTopRevenueFailed = false;
    let query: any = this.reportsService.getFilterQuery(this.range, this.filterForm);
    query.report_card_group = ReportCardGroup.TOP_REVENUE;
    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.topRevenueAccounts = [];
        res.accounts.forEach(account => {
          this.topRevenueAccounts.push({
            companyName: account.companyName,
            total: formatNumber(account.total, DEFAULT_LOCALE),
            revenue: account.revenue,
            sales_rep: account.sales_rep,
          });
        })
        this.topRevenueAccountsEmpty = this.topRevenueAccounts.length === 0;
        this.loadingTopRevenue = false;
      },
      (err: Error) => {
        this.loadingTopRevenue = false;
        this.loadingTopRevenueFailed = true;
        this.logger.error('GET top accounts report error', err);
      }
    );
  }
}
